import {
  Alert,
  Button,
  Card,
  Label,
  Pill,
  Select,
  Text,
} from '@workos-inc/component-library';
import { FC, useState } from 'react';
import { AlertCircle, Check } from 'react-feather';
import { Attribute } from '../../../../../../components/attribute-mapping';
import {
  ConnectionFragment,
  ConnectionType,
  InvalidAttributesError,
} from '../../../../../../graphql/generated';
import { graphql } from '../../../../../utils/graphql';
import {
  attributeMappingConfig,
  Attributes,
  ConnectionAttributeMapping,
} from '../../../attribute-mapping';
import { getConnectionName } from '../../../utils/get-connection-name';
import { testConnection } from '../../../utils/test-connection';

export interface InvalidAttributesProps {
  appName: string;
  connection: ConnectionFragment;
  error: InvalidAttributesError;
}

type SelectOption = {
  value: string;
  subtitle: string;
};

export const InvalidAttributes: FC<InvalidAttributesProps> = ({
  appName,
  connection,
  error: {
    expected_attributes: {
      idp_id_attribute: idAttribute,
      first_name_attribute: firstNameAttribute,
      last_name_attribute: lastNameAttribute,
      email_attribute: emailAttribute,
    },
    received_attributes: receivedAttributes,
  },
}) => {
  const expectedAttributes = [
    idAttribute,
    emailAttribute,
    firstNameAttribute,
    lastNameAttribute,
  ];

  const getReceivedAttribute = (attr: string): string | undefined =>
    receivedAttributes.find(({ attribute }) => attribute === attr)?.attribute;

  const isValid = (attribute: string): boolean =>
    expectedAttributes.includes(attribute);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isUpdated, setIsUpdated] = useState<boolean>(false);

  const initialAttributes = expectedAttributes.reduce(
    (obj, attribute) => ({
      ...obj,
      [attribute]: getReceivedAttribute(attribute),
    }),
    {} as {
      [attribute: string]: string | undefined;
    },
  );

  const [attributes, setAttributes] = useState(initialAttributes);

  const initialOptions = receivedAttributes
    .filter(({ attribute }) => !isValid(attribute))
    .map(({ attribute, value }) => ({
      value: attribute,
      subtitle: value || '',
    }));

  const attributeLabels = {
    [idAttribute]: 'User ID',
    [firstNameAttribute]: 'First Name',
    [lastNameAttribute]: 'Last Name',
    [emailAttribute]: 'Email',
  };

  const defaultAttributes = {
    [idAttribute]: 'id',
    [firstNameAttribute]: 'firstName',
    [lastNameAttribute]: 'lastName',
    [emailAttribute]: 'email',
  };

  const attributesUpdated = expectedAttributes.some(
    (attribute) => attributes[attribute],
  );

  const noReceivedAttributes = receivedAttributes.length === 0;

  const handleSelectAttribute = (attribute: string) => (option: string) => {
    setAttributes((attributes) => ({
      ...attributes,
      [attribute]: option,
    }));
  };

  const handleUpdateMapping = async (): Promise<void> => {
    const attributeMapId = connection.attributeMap?.id;

    if (attributeMapId) {
      setIsLoading(true);
      await graphql().UpdateAttributeMap({
        id: attributeMapId,
        first_name_attribute: attributes[firstNameAttribute],
        last_name_attribute: attributes[lastNameAttribute],
        email_attribute: attributes[emailAttribute],
        idp_id_attribute: attributes[idAttribute],
      });
      setIsLoading(false);
      setIsUpdated(true);
    }
  };

  const handleTestConnection = (): void => {
    testConnection(connection.id);
  };

  const handleFooterClick = isUpdated
    ? handleTestConnection
    : handleUpdateMapping;

  if (noReceivedAttributes) {
    return (
      <Card>
        <Card.Header>
          <Card.Title>No Attributes</Card.Title>
          <Card.Description>
            {getDescription(connection.type, appName, true)}
          </Card.Description>
        </Card.Header>
        <Card.Body>
          <ConnectionAttributeMapping connectionType={connection.type} />
        </Card.Body>
      </Card>
    );
  }

  return (
    <Card>
      <Card.Header>
        <Card.Title>Invalid Attributes</Card.Title>
        <Card.Description>
          {getDescription(connection.type, appName, false)}
        </Card.Description>
      </Card.Header>
      <Card.Body className="flex flex-col gap-4">
        {isUpdated && (
          <Alert appearance="green">
            <Text inheritColor as="p">
              Attribute mapping updated
            </Text>
          </Alert>
        )}
        <form className="flex w-full flex-col  gap-4">
          {expectedAttributes.map((attribute) => (
            <AttributeField
              key={attribute}
              attribute={attribute}
              connectionType={connection.type}
              defaultAttribute={defaultAttributes[attribute] ?? ''}
              isUpdated={isUpdated}
              label={attributeLabels[attribute] ?? ''}
              onSelect={handleSelectAttribute(attribute)}
              options={initialOptions}
              selected={attributes[attribute]}
              selections={attributes}
              valid={Boolean(initialAttributes[attribute])}
            />
          ))}
        </form>

        {(attributesUpdated || initialOptions.length > 0) && (
          <Button
            appearance="secondary"
            className="self-start"
            isLoading={isLoading}
            onClick={handleFooterClick}
          >
            {isUpdated ? 'Test Single Sign On' : 'Update Mapping'}
          </Button>
        )}
      </Card.Body>
    </Card>
  );
};

type AttributeFieldProps = {
  attribute: string;
  connectionType: ConnectionType;
  defaultAttribute: string;
  label: string;
  valid: boolean;
  options: SelectOption[];
  isUpdated: boolean;
  onSelect: (option: string) => void;
  selected?: string;
  selections: { [key: string]: string | undefined };
};

const AttributeField: FC<AttributeFieldProps> = ({
  attribute,
  connectionType,
  defaultAttribute,
  label,
  valid,
  options,
  onSelect: handleSelect,
  selected,
  selections,
  isUpdated,
}) => {
  const updated = isUpdated && selected;
  const isUnmatched = selected || options.length > 0;

  if (valid || updated) {
    return (
      <fieldset>
        <Label htmlFor="">{label}</Label>

        <div className="mt-2 flex w-[420px] items-center">
          <Pill className="w-full">{updated ? selected : attribute}</Pill>

          <div className="ml-2 flex h-[18px] w-[18px] items-center justify-center rounded-full bg-green-light">
            <Check className="text-green-darken" size={11} strokeWidth={2.5} />
          </div>
        </div>
      </fieldset>
    );
  }

  return (
    <fieldset>
      <div className="flex items-center">
        <Label htmlFor="">{label}</Label>

        <Pill appearance="red" className="ml-2" size="small">
          {isUnmatched ? 'Unmatched Attribute' : 'Missing Attribute'}
        </Pill>
      </div>

      {isUnmatched ? (
        <div className="mt-2 flex w-[420px] items-center gap-2">
          <div className="w-11/12">
            <Select
              name="invalid-attributes"
              onValueChange={(value) => handleSelect(value)}
              value={selected || 'placeholder'}
            >
              <Select.Trigger id="invalid-attributes" />

              <Select.Content>
                <Select.Item disabled value="placeholder">
                  <Select.ItemText>
                    Select an attribute from the most recent request
                  </Select.ItemText>
                </Select.Item>

                {options.map((option) => (
                  <Select.Item
                    key={option.value}
                    disabled={Object.values(selections).includes(option.value)}
                    value={option.value}
                  >
                    <Select.ItemText>
                      {option.value} - {option.subtitle}
                    </Select.ItemText>
                    <Select.ItemIndicator />
                  </Select.Item>
                ))}
              </Select.Content>
            </Select>
          </div>

          <AlertCircle className="text-red-darken" size={16} />
        </div>
      ) : (
        <div className="mt-2 grid w-[420px] grid-cols-[1fr_min-content_1fr_min-content] items-center">
          <Attributes
            attributes={getAttributesFromConfig(
              connectionType,
              defaultAttribute,
            )}
          />

          <AlertCircle className="ml-2 text-red-darken" size={16} />
        </div>
      )}

      {!isUnmatched && (
        <div className="mt-2 max-w-[400px] text-red-darken">
          <Text inheritColor as="p">
            The {attribute} attribute is missing from your SAML response. To
            resolve this issue, add it to your attribute configuration in{' '}
            {getConnectionName(connectionType)}.
          </Text>
        </div>
      )}
    </fieldset>
  );
};

const getDescription = (
  connectionType: ConnectionType,
  appName: string,
  noReceivedAttributes: boolean,
): string => {
  const provider = getConnectionName(connectionType);

  if (noReceivedAttributes) {
    return `No attributes were sent from ${provider}. To resolve this issue, update the
    configuration in ${provider} to match the values below.`;
  }

  return `Attribute mapping allows you to configure how data is transferred
  between your ${provider} connection and ${appName}. To resolve this error, update the
  attribute mapping below to match the SAML response from ${provider}.`;
};

const getAttributesFromConfig = (
  connectionType: ConnectionType,
  attribute: string,
): Attribute[] | undefined =>
  attributeMappingConfig[connectionType]?.attributeGroups.find(
    (group) => group[0]?.name === attribute,
  );
