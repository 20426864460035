import React, { useCallback, useContext, useMemo, useState } from 'react';
import {
  AnyObject,
  RecordStateDispatcher,
  useRecordState,
} from '../../hooks/use-record-state';
import { Error as FormError } from '../../interfaces/errors';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const FormDataContext = React.createContext<FormContext<any> | null>(null);

export const FormDataProvider = ({
  initialFormData,
  initialFormDataErrors,
  children,
}: React.PropsWithChildren<FormDataProviderProps>) => {
  const [formData, setFormData] = useRecordState<AnyObject>(
    initialFormData ?? {},
  );
  const [formErrors, setFormErrors] = useState<FormDataErrors<AnyObject>>(
    initialFormDataErrors ?? {},
  );

  const resetForm = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const resetObject = (data: Record<string, any>) => {
      Object.keys(data).forEach((key) => {
        data[key] = undefined;
      });

      return data;
    };

    setFormData(resetObject(formData));
    setFormErrors(resetObject(formErrors));
  }, [formData, formErrors, setFormData, setFormErrors]);

  const context = useMemo(
    () => ({
      formData,
      formErrors,
      resetForm,
      setFormData,
      setFormErrors,
    }),
    [formData, formErrors, resetForm, setFormData, setFormErrors],
  );

  return (
    <FormDataContext.Provider value={context}>
      {children}
    </FormDataContext.Provider>
  );
};

export function useFormContext<TFormData extends AnyObject>() {
  const contextVal = useContext<FormContext<TFormData> | null>(FormDataContext);

  if (!contextVal) {
    throw new Error(
      "Form context can't be undefined: Please make sure to wrap the app in FormDataProvider ",
    );
  }

  return contextVal;
}

export type FormDataErrors<TFormData extends AnyObject> = Partial<
  Record<keyof TFormData, FormError>
>;
export interface FormContext<TFormData extends AnyObject> {
  formData: TFormData;
  setFormData: RecordStateDispatcher<Partial<TFormData>>;
  formErrors: FormDataErrors<TFormData>;
  setFormErrors: RecordStateDispatcher<FormDataErrors<TFormData>>;
  resetForm: () => void;
}

interface FormDataProviderProps {
  initialFormData?: AnyObject;
  initialFormDataErrors?: FormDataErrors<AnyObject>;
}
