import { FC } from 'react';
import { StepData } from '../../../../shared/step-navigation';
import { useSsoStepRoutes } from '../../hooks/use-sso-step-routes';
import { MetadataXmlFormFields } from '../../interfaces/metadata-form-fields';
import { SsoStepNavigation } from '../../sso-step-navigation';
import { generateSsoStepData } from '../../utils/generate-sso-step-data';
import { setupUnsolicitedSaml } from './1-setup-unsolicited-saml';
import { configureSamlAttributes } from './2-configure-saml-attributes';
import { uploadIdentityProviderMetadata } from './3-upload-identity-provider-metadata';

interface ShibbolethUnsolicitedSamlStepsProps {}

const stepsData: StepData<MetadataXmlFormFields>[] = generateSsoStepData([
  setupUnsolicitedSaml,
  configureSamlAttributes,
  uploadIdentityProviderMetadata,
]);

export const ShibbolethUnsolicitedSamlSteps: FC<
  Readonly<ShibbolethUnsolicitedSamlStepsProps>
> = () => {
  const stepRoutes = useSsoStepRoutes(stepsData);

  return (
    <SsoStepNavigation stepsData={stepsData}>{stepRoutes}</SsoStepNavigation>
  );
};
