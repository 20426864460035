import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { CopyInput } from '../../../../components/inputs';
import { ExternalLink } from '../../../../components/link';
import { usePortalSession } from '../../../../components/portal-session-provider';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';
import { useSsoStore } from '../../sso-store-provider';

const Component: FC = () => {
  const { connection } = useSsoStore();
  const { appName } = usePortalSession();

  return (
    <StepContent confirmLabel="I've created and configured a SAML application.">
      <Text>
        Follow the{' '}
        <ExternalLink
          newTab
          appearance="highlight"
          href="https://docs.oracle.com/en/cloud/paas/identity-cloud/uaids/add-saml-application.html"
        >
          Oracle Cloud documentation
        </ExternalLink>{' '}
        to create a new SAML application.
      </Text>

      <Text>
        Copy and paste the ACS URL and SP Entity ID into the corresponding
        fields for Service Provider details and configuration.
      </Text>

      <CopyInput
        id="acs-url"
        label="Copy this ACS URL"
        value={connection.saml_acs_url}
      />

      <Text>
        The Service Provider Entity ID is a unique value which represents{' '}
        {appName} within Oracle. Provide the value below to your Oracle SAML
        application settings.
      </Text>

      <CopyInput
        id="sp-entity-id"
        label="Copy this SP Entity ID"
        value={connection.saml_entity_id}
      />

      <Text>
        In the Advanced Settings of the SSO Configuration page, ensure that you
        select Signed SSO for Assertion and Response, and Include Signing
        Certificate in Signature.
      </Text>
    </StepContent>
  );
};

export const createASamlApplication: StepData = {
  title: 'Create a SAML Application',
  component: Component,
};
