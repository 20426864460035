import { InputGroup } from '@workos-inc/component-library';
import { ChangeEvent, FC, useState } from 'react';
import { Card } from '../../../../components/card';
import { Form } from '../../../../components/form';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { useStepsStore } from '../../../../shared/step-navigation/steps-store-provider';
import { graphql } from '../../../../utils/graphql';

const Component: FC = () => {
  const { handleNextStep } = useStepsStore();

  const [directorySource, setDirectorySource] = useState('');

  const handleDirectorySubmit = (): void => {
    void graphql().RequestIdentityProvider({
      identityProvider: directorySource,
      pageRequestedFrom: 'Generic SCIM',
    });

    void handleNextStep();
  };

  return (
    <Card>
      <Form
        isInline
        disabled={!directorySource}
        onSubmit={handleDirectorySubmit}
      >
        <InputGroup
          autoFocus
          id="directory_source"
          label="Which Directory Provider are you connecting to?"
          name="directory_source"
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            setDirectorySource(event.target.value)
          }
          placeholder="e.g. Okta, Azure AD, Google Workspace..."
          value={directorySource}
        />
      </Form>
    </Card>
  );
};

export const provideDirectoryInformation: StepData = {
  title: 'Provide Directory Information',
  component: Component,
};
