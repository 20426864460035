import { InputGroup, Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Card } from '../../../../components/card';
import { FileField } from '../../../../components/fields';
import { Form } from '../../../../components/form';
import { Img } from '../../../../components/image';
import { useFormContext } from '../../../../shared/form-data-provider';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { useStepsStore } from '../../../../shared/step-navigation/steps-store-provider';
import { SsoFormMetadata } from '../../interfaces/sso-form-metadata';
import { CloudFlareSamlFormFields } from './cloudflare-saml-steps';

const Component: FC = () => {
  const { handleInputChange, handleNextStep, isUpdating } = useStepsStore();
  const { formData, setFormData, formErrors } =
    useFormContext<SsoFormMetadata>();
  return (
    <>
      <Text>
        Copy the "SSO Endpoint" and "Access Entity ID or Issuer" values and
        paste them in to the "IdP SSO URL" (SSO Endpoint) and "Identity Provider
        Issuer" (Access Entity ID) fields below.
      </Text>

      <Text>
        Upload the X.509 certificate that you formatted from the Public Key in
        step 4.
      </Text>

      <Img
        className="my-8"
        height={1962}
        src="/images/606a34d5-2816-48cf-bf00-93bcade272b4.png"
        width={2952}
      />

      <Card>
        <Form
          disabled={
            !formData.saml_idp_url ||
            !formData.saml_x509_certificates ||
            formErrors.saml_idp_url?.value === formData.saml_idp_url
          }
          isLoading={isUpdating}
          isUpdate={
            !!(formErrors.saml_idp_url || formErrors.saml_x509_certificates)
          }
          onSubmit={handleNextStep}
        >
          <InputGroup
            error={formErrors.saml_idp_url?.message}
            id="saml_idp_url"
            label="1. Identity Provider Single Sign-On URL"
            name="saml_idp_url"
            onChange={handleInputChange}
            placeholder={
              'https://your-team-id.cloudflareaccess.com/cdn-cgi/access/sso/saml/unique-id'
            }
            value={formData.saml_idp_url ?? ''}
          />

          <InputGroup
            id="saml_idp_entity_id"
            label="2. Identity Provider Issuer"
            name="saml_idp_entity_id"
            onChange={handleInputChange}
            placeholder={'https://your-team-id.cloudflareaccess.com'}
            value={formData.saml_idp_entity_id}
          />

          <FileField
            aria-label="upload_x509_certificate"
            error={formErrors.saml_x509_certificates}
            label="3. X.509 Certificate"
            name="saml_x509_certs"
            onUpload={({ file }) => {
              setFormData({
                saml_x509_certificates: [file.content],
              });
            }}
            value={formData?.saml_x509_certificates?.[0]}
          />
        </Form>
      </Card>
    </>
  );
};

export const uploadIdentityProviderMetadata: StepData<CloudFlareSamlFormFields> =
  {
    title: 'Upload Identity Provider Metadata',
    component: Component,
    formKeys: ['saml_idp_entity_id', 'saml_idp_url', 'saml_x509_certificates'],
  };
