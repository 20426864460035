import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { DownloadInput } from '../../../../components/inputs';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';
import { useSsoStore } from '../../sso-store-provider';

const Component: FC = () => {
  const { connection } = useSsoStore();

  return (
    <StepContent confirmLabel="I’ve selected “Permit everyone” and clicked on “Next”.">
      <Text>Open the AD FS Management console.</Text>

      <Img
        className="my-8"
        height={853}
        src="/images/a595dd78-07ff-4419-9675-af2e4a3434e6.png"
        width={1186}
      />

      <Text>Click “Relying Party Trusts” on the left sidebar.</Text>

      <Img
        className="my-8"
        height={853}
        src="/images/d6664d66-8c54-4689-8ed9-2e7883ecc272.png"
        width={1186}
      />

      <Text>
        Open the “AD FS Relying Party Trust Wizard” by clicking “Add Relying
        Party Trust...” on the right sidebar.
      </Text>

      <Img
        className="my-8"
        height={853}
        src="/images/1aaf2490-580f-4930-91b4-258326b751c3.png"
        width={1186}
      />

      <Text>Select “Claims aware” and then “Start”.</Text>

      <Text>Download the provided Metadata file from WorkOS.</Text>

      <DownloadInput
        filename="metadata.xml"
        label="Your SP Metadata File"
        value={connection.saml_sp_metadata_url}
      />

      <Text>
        Select “Import data about the relying party from a file,” then select
        the SP Metadata file you downloaded, then click “Next”.
      </Text>

      <Img
        className="my-8"
        height={853}
        src="/images/9cf7ee4f-09d8-4dcc-ab92-29732ca3c691.png"
        width={1186}
      />

      <Text>Select “Permit everyone” and then “Next”.</Text>

      <Img
        className="my-8"
        height={853}
        src="/images/94d90815-ef73-4cf5-9a8e-9b85108163d3.png"
        width={1186}
      />
    </StepContent>
  );
};

export const configureARelyingPartyTrust: StepData = {
  title: 'Configure a Relying Party Trust',
  component: Component,
};
