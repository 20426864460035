import { unreachable } from '@workos-inc/standard';
import { ConnectionFragment } from '../../../../../../graphql/generated';
import { SelectedConfiguration } from '../../../interfaces/selected-configuration';
import { configurationTypeForConnectionType } from './connection-configuration-type';

interface GetUpdateTypeArg {
  connection: ConnectionFragment;
  selectedConfiguration: SelectedConfiguration;
  isOktaOinEnabled?: boolean;
  isOneLoginOacEnabled?: boolean;
}

export const getUpdateType = ({
  connection,
  isOktaOinEnabled,
  isOneLoginOacEnabled,
  selectedConfiguration,
}: GetUpdateTypeArg) => {
  const configurationType = !!connection
    ? configurationTypeForConnectionType(connection.type, {
        isOktaOinEnabled,
        isOneLoginOacEnabled,
      })
    : ({ tag: 'None' } as const);

  switch (configurationType.tag) {
    case 'Saml': {
      if (selectedConfiguration === 'manual') {
        return 'Manual';
      }
      return configurationType.samlConfigurationType;
    }
    case 'Oidc':
      return 'Manual';
    case 'None':
      return undefined;
    default:
      return unreachable(configurationType);
  }
};
