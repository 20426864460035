import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';

const Component: FC = () => (
  <StepContent confirmLabel='I’ve enabled "Sign Assertion" and "Include Assertion Signature"'>
    <Text>Continue scrolling and expand "Advanced Properties".</Text>
    <Img
      className="my-8"
      height={1598}
      src="/images/ce886446-89ee-4649-8d67-ee5dc3d09106.png"
      width={2692}
    />
    <Text>Enable "Sign Assertion" and "Include Assertion Signature".</Text>
    <Img
      className="my-8"
      height={1598}
      src="/images/ae25e3dd-4167-4ed4-9838-48e2ede08d4c.png"
      width={2692}
    />{' '}
  </StepContent>
);

export const advancedSamlConfiguration: StepData = {
  title: 'Advanced SAML Configuration',
  component: Component,
};
