import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';
import { ConnectionAttributeMapping } from '../../attribute-mapping';
import { useSsoStore } from '../../sso-store-provider';

const Component: FC = () => {
  const { connection } = useSsoStore();
  return (
    <StepContent confirmLabel="I’ve finished configuring the SAML claims.">
      <Text>
        The final step is to make sure that you are mapping the required
        attributes: <code>id</code>, <code>email</code>, <code>firstName</code>,
        and <code>lastName</code>. In the Map Attributes section enter these on
        the right side under SAML Response Attribute. On the left side, click
        the empty field box and select the pre-populated values that look like
        e.g. "{'<Email Address>'}". Duo will automatically grab the
        corresponding fields and map them to the expected values.
      </Text>

      <Text>
        You can map any values you like, but these four values are required in
        SAML responses. If your users don’t have a Last Name value for instance,
        you could map Display Name or any other value to <code>lastName</code>.
        The SAML response will be rejected if <code>lastName</code> is not
        included.
      </Text>

      <Text>
        Ensure the Match attributes section of your General Service Provider
        Application Settings page in Duo aligns with the following.
      </Text>

      <ConnectionAttributeMapping connectionType={connection.type} />
    </StepContent>
  );
};

export const configureTheSamlClaims: StepData = {
  title: 'Configure the SAML Claims',
  component: Component,
};
