import { InputGroup, Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { ConnectionStepProps } from '../../../../interfaces/step-props';
import { Card } from '../../../card';
import { FileField } from '../../../fields';
import { Form } from '../../../form';

export interface ManualMetadataStepProps extends ConnectionStepProps {
  onConfigurationTypeChange: (configurationType: 'dynamic') => void;
  idpEntityIdPlaceholder: string;
  idpSsoUrlPlaceholder: string;
}

export const ManualMetadataStep: FC<Readonly<ManualMetadataStepProps>> = ({
  connection,
  connectionUpdatedFields,
  setConnectionUpdatedFields,
  errors,
  idpEntityIdPlaceholder,
  idpSsoUrlPlaceholder,
  isLoading,
  onInputChange: handleInputChange,
  onNextStep: handleNextStep,
  onConfigurationTypeChange,
  validationErrors,
}) => (
  <div>
    <Text as="h2" size="large" weight="medium">
      Manual Configuration
    </Text>

    <Text>
      See your Identity Provider’s documentation on how to configure a Service
      Provider.
    </Text>

    <Card>
      <Form
        disabled={
          !connection?.saml_idp_url ||
          !connectionUpdatedFields?.samlX509Certificates ||
          validationErrors?.saml_idp_url?.value === connection?.saml_idp_url
        }
        isLoading={isLoading}
        isUpdate={!!(errors?.saml_idp_url || errors?.samlX509Certificates)}
        onSubmit={handleNextStep}
        secondaryAction={() => onConfigurationTypeChange('dynamic')}
        secondaryText="Configure Dynamically"
      >
        <InputGroup
          error={
            errors?.saml_idp_url?.message ||
            validationErrors?.saml_idp_url?.message
          }
          id="saml_idp_url"
          label="1. Identity Provider Single Sign-On URL"
          name="saml_idp_url"
          onChange={handleInputChange}
          placeholder={idpSsoUrlPlaceholder}
          value={connection?.saml_idp_url ?? ''}
        />

        <InputGroup
          id="saml_idp_entity_id"
          label="2. Identity Provider Issuer"
          name="saml_idp_entity_id"
          onChange={(event) =>
            setConnectionUpdatedFields((fields) => ({
              ...fields,
              samlIdpEntityId: event.target.value,
            }))
          }
          placeholder={idpEntityIdPlaceholder}
          value={connectionUpdatedFields.samlIdpEntityId}
        />

        <FileField
          error={errors?.samlX509Certificates}
          label="3. X.509 Certificate"
          name="saml_x509_certs"
          onUpload={({ file }) => {
            setConnectionUpdatedFields({
              samlX509Certificates: [file.content],
            });
          }}
          value={connectionUpdatedFields?.samlX509Certificates?.[0]}
        />
      </Form>
    </Card>
  </div>
);
