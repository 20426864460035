import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ListMetadata } from '../../../../graphql/generated';

function getSearchParamsObj(searchParams: URLSearchParams) {
  const searchParamsObj: { [key: string]: string | string[] } = {};

  for (const [key, value] of searchParams.entries()) {
    searchParamsObj[key] = value;
  }

  return searchParamsObj;
}

interface PaginationProps {
  hasNext: boolean;
  hasPrevious: boolean;
  onNextPage: () => void;
  onPreviousPage: () => void;
}

export interface PaginatedData<T> {
  data: T[];
  listMetadata: ListMetadata;
}

interface PaginationOptions {
  pushToSearchParams?: boolean;
}

export type PaginatedRequest<T> = (listMetadata: ListMetadata) => Promise<{
  data: T;
  extensions?: unknown;
  headers: unknown;
  status: number;
}>;

export function usePaginated<T, TEntity>(
  paginatedRequest: PaginatedRequest<T>,
  extractPaginatedData: (data: T) => PaginatedData<TEntity>,
  paginationOptions: PaginationOptions = {},
) {
  const { pushToSearchParams } = paginationOptions;
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState<T>();

  useEffect(() => {
    if (pushToSearchParams) {
      setSearchParams(getSearchParamsObj(searchParams), { replace: true });
    }
  }, [pushToSearchParams, setSearchParams, searchParams]);

  useEffect(() => {
    async function runPaginatedRequest() {
      const response = await paginatedRequest({
        before: searchParams.get('before'),
        after: searchParams.get('after'),
      });

      setData(response.data);
    }

    void runPaginatedRequest();
  }, [searchParams]); // eslint-disable-line react-hooks/exhaustive-deps

  const paginatedData = data ? extractPaginatedData(data) : undefined;

  const { listMetadata } = paginatedData ?? {
    data: undefined,
    listMetadata: undefined,
  };

  const pagination: PaginationProps = {
    hasPrevious: !!listMetadata?.after,
    hasNext: !!listMetadata?.before,
    onPreviousPage: () => {
      searchParams.delete('before');
      if (listMetadata?.after) {
        searchParams.set('after', listMetadata.after);
      }

      setSearchParams(getSearchParamsObj(searchParams), { replace: true });
    },
    onNextPage: () => {
      searchParams.delete('after');
      if (listMetadata?.before) {
        searchParams.set('before', listMetadata.before);
      }

      setSearchParams(getSearchParamsObj(searchParams), { replace: true });
    },
  };

  return [data, pagination] as const;
}
