import { CreateAuditLogExportInput } from '../../../../../graphql/generated';

export enum DataSet {
  All = 'all',
  Filtered = 'filtered',
}

export type ExportFilters = Pick<
  CreateAuditLogExportInput,
  'actions' | 'actors' | 'targets'
>;

export type ExportDate = Pick<
  CreateAuditLogExportInput,
  'rangeStart' | 'rangeEnd'
>;
