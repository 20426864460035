import { Card, Pill, Table, Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { DirectoryUserFragment as DirectoryUser } from '../../../../../../graphql/generated';
import { getDirectoryUserStateBadge } from '../../../utils/directory-user-state-badges';
import { GroupsCell } from './directory-users-table-group-cell';

type DirectoryUsersTableProps = {
  users: DirectoryUser[];
};

const TABLE_HEADERS = [
  {
    name: 'First Name',
    id: 'firstName',
  },
  {
    name: 'Last Name',
    id: 'lastName',
  },
  {
    name: 'Username',
    id: 'username',
  },
  {
    name: 'State',
    id: 'state',
  },
  {
    name: 'Groups',
    id: 'directoryGroups',
  },
];

export const DirectoryUsersTable: FC<Readonly<DirectoryUsersTableProps>> = ({
  users,
}) => (
  <Card>
    <Card.Header>
      <Card.Title>Users</Card.Title>
    </Card.Header>

    <Card.Body className="mt-4 p-0">
      {users.length ? (
        <Table>
          <Table.RowGroup as="thead">
            {TABLE_HEADERS.map((header) => (
              <Table.ColumnHeader
                key={header.id}
                className={
                  'border-b border-gray-lightmode-200 dark:border-gray-darkmode-200'
                }
              >
                {header.name}
              </Table.ColumnHeader>
            ))}
          </Table.RowGroup>

          <Table.RowGroup as="tbody">
            {users.map((user) => (
              <Table.Row key={user.id} asChild>
                <div>
                  <Table.Cell>{user.firstName}</Table.Cell>
                  <Table.Cell>{user.lastName}</Table.Cell>
                  <Table.Cell>{user.username}</Table.Cell>
                  <Table.Cell>
                    <Pill
                      appearance={getDirectoryUserStateBadge(user.state)}
                      size="small"
                    >
                      {user.state}
                    </Pill>
                  </Table.Cell>
                  <Table.Cell>
                    {user.directoryGroups.length ? (
                      <GroupsCell
                        groups={user.directoryGroups}
                        rowId={user.id}
                      />
                    ) : (
                      <>{'-'}</>
                    )}
                  </Table.Cell>
                </div>
              </Table.Row>
            ))}
          </Table.RowGroup>
        </Table>
      ) : (
        <div className="p-6 text-center">
          <Text as="p">There are no users for this directory.</Text>
        </div>
      )}
    </Card.Body>
  </Card>
);
