import { FC } from 'react';
import { StepData } from '../../../../shared/step-navigation';
import { useSsoStepRoutes } from '../../hooks/use-sso-step-routes';
import { SsoStepNavigation } from '../../sso-step-navigation';
import { generateSsoStepData } from '../../utils/generate-sso-step-data';
import { createAProjectInTheAdpAppsPortal } from './1-create-a-project-in-the-adp-apps-portal';
import { configureTheRedirectUriInAdp } from './2-configure-the-redirect-uri-in-adp';
import { provideTheClientIdAndSecretFromAdp } from './3-provide-the-client-id-and-secret-from-adp';
import { uploadTheSslCertificateAndPrivateKeyFromAdp } from './4-upload-the-ssl-certificate-and-private-key-from-adp';

interface AdpOidcStepsProps {}

export type AdpOidcFormFields = {
  oidc_client_id: string;
  oidc_client_secret: string;
};

const stepsData: StepData<AdpOidcFormFields>[] = generateSsoStepData([
  createAProjectInTheAdpAppsPortal,
  configureTheRedirectUriInAdp,
  provideTheClientIdAndSecretFromAdp,
  uploadTheSslCertificateAndPrivateKeyFromAdp,
]);

export const AdpOidcSteps: FC<Readonly<AdpOidcStepsProps>> = () => {
  const stepRoutes = useSsoStepRoutes(stepsData);

  return (
    <SsoStepNavigation stepsData={stepsData}>{stepRoutes}</SsoStepNavigation>
  );
};
