import {
  InputFile,
  Label,
  SelectedFile,
  Text,
} from '@workos-inc/component-library';
import { FC } from 'react';
import { Error } from '../../interfaces/errors';

export type FileInputChangeEvent = (event: {
  name: string;
  file: SelectedFile;
}) => void;

interface FileInputProps {
  accept?: string;
  error?: Error;
  id?: string;
  label?: string;
  name: string;
  onUpload: FileInputChangeEvent;
  value?: string;
}

export const FileField: FC<Readonly<FileInputProps>> = ({
  accept = '.cer, .cert, .crt, .key, .pem',
  error,
  id = '',
  label,
  name,
  onUpload: handleUpload,
  value,
}) => {
  const isError = error && error.value === value;
  return (
    <fieldset>
      <Label className="mb-2" htmlFor={name}>
        {label}
      </Label>

      <InputFile
        accept={accept}
        className="mt-2 break-all"
        data-testid={`file-input-${id}`}
        id={id}
        label=""
        name={name}
        onChange={handleUpload}
        onClear={() => handleUpload({ name, file: { name, content: '' } })}
      />

      {isError && (
        <div className="text-red-darken">
          <Text inheritColor className="font-medium" size="small">
            {error?.message}
          </Text>
        </div>
      )}
    </fieldset>
  );
};
