import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { CopyInput } from '../../../../components/inputs';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';
import { useSsoStore } from '../../sso-store-provider';

const Component: FC = () => {
  const { connection } = useSsoStore();

  return (
    <StepContent confirmLabel="I've configured the Entity ID.">
      <Text>
        Select "Applications" from the top menu of your PingFederate
        Administrator Dashboard, then select "SP Connections".
      </Text>

      <Img
        className="my-8"
        height={1452}
        src="/images/4cac68f3-4a42-47aa-ba43-7025037ad908.png"
        width={2108}
      />

      <Text>
        On the "SP Connections" page, select the "Create Connection" button to
        begin creating a SAML SP Connection.
      </Text>

      <Img
        className="my-8"
        height={1196}
        src="/images/c7947415-c9db-4a54-a523-be12e07d6142.png"
        width={2108}
      />

      <Text>
        On the "Connection Template" page, select "Do not use a template for
        this connection" and click "Next".
      </Text>

      <Img
        className="my-8"
        height={1140}
        src="/images/9f1463bc-eb40-43d2-9880-c8da295ea3f0.png"
        width={2298}
      />

      <Text>
        On the "Connection Type" page, select "Browser SSO Profiles" with the
        "SAML 2.0" Protocol, and click "Next".
      </Text>

      <Img
        className="my-8"
        height={1364}
        src="/images/4f3857d3-a8c3-4529-a2aa-ce1826f0f232.png"
        width={2298}
      />

      <Text>
        On the "Connection Options" page, select only "Browser SSO" and click
        "Next".
      </Text>

      <Img
        className="my-8"
        height={1364}
        src="/images/069215c9-866b-432e-8a27-f107913533be.png"
        width={2298}
      />

      <Text>
        On the "Import Metadata" page, select "None" and click "Next".
      </Text>

      <Img
        className="my-8"
        height={1118}
        src="/images/9f6da8a2-0c42-490d-9708-8269f381f4c6.png"
        width={2298}
      />

      <Text>
        On the "General Info" page, paste the IdP URI (Entity ID) into the
        "Partner's Entity ID (Connection ID)" field, and add a unique name in
        the "Connection Name" field, then click "Next".
      </Text>

      <CopyInput
        id="idp-uri"
        label="Copy this IdP URI (Entity ID)"
        value={connection.saml_entity_id}
      />

      <Img
        className="my-8"
        height={1624}
        src="/images/0ee89afd-d22a-4343-9e90-5cd87af441aa.png"
        width={2298}
      />
    </StepContent>
  );
};

export const createSamlSpConnection: StepData = {
  title: 'Create SAML SP Connection',
  component: Component,
};
