import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';

const Component: FC = () => (
  <StepContent confirmLabel="I’ve selected my JumpCloud application.">
    <Text>
      Log in to the JumpCloud admin dashboard, select "SSO" on the left and
      select your Application.
    </Text>

    <Img
      className="my-8"
      height={3996}
      src="/images/c1dbceee-1b3e-4c53-be26-bc5514e6fe1f.png"
      width={8124}
    />

    <Text>
      If you haven't created an application, you'll need to first create a
      custom SAML application in JumpCloud. JumpCloud only supports configuring
      SCIM provisioning in an existing SAML application. Make sure to create
      your SAML application before moving on to SCIM provisioning.
    </Text>
  </StepContent>
);

export const selectJumpCloudApplication: StepData = {
  title: 'Select JumpCloud Application',
  component: Component,
};
