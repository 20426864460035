import { DirectoryType } from '../../../../graphql/generated';

// Note: Add any new SCIM directory types to ScimDirectoryType below in
// addition to supportedDirectoryTypes
export const supportedDirectoryTypes = [
  DirectoryType.AzureScimv2_0,
  DirectoryType.BambooHr,
  DirectoryType.BreatheHr,
  DirectoryType.CezanneHr,
  DirectoryType.CyberArkScimV2_0,
  DirectoryType.FourthHr,
  DirectoryType.OktaScimv2_0,
  DirectoryType.PeopleHr,
  DirectoryType.PingFederateScimV2_0,
  DirectoryType.RipplingScimV2_0,
  DirectoryType.GoogleWorkspace,
  DirectoryType.Hibob,
  DirectoryType.JumpCloudScimV2_0,
  DirectoryType.OneLoginScimV2_0,
  DirectoryType.Workday,
  DirectoryType.GenericScimv1_1,
  DirectoryType.GenericScimv2_0,
] as const;

export type ScimDirectoryType =
  | DirectoryType.AzureScimv2_0
  | DirectoryType.CyberArkScimV2_0
  | DirectoryType.GenericScimv1_1
  | DirectoryType.GenericScimv2_0
  | DirectoryType.JumpCloudScimV2_0
  | DirectoryType.OktaScimv1_1
  | DirectoryType.OktaScimv2_0
  | DirectoryType.OneLoginScimV2_0
  | DirectoryType.PingFederateScimV2_0
  | DirectoryType.RipplingScimV2_0;

export type SupportedDirectoryType = typeof supportedDirectoryTypes[number];
