import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { MapAttributesStep } from '../../map-attributes-step/map-attributes-step';

const Component: FC = () => (
  <MapAttributesStep>
    <Text>
      There are additional attributes needed by the application for it to fully
      function. Note that basic attributes like a user's name or email are
      already mapped, and so are not present below.
    </Text>

    <Text>
      The attributes that will be available to map from PingFederate will be
      determined by what you have set up in step 5 and may be unique to each
      configuration.
    </Text>

    <Text>
      Refer to the Attribute Mapping section of your PingFederate SP Connection
      to review the attributes that are available.
    </Text>

    <Img
      height={900}
      src="/admin-portal/directory-sync/pingfederate-scim/v1/pingfederate-scim-13.png"
      width={2000}
    />

    <Text>
      Map attributes from your Directory Provider's responses to the attributes
      shown below.
    </Text>
  </MapAttributesStep>
);

export const setUpAttributeMapping: StepData = {
  title: 'Set up Attribute Mapping',
  customAttributesStep: true,
  component: Component,
};
