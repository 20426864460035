import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { usePortalSession } from '../../../../components/portal-session-provider';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';

const Component: FC = () => {
  const { appName } = usePortalSession();

  return (
    <StepContent confirmLabel="I’ve finished naming the SAML Application.">
      <Text>
        From the LastPass admin console, select "Applications" on the top
        navigation. Then select "SSO apps" from the left side navigation. Click
        on the "Add app" button.
      </Text>

      <Img
        height={1360}
        src="/images/e6cc5fbc-e802-4cd4-b3d6-786c31ee7db2.png"
        width={2486}
      />

      <Text>In the modal that pops up, click on "Add an unlisted app".</Text>

      <Img
        height={1258}
        src="/images/fad2dc44-73a1-4b98-b8bb-ee02e7293dfd.png"
        width={2158}
      />

      <Text>
        Give your SAML App a descriptive name such as {appName} and select
        "Continue".
      </Text>

      <Img
        height={1258}
        src="/images/839575b4-8b1c-45e5-ab10-8fcc509fc1be.png"
        width={2158}
      />
    </StepContent>
  );
};

export const createSamlApplication: StepData = {
  title: 'Create SAML Application',
  component: Component,
};
