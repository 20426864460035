import { Breadcrumb } from '@workos-inc/component-library';
import { FC } from 'react';
import { Link } from '../../../components/link/internal-link';

interface AuditLogsBreadcrumbProps {
  auditLogId?: string;
}

export const AuditLogsBreadcrumb: FC<AuditLogsBreadcrumbProps> = ({
  auditLogId,
}) => (
  <Breadcrumb>
    <Breadcrumb.Item asChild>
      <Link href="/audit-logs/events">Audit Logs</Link>
    </Breadcrumb.Item>

    {auditLogId && (
      <>
        <Breadcrumb.Separator />

        <Breadcrumb.Item isCurrent>{auditLogId}</Breadcrumb.Item>
      </>
    )}
  </Breadcrumb>
);
