import {
  Alert,
  Button,
  Dialog,
  InputGroup,
  Text,
} from '@workos-inc/component-library';
import { unreachable } from '@workos-inc/standard';
import { ChangeEvent, FC, FormEvent, useState } from 'react';
import { RefreshCcw } from 'react-feather';
import { SamlX509CertificateValidationError } from '../../../../../../components/sso/connection/saml-x509-certificate-validation-error';
import { useToast } from '../../../../../../utils/toast-context';
import { graphql } from '../../../../../utils/graphql';
import { logError } from '../../../../../utils/logger';
import { useSsoStore } from '../../../sso-store-provider';

interface ConnectionMetadataUrlProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onIsManualChange: (isManual: boolean) => void;
}

export const ConnectionMetadataUrl: FC<
  Readonly<ConnectionMetadataUrlProps>
> = ({ open, onOpenChange: handleOpenChange, onIsManualChange }) => {
  const { showToast } = useToast();
  const { connection, setSsoStore } = useSsoStore();

  const [samlIdpMetadataUrl, setSamlIdpMetadataUrl] = useState(
    connection.saml_idp_metadata_url,
  );

  const [isUpdatingConnectionMetadata, setIsUpdatingConnectionMetadata] =
    useState(false);
  const [connectionUpdateError, setConnectionUpdateError] =
    useState<JSX.Element>();

  const handleInputChange = async (
    event: ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    setSamlIdpMetadataUrl(event.target.value);
  };

  const handleUpdateConnection = async (event: FormEvent) => {
    event.preventDefault();
    if (!samlIdpMetadataUrl || !connection) {
      return;
    }

    try {
      setIsUpdatingConnectionMetadata(true);

      const response = await graphql().UpdateConnectionFromMetadataUrl({
        input: {
          connectionId: connection.id,
          metadataUrl: samlIdpMetadataUrl,
        },
      });

      const result = response.data?.portal_updateConnectionFromMetadataUrl;

      switch (result?.__typename) {
        case 'Portal_ConnectionUpdatedFromMetadataUrl': {
          const { connection: updatedConnection } = result;

          handleOpenChange(false);
          setConnectionUpdateError(undefined);

          showToast({
            title: `${updatedConnection.name} connection updated`,
            description:
              'Your changes have been successfully applied to the connection.',
          });

          setSsoStore({ connection: updatedConnection });
          break;
        }
        case 'ConnectionNotFound': {
          setConnectionUpdateError(
            <Text as="p">
              The connection {result.connectionId} could not be found.
            </Text>,
          );

          break;
        }
        case 'Portal_MetadataFetchFailed': {
          setConnectionUpdateError(<Text as="p">{result.reason}</Text>);

          break;
        }
        case 'InvalidSamlX509Certificate': {
          setConnectionUpdateError(
            <SamlX509CertificateValidationError error={result} />,
          );

          break;
        }
        case undefined:
          break;
        default:
          return unreachable(result);
      }
    } catch (error) {
      logError(error);

      setConnectionUpdateError(<>Metadata URL is not valid.</>);
    } finally {
      setIsUpdatingConnectionMetadata(false);
    }
  };

  return (
    <Dialog
      acceptButtonProps={{
        type: 'submit',
        form: 'urlConnectionMetadataForm',
      }}
      acceptText="Save Metadata Configuration"
      isAcceptDisabled={!samlIdpMetadataUrl}
      isLoading={isUpdatingConnectionMetadata}
      onOpenChange={handleOpenChange}
      open={open}
      title="URL Metadata Configuration"
      titleSuffix={
        <Button
          appearance="secondary"
          iconLeft={<RefreshCcw size={16} />}
          onClick={() => onIsManualChange(true)}
          size="small"
        >
          Switch to Manual Configuration
        </Button>
      }
    >
      <form id="urlConnectionMetadataForm" onSubmit={handleUpdateConnection}>
        <InputGroup
          id="saml_idp_metadata_url"
          label="Metadata URL"
          name="saml_idp_metadata_url"
          onChange={handleInputChange}
          placeholder="https://"
          value={samlIdpMetadataUrl ?? ''}
        />

        {connectionUpdateError && (
          <Alert appearance="red" className="mt-6">
            <Text inheritColor as="p">
              {connectionUpdateError}
            </Text>
          </Alert>
        )}
      </form>
    </Dialog>
  );
};
