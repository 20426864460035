import { Pill, Table, Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { ConnectionSessionFragment } from '../../../../../../graphql/deserializers/connection-session-deserializer';
import { Link } from '../../../../../components/link/internal-link';
import { formatDate, formatName } from '../../../../../utils/formatters';
import { getSessionStateBadge } from '../../../utils/get-session-state-badges';

export interface ConnectionRecentEventsTableProps {
  events: ConnectionSessionFragment[];
}

const TABLE_HEADERS = [
  {
    name: 'Email',
    id: 'profileEmail',
  },
  {
    name: 'Name',
    id: 'profile',
  },
  {
    name: 'State',
    id: 'state',
  },
  {
    name: 'Timestamp',
    id: 'createdAt',
  },
];

export const ConnectionRecentEventsTable: FC<
  Readonly<ConnectionRecentEventsTableProps>
> = ({ events }) => {
  const formattedEvents = events.map((event) => ({
    id: event.id,
    profile: event.profile,
    state: event.state,
    createdAt: event.createdAt,
    href: `/sso/session/${event.id}`,
    profileEmail: event.profile?.email ?? '-',
  }));

  return (
    <Table>
      <Table.RowGroup as="thead">
        {TABLE_HEADERS.map((header) => (
          <Table.ColumnHeader
            key={header.id}
            className={
              'border-b border-gray-lightmode-200 dark:border-gray-darkmode-200'
            }
          >
            {header.name}
          </Table.ColumnHeader>
        ))}
      </Table.RowGroup>

      <Table.RowGroup as="tbody">
        {formattedEvents.map((event) => (
          <Table.Row key={event.id} asChild>
            <Link href={event.href}>
              <Table.Cell>
                <Text as="p" size="small">
                  {event.profileEmail}
                </Text>
              </Table.Cell>
              <Table.Cell>{formatName(event.profile)}</Table.Cell>
              <Table.Cell>
                <Pill
                  appearance={getSessionStateBadge(event.state)}
                  size="small"
                >
                  {event.state}
                </Pill>
              </Table.Cell>
              <Table.Cell>{formatDate(event.createdAt)}</Table.Cell>
            </Link>
          </Table.Row>
        ))}
      </Table.RowGroup>
    </Table>
  );
};
