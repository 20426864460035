const allCharactersAreValid = new RegExp('^([\u0E00-\u0E7Fa-z0-9-._]+)$');

export const isDomain = (string: string): boolean => {
  if (!allCharactersAreValid.test(string)) {
    return false;
  }

  return !!string.match(
    new RegExp(
      /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/,
    ),
  );
};
