import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  connectionSessionDeserializer,
  ConnectionSessionFragment,
} from '../../../../../graphql/deserializers/connection-session-deserializer';
import { CenteredPageContent } from '../../../../components/centered-page-content';
import { LoadingScreen } from '../../../../components/loading-screen';
import { usePortalSession } from '../../../../components/portal-session-provider';
import { EventName } from '../../../../utils/constants';
import { graphql } from '../../../../utils/graphql';
import { useSsoStore } from '../../sso-store-provider';
import { ConnectionEvent } from './connection-event';

interface ConnectionEventPageProps {}

export const ConnectionEventPage: FC<
  Readonly<ConnectionEventPageProps>
> = () => {
  const portalSession = usePortalSession();
  const { sessionId } = useParams();
  const { connection } = useSsoStore();
  const [event, setEvent] = useState<ConnectionSessionFragment | undefined>();

  useEffect(() => {
    const loadConnectionSession = async (): Promise<void> => {
      if (sessionId) {
        const connectionSessionResponse = await graphql().ConnectionSession({
          id: sessionId,
        });

        const { portal_connectionSession: rawConnectionSession } =
          connectionSessionResponse?.data;

        const connectionSession =
          connectionSessionDeserializer(rawConnectionSession);

        setEvent(connectionSession);
        void graphql().TrackEvent({
          event_name: EventName.RecentActivityViewed,
          attributes: {
            session_id: connectionSession.id,
          },
        });
      }
    };

    void loadConnectionSession();
  }, [sessionId]);

  if (!connection || !event) {
    return <LoadingScreen />;
  }

  return (
    <CenteredPageContent className="flex flex-col gap-8">
      <ConnectionEvent
        appName={portalSession.appName}
        connection={connection}
        event={event}
      />
    </CenteredPageContent>
  );
};
