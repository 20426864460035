import { useCallback, useEffect, useState } from 'react';
import {
  AuditLogExportQuery,
  Portal_CreateAuditLogExportInput,
} from '../../../../graphql/generated';
import { graphql } from '../../../../utils/graphql';

const REFRESH_INTERVAL_MS = 1000;
const MIN_NETWORK_DELAY_MS = 800;

type Error = {
  message: string;
  severe: boolean;
};

export type AuditLogLoadingState = 'idle' | 'loading' | 'error' | 'complete';

export interface AuditLogExportDetails {
  url?: string | null;
  exportId?: string;
  state: AuditLogLoadingState;
  error?: Error;
  reset: () => void;
  exportAuditLog: (input: Portal_CreateAuditLogExportInput) => void;
}

export const useAuditLogExport = (): AuditLogExportDetails => {
  const [error, setError] = useState<Error>();
  const [exportId, setExportId] = useState<string>();
  const [state, setState] = useState<AuditLogLoadingState>('idle');
  const [data, setData] = useState<AuditLogExportQuery>();

  useEffect(() => {
    if (!exportId || state !== 'loading') {
      return;
    }

    async function getAuditLogExport() {
      const { data } = await graphql().auditLogExport({ id: exportId ?? '' });
      if (data.portal_auditLogExport.url) {
        setState('complete');
        setData(data);
      }
    }

    const auditLogExportPollingInterval = setInterval(() => {
      if (state !== 'loading') {
        clearInterval(auditLogExportPollingInterval);
        return;
      }

      void getAuditLogExport();
    }, REFRESH_INTERVAL_MS);

    return () => {
      clearInterval(auditLogExportPollingInterval);
    };
  }, [exportId, state]);

  const reset = useCallback(() => {
    setState('idle');
    setExportId(undefined);
    setError(undefined);
  }, []);

  const exportAuditLog = useCallback(
    async (input: Portal_CreateAuditLogExportInput) => {
      setState('loading');

      try {
        // Add a min delay for the response for better UX, if the request happens too fast
        const fakeDelay = new Promise((resolve) =>
          setTimeout(resolve, MIN_NETWORK_DELAY_MS),
        );
        const [
          {
            data: { portal_createAuditLogExport: createAuditLogExport },
          },
        ] = await Promise.all([
          graphql().createAuditLogExport({
            input,
          }),
          fakeDelay,
        ]);
        const { __typename: typeName } = createAuditLogExport;
        if (typeName === 'portal_AuditLogExport') {
          setExportId(createAuditLogExport.id);
        } else if (
          typeName === 'portal_InvalidExportDateRange' ||
          typeName === 'portal_NoAuditLogEventsFound'
        ) {
          setState('error');
          setError({ message: createAuditLogExport.message, severe: false });
        }
      } catch (err) {
        setState('error');
        setError({ message: 'Something went wrong', severe: true });
      }
    },
    [],
  );

  return {
    url: data?.portal_auditLogExport.url,
    exportId,
    error,
    state,
    exportAuditLog,
    reset,
  };
};
