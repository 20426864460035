import * as React from 'react';

interface PageWrapperProps {
  children: React.ReactNode;
}

export const PageWrapper = ({ children }: PageWrapperProps) => (
  <div className="w-full px-8">
    <div className="mx-auto mt-8 max-w-5xl">{children}</div>
  </div>
);
