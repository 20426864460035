import {
  Card,
  FormItem,
  Label,
  Pill,
  Text,
} from '@workos-inc/component-library';
import formatDistance from 'date-fns/formatDistance';
import { useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import {
  DirectorySyncRunFragment,
  DirectorySyncRunState,
} from '../../../../../graphql/generated';
import { LoadingScreen } from '../../../../components/loading-screen';
import { ProviderLogo } from '../../../../components/provider-logo';
import { formatDate } from '../../../../utils/formatters';
import { graphql } from '../../../../utils/graphql';
import { handleError } from '../../../../utils/handle-errors';
import { useDsyncStore } from '../../dsync-store-provider';
import { getDirectoryName } from '../../utils/get-directory-name';
import { getSupportedDirectoryType } from '../../utils/get-supported-directory-type';
import { isScimDirectory } from '../../utils/is-scim-directory';

export const DirectoryOverview = () => {
  const { directoryId } = useParams();
  const navigate = useNavigate();
  const { directory, setDsyncStore } = useDsyncStore();

  const [directorySyncRuns, setDirectorySyncRuns] =
    useState<DirectorySyncRunFragment[]>();

  useEffect(() => {
    const loadOverview = async (directoryId: string) => {
      try {
        const response = await graphql().DirectoryOverview({
          id: directoryId,
          state: DirectorySyncRunState.Completed,
        });

        setDsyncStore({ directory: response.data.portal_directory });
        setDirectorySyncRuns(
          response.data.portal_directory.directorySyncRuns.data,
        );
      } catch (error) {
        handleError(error, navigate);
      }
    };

    if (directoryId) {
      void loadOverview(directoryId);
    }
  }, [directoryId, navigate, setDsyncStore]);

  const lastSync = directorySyncRuns?.[0];

  if (!directory) {
    return <LoadingScreen />;
  }

  const directoryType = getSupportedDirectoryType(directory);
  const shouldShowLastRun = !isScimDirectory(directory.type);

  if (!directoryType) {
    return <Navigate to="/unsupported-provider" />;
  }

  return (
    <Card>
      <Card.Header className="flex items-center justify-between">
        <Card.Title data-testid="directory-name">{directory.name}</Card.Title>

        {directory.type && (
          <ProviderLogo provider={directory.type} width="60px" />
        )}
      </Card.Header>

      <Card.Body className="flex flex-col gap-4">
        <FormItem isInline>
          <Label htmlFor="directoryProvider">Directory Provider</Label>

          <Text
            as="span"
            data-testid="directory-provider"
            id="directoryProvider"
          >
            {getDirectoryName(directory.type)}
          </Text>
        </FormItem>

        <FormItem isInline>
          <Label htmlFor="state">Directory Provider</Label>

          <Pill
            appearance="green"
            data-testid="directory-state"
            id="state"
            size="small"
          >
            {directory.state}
          </Pill>
        </FormItem>

        <FormItem isInline>
          <Label htmlFor="createdAt">Created At</Label>

          <Text as="span" data-testid="directory-created-at" id="createdAt">
            {formatDate(directory.createdAt)}
          </Text>
        </FormItem>

        <FormItem isInline>
          <Label htmlFor="userCount">User Count</Label>

          <Text as="span" data-testid="directory-user-count" id="userCount">
            {directory.countsSummary.userCount}
          </Text>
        </FormItem>

        <FormItem isInline>
          <Label htmlFor="groupCount">Group Count</Label>

          <Text as="span" data-testid="directory-group-count" id="groupCount">
            {directory.countsSummary.groupCount}
          </Text>
        </FormItem>

        {shouldShowLastRun && (
          <FormItem isInline>
            <Label htmlFor="lastSync">Last Sync</Label>

            <Text as="span" data-testid="directory-last-run" id="lastSync">
              {lastSync
                ? `${formatDistance(
                    new Date(lastSync.updatedAt),
                    Date.now(),
                  )} ago`
                : 'Never'}
            </Text>
          </FormItem>
        )}
      </Card.Body>
    </Card>
  );
};
