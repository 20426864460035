import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { usePortalSession } from '../../../../components/portal-session-provider';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';

const Component: FC = () => {
  const { appName } = usePortalSession();

  return (
    <StepContent confirmLabel="I’ve pushed my groups.">
      <Text>
        On the "Push Groups" tab of your Okta Application click the "Push
        Groups" button and select "Find groups by name".
      </Text>
      <Img
        className="my-8"
        height={1023}
        src="/images/d70b2946-9b17-4ff7-a1bb-c3caa438bad5.png"
        width={1596}
      />
      <Text>
        Search for the group that you wish to push, select it and click "Save"
        to push your group to {appName}.
      </Text>
      <Img
        className="my-8"
        height={1023}
        src="/images/fecaa674-f14c-4bef-9ce9-1de93ea5135a.png"
        width={1596}
      />
    </StepContent>
  );
};

export const pushGroups: StepData = {
  title: 'Push Groups',
  component: Component,
};
