import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';

const Component: FC = () => (
  <StepContent confirmLabel="I've enabled Salesforce as an IdP">
    <Text>
      In the "Identity Provider" portal, select "Enable Identity Provider" if
      you have not already done this.
    </Text>

    <Img
      className="my-8"
      height={737}
      src="/images/1651c684-6b10-4427-b03b-363ede9c0315.png"
      width={1586}
    />

    <Text>
      You will need to select the correct certificate from the previous step.
    </Text>

    <Img
      className="my-8"
      height={737}
      src="/images/b0dd15cb-5105-48e4-a3d8-3354f083257f.png"
      width={1586}
    />

    <Text>Remain in the "Identity Provider" portal for the next step.</Text>
  </StepContent>
);

export const enableSalesforceAsAnIdp: StepData = {
  title: 'Enable Salesforce as an IdP',
  component: Component,
};
