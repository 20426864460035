import { ListMetadata } from '../../graphql/generated';

export interface PaginationInfo {
  hasNext: boolean;
  hasPrevious: boolean;
  before?: string | null;
  after?: string | null;
}

export interface PaginatedData<T> {
  data: T[];
  listMetadata: ListMetadata;
}

export async function withPagination<T, TEntity>(
  paginationRequest: Promise<{ data?: T }>,
  extractData: (data: T | undefined) => PaginatedData<TEntity> | undefined,
): Promise<[{ data?: T }, PaginationInfo]> {
  const data = await paginationRequest;
  const extracted = extractData(data.data);

  const { listMetadata } = extracted ?? {
    data: undefined,
    listMetadata: undefined,
  };

  const hasNext = !!listMetadata?.before;
  const hasPrevious = !!listMetadata?.after;
  const before = listMetadata?.before;
  const after = listMetadata?.after;

  const pagination: PaginationInfo = {
    hasNext,
    hasPrevious,
    before,
    after,
  };

  return [data, pagination];
}
