import { unreachable } from '@workos-inc/standard';
import { useCallback, useState } from 'react';
import {
  DirectoryFragment,
  DirectoryType,
} from '../../../../graphql/generated';
import { usePortalSession } from '../../../components/portal-session-provider';
import { ProviderSelectionState } from '../../../components/provider-selection';
import { useDsyncStore } from '../../../features/dsync/dsync-store-provider';
import { graphql } from '../../../utils/graphql';
import { RequestState } from '../interfaces/request-state';
import { parseDirectoryCreationError } from '../utils/parse-directory-creation-error';

export const useCreateDirectory = () => {
  const { organization, untypedDirectory, domains, setDomains } =
    usePortalSession();
  const { setDsyncStore } = useDsyncStore();
  const [creationState, setCreationState] = useState<RequestState>({
    type: 'idle',
  });

  const createDirectory = useCallback(
    async (
      providerData: ProviderSelectionState<DirectoryType>,
    ): Promise<void> => {
      const { selectedProvider, directoryDomain } = providerData;
      if (!selectedProvider) {
        return;
      }
      setCreationState({ type: 'waiting' });

      const domain = directoryDomain || domains[0];
      let directory: DirectoryFragment | undefined;

      try {
        if (untypedDirectory) {
          const { data } = await graphql().CreateDirectoryFromUntyped({
            input: {
              directoryId: untypedDirectory.id,
              type: selectedProvider,
            },
          });
          const result = data?.portal_createDirectoryFromUntyped;
          switch (result?.__typename) {
            case 'Portal_DirectoryCreated': {
              directory = result.directory;
              break;
            }
            case 'DirectoryNotFound': {
              throw new Error(`Directory '${result.directoryId}' not found.`);
            }
            case 'DirectoryIsAlreadyTyped': {
              throw new Error(
                `Directory '${result.directoryId}' already has a type.`,
              );
            }
            case undefined: {
              throw new Error('No GraphQL response.');
            }
            default:
              return unreachable(result);
          }
        } else {
          const { data } = await graphql().CreateDirectory({
            name: organization?.name || '',
            type: selectedProvider,
            domain,
          });

          directory = data?.portal_createDirectory;

          setDsyncStore({ directory });
          setCreationState({ type: 'success' });
          if (directoryDomain) {
            setDomains([...new Set([...domains, directoryDomain])]);
          }
        }
      } catch (error) {
        const { message, errorCode } = parseDirectoryCreationError(
          error,
          domain,
        );

        setCreationState({
          type: 'failed',
          value: {
            error,
            message,
            errorCode,
          },
        });
      }
    },
    [domains, setDomains, organization?.name, setDsyncStore, untypedDirectory],
  );

  return [creationState, createDirectory] as const;
};
