import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Topnav } from '../../../components/topnav';
import { FormDataProvider } from '../../../shared/form-data-provider/form-data-provider';
import { DsyncExitWarningCheck } from '../dsync-exit-warning-check/dsync-exit-warning-check';
import { DsyncStoreProvider } from '../dsync-store-provider';
import {
  ConfiguredDirectoryPage,
  DirectorySyncProviderSelectionPage,
} from '../pages';
import { DsyncSteps } from '../steps';
import { DsyncRedirector } from './dsync-redirector';

interface DsyncRouterProps {}
export const DsyncRouter: FC<Readonly<DsyncRouterProps>> = () => (
  <FormDataProvider>
    <DsyncStoreProvider>
      <Topnav />
      <DsyncExitWarningCheck />

      <Routes>
        <Route element={<DsyncRedirector />} path="/">
          <Route element={<DirectorySyncProviderSelectionPage />} path="/new" />
          <Route element={<ConfiguredDirectoryPage />} path="/:directoryId" />
          <Route element={<DsyncSteps />} path="/configure/*" />

          {/* Legacy redirection from old Admin Portal URLs */}
          <Route
            element={<Navigate to="/:directoryId" />}
            path="/directory/:directoryId"
          />
        </Route>
      </Routes>
    </DsyncStoreProvider>
  </FormDataProvider>
);
