import { Button, FakeProgressBar, Text } from '@workos-inc/component-library';
import { AnimatePresence, motion } from 'framer-motion';
import { FC, useMemo } from 'react';
import { File } from 'react-feather';
import { AuditLogExportDetails } from '../../hooks/use-audit-log-export';

interface LoadingStateProps
  extends Pick<AuditLogExportDetails, 'state' | 'url'> {
  onCancel: () => void;
}

export const LoadingState: FC<LoadingStateProps> = ({
  state,
  url,
  onCancel,
}) => {
  const title = useMemo(
    () =>
      url ? 'CSV export is ready for download' : 'Preparing CSV for download',
    [url],
  );

  return (
    <>
      <AnimatePresence exitBeforeEnter initial={false}>
        <motion.div
          key={title}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 10 }}
          initial={{ opacity: 0, y: -5 }}
          transition={{ duration: 0.25, ease: 'easeOut' }}
        >
          <Text as="h1" size="large" weight="medium">
            {title}
          </Text>
        </motion.div>
      </AnimatePresence>

      <div className="flex flex-col gap-y-1">
        <div className="flex items-center gap-x-2">
          <File className="opacity-75" size={14} />
          <Text as="span" size="medium">
            audit_logs_export.csv
          </Text>
        </div>

        <FakeProgressBar state={state} />
      </div>

      <div className="flex gap-x-2">
        <Button asChild disabled={!url} onClick={() => null}>
          <a download href={url ?? '#'} rel="noreferrer" target="_blank">
            Download CSV file
          </a>
        </Button>
        <Button appearance="secondary" onClick={() => onCancel()}>
          {url ? 'Close' : 'Cancel'}
        </Button>
      </div>
    </>
  );
};
