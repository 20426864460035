import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { CopyInput } from '../../../../components/inputs';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';
import { useSsoStore } from '../../sso-store-provider';

const Component: FC = () => {
  const { connection } = useSsoStore();

  return (
    <StepContent confirmLabel="I’ve completed the Customer ID Configuration.">
      <Text>Click the “Configuration” tab from the left sidebar.</Text>

      <CopyInput
        id="saml-acs-url"
        label="Copy the unique ID from this ACS URL"
        value={connection.saml_acs_url}
      />

      <Text>
        Submit the “Customer ID” in the Application details and click "Save".
      </Text>

      <Text>
        Paste only the customer ID which is the customer specific string at the
        end of the ACS URL.
      </Text>

      <Img
        className="my-8"
        height={910}
        src="/images/3d96cce5-b546-49bc-9ae7-1da21faa3977.png"
        width={2250}
      />
    </StepContent>
  );
};

export const configureCustomerIdInOneLogin: StepData = {
  title: 'Configure Customer ID in OneLogin',
  component: Component,
};
