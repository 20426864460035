import { InlineCode, Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';

const Component: FC = () => (
  <StepContent confirmLabel="I've configured the attribute statements.">
    <Text>
      Now, on this same page, Configure the attribute statements. The following
      attributes are required: <InlineCode>email</InlineCode>,{' '}
      <InlineCode>firstName</InlineCode>, <InlineCode>lastName</InlineCode>, and{' '}
      <InlineCode>id</InlineCode>. Cloudflare automatically sends{' '}
      <InlineCode>id</InlineCode> and <InlineCode>email</InlineCode>, so you
      just need to add <InlineCode>firstName</InlineCode> and{' '}
      <InlineCode>lastName</InlineCode>. These attributes were configured in
      Step 1, and the mapped values are the same here.
    </Text>

    <Text>
      Add <InlineCode>firstName</InlineCode> and{' '}
      <InlineCode>lastName</InlineCode> to both the right and left sides of the
      SAML attribute statements.
    </Text>

    <Img
      className="my-8"
      height={2350}
      src="/images/fd7169cf-5b6f-460d-a1ea-4146070c770a.png"
      width={2952}
    />

    <Text>
      Select the Identity Provider that you are using from the list. In this
      example we are using an Okta SAML connection.
    </Text>

    <Img
      className="my-8"
      height={1766}
      src="/images/ca3cdc2f-2680-40fe-af58-0c2933737340.png"
      width={2952}
    />

    <Text>
      Configure at least one policy and one rule, then click next. For this
      example the Policy sets the session length to 30 minutes for everyone.
    </Text>

    <Img
      className="my-8"
      height={2212}
      src="/images/5ae066e9-1baa-4fbc-9d3f-79c9ccdbeddd.png"
      width={2952}
    />
  </StepContent>
);

export const configureAttributeStatements: StepData = {
  title: 'Configure Attribute Statements',
  component: Component,
};
