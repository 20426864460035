import { FC, useContext, useEffect, useState } from 'react';
import * as React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useFeatureFlagContext } from '../../../components/feature-flag-provider/feature-flag-provider';
import {
  PortalSessionFragment,
  UntypedConnectionFragment,
  UntypedDirectoryFragment,
} from '../../../graphql/generated';
import { setBugsnagSession } from '../../utils/bugsnag';
import { graphql } from '../../utils/graphql';
import { handleError } from '../../utils/handle-errors';
import { validateToken } from '../../utils/token';
import { withTelemetry } from '../../utils/with-telemetry';
import { LoadingScreen } from '../loading-screen';

export interface PortalSessionInitiatorProps {
  children?: React.ReactNode;
  initialSession?: PortalSessionFragment;
}

interface PortalSessionStore {
  pageTitle: string;
  setPageTitle: React.Dispatch<React.SetStateAction<string>>;
  appName: string;
  faviconUrl?: string | null;
  logoUrl?: string | null;
  primaryColor?: string;
  returnUrl: string;
  successUrl?: string | null;
  domains: string[];
  setDomains: React.Dispatch<React.SetStateAction<string[]>>;
  setupLink?: PortalSessionFragment['setupLink'];
  organization?: PortalSessionFragment['organization'];
  untypedDirectory?: UntypedDirectoryFragment;
  untypedConnection?: UntypedConnectionFragment;
  showLoseProgressOnExitWarning: boolean;
  setShowLoseProgressOnExitWarning: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}

type IdleSessionState = {
  type: 'idle';
};

type LoadingSessionState = {
  type: 'loading';
};

type SuccessSessionState = {
  type: 'success';
  value: PortalSessionFragment;
};

type ErrorSessionState = {
  type: 'error';
  value: unknown;
};

type SessionState =
  | IdleSessionState
  | LoadingSessionState
  | SuccessSessionState
  | ErrorSessionState;

export const PortalSessionContext = React.createContext<
  PortalSessionStore | undefined
>(undefined);

export const usePortalSession = () => {
  const session = useContext(PortalSessionContext);
  if (!session) {
    throw new Error(
      'Session value should not be undefined. Please make sure that that this hook was called as a child of PortalSessionProvider',
    );
  }
  return session;
};

export const PortalSessionProvider: FC<
  Readonly<PortalSessionInitiatorProps>
> = ({ children, initialSession }) => {
  const navigate = useNavigate();
  const state = useSessionLoader(initialSession);
  const [pageTitle, setPageTitle] = useState('');
  const [showLoseProgressOnExitWarning, setShowLoseProgressOnExitWarning] =
    useState(false);

  // Sync the domains whenever the portal session refreshes:
  const [domains, setDomains] = useState<string[]>([]);
  useEffect(() => {
    if (state.type === 'success') {
      const orgDomains = state.value.organization?.domains || [];
      setDomains(orgDomains.map((domain) => domain.domain));
    }
  }, [state]);

  useEffect(() => {
    if (state.type === 'error') {
      handleError(state.value, navigate);
    }
  }, [state, navigate]);

  switch (state.type) {
    case 'idle':
    case 'loading':
      return <LoadingScreen />;
    case 'success':
      return (
        <PortalSessionContext.Provider
          value={consumePortalSession(
            state.value,
            pageTitle,
            setPageTitle,
            domains,
            setDomains,
            showLoseProgressOnExitWarning,
            setShowLoseProgressOnExitWarning,
          )}
        >
          {children}
        </PortalSessionContext.Provider>
      );
    case 'error':
      return null;
  }
};

const consumePortalSession = (
  portalSession: PortalSessionFragment | undefined,
  pageTitle: PortalSessionStore['pageTitle'],
  setPageTitle: PortalSessionStore['setPageTitle'],
  domains: string[],
  setDomains: React.Dispatch<React.SetStateAction<string[]>>,
  showLoseProgressOnExitWarning: PortalSessionStore['showLoseProgressOnExitWarning'],
  setShowLoseProgressOnExitWarning: PortalSessionStore['setShowLoseProgressOnExitWarning'],
): PortalSessionStore | undefined => {
  if (!portalSession) {
    return;
  }

  return {
    pageTitle,
    setPageTitle,
    appName: portalSession.appName,
    faviconUrl: portalSession.portalSettings.faviconUrl,
    logoUrl: portalSession.portalSettings.logoUrl,
    primaryColor: portalSession.portalSettings.primaryColor || undefined,
    returnUrl: portalSession.returnUrl,
    successUrl: portalSession.successUrl,
    setupLink: portalSession.setupLink,
    organization: portalSession.organization,
    showLoseProgressOnExitWarning,
    setShowLoseProgressOnExitWarning,
    domains,
    setDomains,
    untypedConnection:
      portalSession.connection?.__typename === 'portal_UntypedConnection'
        ? portalSession.connection
        : undefined,
    untypedDirectory:
      portalSession.directory?.__typename === 'portal_UntypedDirectory'
        ? portalSession.directory
        : undefined,
  };
};

const useSessionLoader = (initialSession?: PortalSessionFragment) => {
  const [searchParams] = useSearchParams();
  const startedAt = searchParams.get('started_at');
  const token = searchParams.get('token');
  const { setAppName } = useFeatureFlagContext();
  const [state, setState] = useState<SessionState>(
    initialSession
      ? { type: 'success', value: initialSession }
      : { type: 'idle' },
  );

  useEffect(() => {
    if (state.type === 'idle') {
      const load = async (): Promise<void> => {
        setState({ type: 'loading' });
        try {
          if (token) {
            await validateToken(token);
          }
          const response = await withTelemetry({ startedAt, token }, () =>
            graphql().InitialPortal(),
          );
          if (response?.data) {
            const { portal_portalSession: session } = response.data;
            setBugsnagSession(session);
            setAppName(session.appName);
            setState({ type: 'success', value: session });
          } else {
            throw new Error('Session not defined.');
          }
        } catch (error) {
          setState({ type: 'error', value: error });
        }
      };

      void load();
    }
  }, [setAppName, setState, startedAt, state, token]);

  return state;
};
