import { FC } from 'react';
import { StepData } from '../../../../shared/step-navigation';
import { useSsoStepRoutes } from '../../hooks/use-sso-step-routes';
import { MetadataUrlFormFields } from '../../interfaces/metadata-form-fields';
import { SsoStepNavigation } from '../../sso-step-navigation';
import { generateSsoStepData } from '../../utils/generate-sso-step-data';
import { createSamlApplication } from './1-create-saml-application';
import { uploadIdentityProviderMetadata } from './2-upload-identity-provider-metadata';

interface Auth0SamlStepsProps {}

const stepsData: StepData<MetadataUrlFormFields>[] = generateSsoStepData([
  createSamlApplication,
  uploadIdentityProviderMetadata,
]);

export const Auth0SamlSteps: FC<Readonly<Auth0SamlStepsProps>> = () => {
  const stepRoutes = useSsoStepRoutes(stepsData);

  return (
    <SsoStepNavigation stepsData={stepsData}>{stepRoutes}</SsoStepNavigation>
  );
};
