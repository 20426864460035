import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';
import { Text } from '@workos-inc/component-library';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { FC } from 'react';
import { ConnectionType, DirectoryType } from '../../../graphql/generated';
import { ProviderLogo } from '../provider-logo';
import { CheckItem } from './check-item';

export const ProviderCard: FC<ProviderCardProps> = ({
  value,
  label,
  className,
  ...props
}) => (
  <RadioGroupPrimitive.Item
    asChild
    {...props}
    className={classNames(
      'appearance-none border-gray-lightmode-200 rounded w-[120px] h-[60px] transition cursor-pointer',
      'flex flex-col items-center justify-center relative bg-white',
      'radix-state-checked:shadow-md hover:shadow-md radix-state-unchecked:border radix-state-checked:bg-gray-50 focus:outline-none',
      className,
    )}
    value={value}
  >
    <motion.li variants={itemVariants}>
      <ProviderLogo
        height={label ? '14px' : '20px'}
        provider={value}
        width="100px"
      />
      {label && (
        <Text as="p" size="small">
          {label}
        </Text>
      )}
      <RadioGroupPrimitive.Indicator className="absolute h-full w-full rounded  border border-primary shadow-md outline-0">
        <CheckItem />
      </RadioGroupPrimitive.Indicator>
    </motion.li>
  </RadioGroupPrimitive.Item>
);
type ProviderCardProps = RadioGroupPrimitive.RadioGroupItemProps & {
  value: ConnectionType | DirectoryType;
  label?: string;
};

const itemVariants = {
  hidden: { opacity: 0, scale: 0.1 },
  show: { opacity: 1, scale: 1 },
};
