import { InputGroup, Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Card } from '../../../../components/card';
import { Form } from '../../../../components/form';
import { usePortalSession } from '../../../../components/portal-session-provider';
import { useFormContext } from '../../../../shared/form-data-provider';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { useStepsStore } from '../../../../shared/step-navigation/steps-store-provider';
import { SsoFormMetadata } from '../../interfaces/sso-form-metadata';
import { GenericOidcFormFields } from './generic-oidc-steps';

const Component: FC = () => {
  const { appName } = usePortalSession();
  const { isUpdating, handleNextStep, handleInputChange } =
    useStepsStore<SsoFormMetadata>();
  const { formData } = useFormContext<SsoFormMetadata>();

  return (
    <>
      <Text>
        After creating an application, a Client ID and Client Secret would've
        been provisioned for you by your Identity Provider. These credentials
        are used to authenticate users from {appName}.
      </Text>

      <Card>
        <Form
          disabled={!formData.oidc_client_id || !formData.oidc_client_secret}
          isLoading={isUpdating}
          // isUpdate={!!(formErrors?.oidc_client_id || formErrors?.oidc_client_secret)}
          onSubmit={handleNextStep}
        >
          <InputGroup
            autoFocus
            // error={formErrors?.oidc_client_id?.message}
            id="oidc_client_id"
            label="Client ID"
            name="oidc_client_id"
            onChange={handleInputChange}
            value={formData.oidc_client_id ?? ''}
          />

          <InputGroup
            // error={formErrors?.oidc_client_secret?.message}
            id="oidc_client_secret"
            label="Client Secret"
            name="oidc_client_secret"
            onChange={handleInputChange}
            value={formData.oidc_client_secret ?? ''}
          />
        </Form>
      </Card>
    </>
  );
};

export const provideYourClientCredentials: StepData<GenericOidcFormFields> = {
  title: 'Provide your Client Credentials',
  component: Component,
  formKeys: ['oidc_client_id', 'oidc_client_secret'],
};
