import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { usePortalSession } from '../../../../components/portal-session-provider';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';

const Component: FC = () => {
  const { appName } = usePortalSession();

  return (
    <StepContent confirmLabel="I’ve created a SAML Enterprise Application">
      <Text>
        Select "Enterprise applications" from your Azure AD dashboard.
      </Text>

      <Img
        className="my-8"
        height={1218}
        src="/images/37f3b3d2-fcdb-4a5f-8d93-970ec0239446.png"
        width={2106}
      />

      <Text>Click "New application" and continue.</Text>

      <Img
        className="my-8"
        height={1196}
        src="/images/3c26fec7-f77a-4757-8a04-6998ff7beb26.png"
        width={2066}
      />

      <Text>
        Select "Create your own application", then enter an App name that
        describes {appName}. Under "What are you looking to do with your
        application?", select "Integrate any other application you don't find in
        the gallery (Non-gallery)", then select "Create".
      </Text>

      <Img
        className="my-8"
        height={1196}
        src="/images/9f998b1b-87b4-4f1d-a50c-0a17f8bb3f98.png"
        width={2066}
      />

      <Text>
        Next, select "Single Sign On" from the "Manage" section in the left
        sidebar navigation menu, and then "SAML".
      </Text>

      <Img
        className="my-8"
        height={1546}
        src="/images/f90c0d1a-ed45-4bee-b77f-dcf37a63a6bd.png"
        width={2072}
      />
    </StepContent>
  );
};

export const createEnterpriseApplication: StepData = {
  title: 'Create Enterprise Application',
  component: Component,
};
