import { Modal } from '@workos-inc/component-library';
import { AnimatePresence, motion } from 'framer-motion';
import { ComponentProps, FC, useCallback, useEffect, useMemo } from 'react';
import { useAuditLogExport } from '../../hooks/use-audit-log-export';
import { ErrorState } from './error-state';
import { IdleState } from './idle-state';
import { LoadingState } from './loading-state';

interface AuditLogsExportCsvModalProps
  extends Pick<ComponentProps<typeof Modal>, 'open' | 'onOpenChange'> {
  filters: [string, string][];
}

export const AuditLogsExportCsvModal: FC<AuditLogsExportCsvModalProps> = ({
  open,
  onOpenChange,
  filters,
}) => {
  const { exportAuditLog, state, reset, error, exportId, url } =
    useAuditLogExport();

  const childrenKey = useMemo(() => {
    if (error) {
      return 'error';
    }
    return exportId ? 'loading' : 'idle';
  }, [error, exportId]);

  const handleOpenChange = useCallback(
    (open: boolean) => {
      if (!open && state === 'loading') {
        const leave = window.confirm(
          'CSV export in progress. Are you sure you want to leave?',
        );
        if (leave) {
          onOpenChange(open);
        }
      } else {
        onOpenChange(open);
      }
    },
    [onOpenChange, state],
  );

  useEffect(() => {
    if (state === 'loading') {
      const onUnload = (event: BeforeUnloadEvent) => {
        event.preventDefault();
      };

      window.addEventListener('beforeunload', onUnload);
      return () => {
        window.removeEventListener('beforeunload', onUnload);
      };
    }

    return undefined;
  }, [state]);

  useEffect(() => {
    // Reset the state once the modal closes
    if (!open) {
      reset();
    }
  }, [open, reset]);

  return (
    <Modal
      className="max-w-md"
      onOpenChange={handleOpenChange}
      open={open}
      title="Export to CSV"
    >
      <div className="-m-5 overflow-hidden p-2">
        <AnimatePresence initial={false}>
          <motion.div
            key={childrenKey}
            animate={{ opacity: 1, height: 'auto' }}
            className="flex flex-col gap-y-5"
            exit={{ opacity: 0, height: 0 }}
            initial={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.15, ease: 'easeOut' }}
          >
            {childrenKey === 'error' && (
              <ErrorState error={error} onCancel={() => reset()} />
            )}

            {childrenKey === 'loading' && (
              <LoadingState
                onCancel={() => handleOpenChange(false)}
                state={state}
                url={url}
              />
            )}

            {childrenKey === 'idle' && (
              <IdleState
                filters={filters}
                onCancel={() => handleOpenChange(false)}
                onGenerate={(input) => {
                  exportAuditLog(input);
                }}
                state={state}
              />
            )}
          </motion.div>
        </AnimatePresence>
      </div>
    </Modal>
  );
};
