import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { CopyInput } from '../../../../components/inputs';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';
import { useSsoStore } from '../../sso-store-provider';

const Component: FC = () => {
  const { connection } = useSsoStore();

  return (
    <StepContent confirmLabel="I’ve completed my Basic SAML Configuration.">
      <Text>Click the Edit icon in the top right of the first step.</Text>

      <Img
        className="my-8"
        height={1546}
        src="/images/e7a4397c-8120-43c6-9b66-e6a2251cd5bd.png"
        width={2208}
      />

      <CopyInput
        id="saml-entity-id"
        label="Copy this Identifier"
        value={connection.saml_entity_id}
      />

      <CopyInput
        id="saml-acs-url"
        label="Copy this Reply URL"
        value={connection.saml_acs_url}
      />

      <Text>
        Submit the Identifier and the Reply URL in the Basic SAML Configuration.
      </Text>

      <Img
        className="my-8"
        height={1268}
        src="/images/43e8aed9-fdfa-421a-8db7-94a051bcbfa4.png"
        width={2150}
      />
    </StepContent>
  );
};

export const basicSamlConfiguration: StepData = {
  title: 'Basic SAML Configuration',
  component: Component,
};
