import { VerifyConnectionFailed } from '../../../../../../graphql/generated';
import { ErrorOrigin } from '../../../../../interfaces/errors';
import { SsoFormErrors } from '../../../interfaces/sso-form-errors';
import { SsoFormMetadata } from '../../../interfaces/sso-form-metadata';

export const getConnectionFailedFormErrors = (
  formData: SsoFormMetadata,
  connectionErrors: VerifyConnectionFailed['connectionErrors'],
) =>
  connectionErrors.reduce((acc, curr) => {
    const formFieldKey = curr.field as keyof SsoFormMetadata;
    const formFieldVal =
      formFieldKey === 'saml_x509_certificates'
        ? formData[formFieldKey]?.[0]
        : formData[formFieldKey];

    acc[formFieldKey] = {
      errorOrigin: ErrorOrigin.Server,
      message: curr.error,
      value: formFieldVal || '',
    };
    return acc;
  }, {} as SsoFormErrors);
