import { Button, Dialog, InputGroup } from '@workos-inc/component-library';
import { FC, FormEvent, useState } from 'react';
import { AlertCircle } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { usePortalSession } from '../../../../components/portal-session-provider';
import { graphql } from '../../../../utils/graphql';
import { useSsoStore } from '../../sso-store-provider';

export interface DeleteConnectionProps {}

export const DeleteConnection: FC<Readonly<DeleteConnectionProps>> = () => {
  const ssoStore = useSsoStore();
  const session = usePortalSession();

  const navigate = useNavigate();

  const [displayDeletionModal, setDisplayDeletionModal] = useState(false);
  const [resetText, setResetText] = useState('');

  const handleDeleteConnection = async (event: FormEvent) => {
    event.preventDefault();

    await graphql().DeleteConnection({ id: ssoStore.connection.id });

    ssoStore.setSsoStore({ connection: undefined });
    void navigate('/sso', { replace: true });
  };

  return (
    <>
      <div className="w-full border-t border-t-gray-300 py-4">
        <Button
          appearance="red"
          data-testid="open-reset-connection-modal-button"
          iconLeft={<AlertCircle size={16} />}
          onClick={() => setDisplayDeletionModal(true)}
        >
          Reset Connection
        </Button>
      </div>

      <Dialog
        isDangerous
        acceptButtonProps={{
          'data-testid': 'confirm-reset-connection-button',
          'type': 'submit',
          'form': 'deleteConnectionForm',
        }}
        acceptText="Reset Connection"
        description={`This action will reset any information and configuration associated
            with your connection. Your users will no longer be able to sign in
            to ${session.appName} until the connection is setup again.`}
        isAcceptDisabled={resetText !== 'Reset'}
        onOpenChange={(open) => setDisplayDeletionModal(open)}
        open={displayDeletionModal}
        title="Reset Connection"
      >
        <form
          id="deleteConnectionForm"
          onSubmit={(event) => handleDeleteConnection(event)}
        >
          <InputGroup
            id="reset-connection-id"
            label="Type 'Reset' to reset the connection"
            name="reset"
            onChange={(event) => setResetText(event.target.value)}
            placeholder="Reset"
            value={resetText}
          />
        </form>
      </Dialog>
    </>
  );
};
