import {
  ConnectionFragment,
  UpdateConnectionMutation,
} from '../../../../../../graphql/generated';
import { graphql } from '../../../../../utils/graphql';
import { SsoFormMetadata } from '../../../interfaces/sso-form-metadata';

export const completeConnectionManually = async (
  formData: SsoFormMetadata,
  connection: ConnectionFragment,
): Promise<UpdateConnectionMutation['portal_updateConnection']> => {
  const response = await graphql().UpdateConnection({
    input: {
      connectionId: connection.id,
      name: connection.name,
      oidc: {
        clientId: formData.oidc_client_id || connection.oidc_client_id,
        clientSecret:
          formData.oidc_client_secret || connection.oidc_client_secret,
        discoveryEndpoint:
          formData.oidc_discovery_endpoint ||
          connection.oidc_discovery_endpoint,
      },
      saml: {
        idpEntityId:
          formData.saml_idp_entity_id || connection.saml?.idpEntityId,
        idpUrl: formData.saml_idp_url || connection.saml_idp_url,
        x509Certs: formData.saml_x509_certificates,
      },
    },
  });
  return response.data?.portal_updateConnection;
};
