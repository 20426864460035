import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';

const Component: FC = () => (
  <StepContent confirmLabel="I’ve enabled the actions listed above.">
    <Text>
      In the "To App" navigation section, click "Edit" and then check to enable
      the following actions:
    </Text>

    <ul className="my-4 list-inside list-disc space-y-3 text-gray-lightmode-300">
      <li>Create Users</li>
      <li>Update User Attributes</li>
      <li>Deactivate Users</li>
    </ul>

    <Img
      className="my-8"
      height={1023}
      src="/images/aa9728c3-d1e7-453c-82ff-989f692ed27f.png"
      width={1596}
    />
  </StepContent>
);

export const configureProvisioningActions: StepData = {
  title: 'Configure Provisioning Actions',
  component: Component,
};
