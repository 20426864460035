import { ConnectionType } from '../../../../graphql/generated';
import { useFeature } from '../../../../utils/feature-flags';
import { SupportedConnectionType } from '../interfaces/supported-connection-type';

/**
 * A hooks that filters identity providers based on their feature flag
 */
export const useEnabledConnections = (
  providers: readonly SupportedConnectionType[],
) => {
  const oktaAdminPortalEnabledForDrata = useFeature(
    'oktaAdminPortalEnabledForDrata',
  );

  const isAdpOidcEnabled = useFeature('adpOidcAdminPortal');
  const isRipplingSsoEnabled = useFeature('ripplingSsoAdminPortal');

  const enabledConnectionsMap: {
    [k in SupportedConnectionType]?: boolean;
  } = {
    [ConnectionType.OktaSaml]: oktaAdminPortalEnabledForDrata,
    [ConnectionType.RipplingSaml]: isRipplingSsoEnabled,
    [ConnectionType.AdpOidc]: isAdpOidcEnabled,
  };

  return providers.filter((provider) => {
    if (provider in enabledConnectionsMap) {
      return enabledConnectionsMap[provider];
    }

    return true;
  });
};
