import adapter from '@flopflip/launchdarkly-adapter';
import {
  ConfigureFlopFlip,
  useAdapterReconfiguration,
} from '@flopflip/react-broadcast';
import {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import * as React from 'react';
import { Page } from '../../layouts/page';
import { defaultFeatureFlags } from '../../utils/feature-flags';
import { Spinner } from '../spinner';

interface ContextProps {
  setAppName: (appName: string) => void;
}

export const FeatureFlagContext = createContext<ContextProps | null>(null);

export interface FeatureFlagProviderProps {
  children?: React.ReactNode;
  mockedFlags?: boolean;
}

export const FeatureFlagProvider: FC<Readonly<FeatureFlagProviderProps>> = ({
  children,
  mockedFlags = false,
}) => {
  const reconfigure = useAdapterReconfiguration();

  const [appName, setAppName] = useState<string>('');

  const launchDarklyUser = useMemo(
    () => ({
      key: appName,
    }),
    [appName],
  );

  useEffect(() => {
    if (appName) {
      reconfigure({ user: launchDarklyUser }, { shouldOverwrite: true });
    }
  }, [launchDarklyUser, reconfigure, appName]);

  const adapterArgs = {
    sdk: { clientSideId: process.env.NEXT_PUBLIC_LAUNCH_DARKLY_CLIENT_SIDE_ID },
    user: launchDarklyUser,
  };

  return (
    <FeatureFlagContext.Provider
      value={{
        setAppName,
      }}
    >
      {mockedFlags ? (
        children
      ) : (
        <ConfigureFlopFlip
          adapter={adapter}
          adapterArgs={adapterArgs}
          defaultFlags={defaultFeatureFlags}
        >
          {({ isAdapterConfigured }) =>
            isAdapterConfigured ? (
              children
            ) : (
              <Page>
                <div className="h-screen">
                  <Spinner isCube />
                </div>
              </Page>
            )
          }
        </ConfigureFlopFlip>
      )}
    </FeatureFlagContext.Provider>
  );
};

export const useFeatureFlagContext = (): ContextProps => {
  const featureFlagContext = useContext(FeatureFlagContext);
  if (!featureFlagContext) {
    throw new TypeError(
      '`useFeatureFlagContext` must be called from within an `FeatureFlagProvider`',
    );
  }

  return featureFlagContext;
};
