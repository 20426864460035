import { ErrorOrigin } from '../../../interfaces/errors';
import { FormDataErrors } from '../../../shared/form-data-provider';
import { isURL } from '../../../utils/is-url';
import { SsoFormMetadata } from '../interfaces/sso-form-metadata';

export const getSsoClientSideFormErrors = <TFormData extends SsoFormMetadata>(
  formData: TFormData,
) => {
  let hasErrors = false;
  const clientSideFormErrors: Partial<FormDataErrors<TFormData>> = {};
  const { oidc_discovery_endpoint, saml_idp_url } = formData;
  if (oidc_discovery_endpoint && !isURL(oidc_discovery_endpoint)) {
    hasErrors = true;
    clientSideFormErrors.oidc_discovery_endpoint = {
      errorOrigin: ErrorOrigin.Client,
      message: 'You must enter a valid url',
      value: oidc_discovery_endpoint,
    };
  }

  if (saml_idp_url && !isURL(saml_idp_url)) {
    hasErrors = true;
    clientSideFormErrors.saml_idp_url = {
      errorOrigin: ErrorOrigin.Client,
      message: 'You must enter a valid url',
      value: saml_idp_url,
    };
  }
  if (!hasErrors) {
    return;
  }

  return clientSideFormErrors;
};
