import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { CopyInput } from '../../../../components/inputs';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';
import { useSsoStore } from '../../sso-store-provider';

const Component: FC = () => {
  const { connection } = useSsoStore();
  return (
    <StepContent confirmLabel="I've configured the ACS URL and Entity ID in my custom SAML app.">
      <CopyInput
        id="saml-acs-url"
        label="Copy this ACS URL"
        value={connection.saml_acs_url}
      />

      <CopyInput
        id="saml-entity-id"
        label="Copy this Entity ID"
        value={connection.saml_entity_id}
      />

      <Text>Submit the "ACS URL" and the "Entity ID". Click "Continue"</Text>

      <Img
        className="my-8"
        height={1009}
        src="/images/d70e5055-34de-4d31-a7d3-a0ac8cfa4395.png"
        width={1474}
      />
    </StepContent>
  );
};

export const enterServiceProviderDetails: StepData = {
  title: 'Enter Service Provider Details',
  component: Component,
};
