import { FC, useEffect, useState } from 'react';
import { Outlet, useLocation, useMatch, useNavigate } from 'react-router-dom';
import { ConnectionState } from '../../../../graphql/generated';
import { useSsoStore } from '../sso-store-provider';

export const SsoRedirector: FC = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const onConfigurePage = useMatch('/sso/configure/*');
  const onRootSsoPage = useMatch('/sso');
  const onNewPage = useMatch('/sso/new');
  const onSessionPage = useMatch('/sso/session/*');

  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const { connection } = useSsoStore();

  useEffect(() => {
    if (!isInitialLoad && !onRootSsoPage) {
      return;
    }

    if (connection?.state) {
      if (connection?.state === ConnectionState.Active) {
        if (!onSessionPage) {
          navigate(
            {
              pathname: `/sso/${connection.id}`,
              search,
            },
            { replace: true },
          );
        }
      } else if (!onConfigurePage) {
        navigate('/sso/configure/1', { replace: true });
      }
    } else if (!onNewPage) {
      navigate('/sso/new', { replace: true });
    }

    if (isInitialLoad) {
      setIsInitialLoad(false);
    }
  }, [
    connection?.id,
    connection?.state,
    isInitialLoad,
    navigate,
    onConfigurePage,
    onNewPage,
    onRootSsoPage,
    onSessionPage,
    search,
  ]);

  return <Outlet />;
};
