import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';
import { ConnectionAttributeMapping } from '../../attribute-mapping';
import { useSsoStore } from '../../sso-store-provider';

const Component: FC = () => {
  const { connection } = useSsoStore();

  return (
    <StepContent
      confirmLabel="I’ve finished configuring the Attribute Statements
    and clicked “Save & assign users“."
    >
      <Text>
        Map the following four attributes as shown below, and select "Save {'&'}{' '}
        assign users".
      </Text>

      <ConnectionAttributeMapping connectionType={connection.type} />

      <Img
        height={2042}
        src="/images/3e75c892-a7cc-4167-9e45-c046232c1231.png"
        width={2740}
      />
    </StepContent>
  );
};

export const configureAttributeStatements: StepData = {
  title: 'Configure Attribute Statements',
  component: Component,
};
