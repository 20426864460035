import {
  Button,
  Card,
  FormItem,
  Label,
  SamlX509CertificatePill,
  Text,
} from '@workos-inc/component-library';
import { FC, useState } from 'react';
import { configurationTypeForConnectionType } from '../../../../../../utils/connection-configuration-type';
import { useFeature } from '../../../../../../utils/feature-flags';
import { useSsoStore } from '../../../sso-store-provider';
import { ConnectionMetadataDialog } from './connection-metadata-dialog';

interface ConnectionMetadataProps {}

export const ConnectionMetadata: FC<ConnectionMetadataProps> = () => {
  const { connection } = useSsoStore();
  const [isEditMetadataModalOpen, setEditMetadataModalOpen] = useState(false);
  const isOktaOinEnabled = useFeature('oktaOinAdminPortalSso');
  const isOneLoginOacEnabled = useFeature('oneLoginOacAdminPortalSso');

  const configurationType = configurationTypeForConnectionType(
    connection.type,
    {
      isOktaOinEnabled,
      isOneLoginOacEnabled,
    },
  );

  if (configurationType.tag !== 'Saml') {
    return null;
  }

  return (
    <Card>
      <Card.Header>
        <Card.Title>Metadata Configuration</Card.Title>
      </Card.Header>
      <Card.Body className="flex flex-col gap-4">
        <FormItem isInline>
          <Label htmlFor="entityId">IdP URI (Entity ID)</Label>
          <Text as="span" id="entityId">
            {connection.saml.idpEntityId}
          </Text>
        </FormItem>

        <FormItem isInline>
          <Label htmlFor="idpSsoUrl">IdP SSO URL</Label>
          <Text as="span" id="idpSsoUrl">
            {connection.saml_idp_url}
          </Text>
        </FormItem>

        <FormItem isInline>
          <Label htmlFor="x509Certificate">X.509 Certificates</Label>
          {connection.samlX509Certificates.map((samlX509Certificate) => (
            <SamlX509CertificatePill
              key={samlX509Certificate.id}
              {...samlX509Certificate}
            />
          ))}
        </FormItem>

        <Button
          appearance="secondary"
          className="self-start"
          data-testid="edit-metadata-configuration-button"
          onClick={() => setEditMetadataModalOpen(true)}
        >
          Edit Metadata Configuration
        </Button>
      </Card.Body>

      <ConnectionMetadataDialog
        configurationType={configurationType.samlConfigurationType}
        onOpenChange={(open) => setEditMetadataModalOpen(open)}
        open={isEditMetadataModalOpen}
      />
    </Card>
  );
};
