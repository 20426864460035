import { Alert, Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Info } from 'react-feather';
import { Form } from '../../../../components/form';
import { CopyInput } from '../../../../components/inputs';
import { usePortalSession } from '../../../../components/portal-session-provider';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { useStepsStore } from '../../../../shared/step-navigation/steps-store-provider';
import { useDsyncStore } from '../../dsync-store-provider';

const Component: FC = () => {
  const { appName } = usePortalSession();
  const { directory } = useDsyncStore();
  const { handleNextStep } = useStepsStore();

  return (
    <>
      <Alert className="mb-4" icon={<Info size={14} />}>
        <Text as="span" size="medium">
          {appName} authenticates via a Bearer Token passed in the HTTP
          authorization header.
        </Text>
      </Alert>

      <Text>
        Provide this endpoint to your directory provider so that it can
        provision users and groups to {appName}.
      </Text>

      <CopyInput
        id="directory-endpoint"
        label="Copy this Endpoint"
        value={directory.endpoint}
      />

      <Text>
        Provide this bearer token to your directory provider so that it can
        authenticate its requests to {appName}.
      </Text>

      <CopyInput
        id="directory-bearer-token"
        label="Copy this Bearer Token"
        type="password"
        value={directory.bearerToken}
      />

      {/* eslint-disable-next-line react/no-children-prop */}
      <Form children={null} onSubmit={handleNextStep} />
    </>
  );
};

export const configureDirectoryProvider: StepData = {
  title: 'Configure Directory Provider',
  component: Component,
};
