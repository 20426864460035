import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { CopyInput } from '../../../../components/inputs';
import { usePortalSession } from '../../../../components/portal-session-provider';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';
import { useSsoStore } from '../../sso-store-provider';

const Component: FC = () => {
  const { appName } = usePortalSession();
  const { connection } = useSsoStore();

  return (
    <StepContent confirmLabel="I've configured the Single sign on URL and Audience URI.">
      <Text>
        In your Okta Application Dashboard go to "Applications" in the sidebar.
      </Text>
      <Img
        className="my-8"
        height={940}
        src="/images/5af20543-8b88-4a7d-9fac-cdf5750ceb1f.png"
        width={1408}
      />

      <Text>Click "Create App Integration".</Text>

      <Img
        className="my-8"
        height={915}
        src="/images/db53e321-105d-47b7-8be1-ec3d93bef3fa.png"
        width={1408}
      />

      <Text>
        Select "Create New App", then select "SAML 2.0" as a Sign on method,
        then click "Next".
      </Text>

      <Img
        className="my-8"
        height={926}
        src="/images/784eac91-0537-4506-b593-4a95072ea846.png"
        width={1407}
      />

      <Text>
        Enter an App name that describes {appName}, then click "Next".
      </Text>

      <Img
        className="my-8"
        height={926}
        src="/images/cd6a6123-e619-49a1-bd56-2a53594b3f44.png"
        width={1407}
      />

      <CopyInput
        id="connection-saml-acs-url"
        label="Copy this Single sign on URL"
        value={connection.saml_acs_url}
      />

      <CopyInput
        id="connection-saml-entity-id"
        label="Copy this Audience URI (SP Entity ID)"
        value={connection.saml_entity_id}
      />

      <Text>
        Submit the "Single Sign on URL" and the "Audience URI (SP Entity ID)".
      </Text>

      <Img
        className="my-8"
        height={935}
        src="/images/f596649d-d72f-4275-b617-af83eb0719d1.png"
        width={1407}
      />
    </StepContent>
  );
};

export const createSamlIntegration: StepData = {
  title: 'Create SAML Integration',
  component: Component,
};
