import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { ConnectionStepProps } from '../../../interfaces/step-props';
import { Confirm } from '../../confirm';
import { Img } from '../../image';
import { CopyInput } from '../../input';
import { MetadataUrlStep } from './saml/metadata-url-step';

export const KeycloakSamlCreateClient: FC<Readonly<ConnectionStepProps>> = ({
  connection,
  onNextStep: handleNextStep,
}) => (
  <>
    <Text>
      Log in to your Keycloak Admin Console, and navigate to the Realm you want
      to set up the SAML client in. Select "Clients" from the side menu and then
      select "Create".
    </Text>

    <Img
      className="my-8"
      height={1670}
      src="/images/258416f7-e05d-4935-b46d-23ac64599b7b.png"
      width={2122}
    />

    <Text>
      Copy the Client ID and Client SAML Endpoint below and input them on the
      Add Client setup page.
    </Text>

    <CopyInput label="Copy this Client ID" value={connection?.saml_entity_id} />

    <CopyInput
      label="Copy this Client SAML Endpoint"
      value={connection?.saml_acs_url}
    />

    <Img
      className="my-8"
      height={1352}
      src="/images/18b4f365-aefc-467a-8cbb-8b61b277de81.png"
      width={2158}
    />

    <Confirm label="I've created a SAML Client" onClick={handleNextStep} />
  </>
);

export const KeycloakSamlConfiguration: FC<Readonly<ConnectionStepProps>> = ({
  connection,
  onNextStep: handleNextStep,
}) => (
  <>
    <Text>
      On the Settings page, scroll down and make sure "Sign Assertions" is
      turned ON, and "Client Signature Required" is turned OFF.
    </Text>

    <Img
      className="my-8"
      height={1292}
      src="/images/ab2cc845-8919-4bac-ac62-583790e810c1.png"
      width={2066}
    />

    <Text>
      Copy the Redirect URI below and enter it in the "Valid Redirect URIs" box.
      This is the same as the Client SAML Endpoint from the previous step.
      Scroll down and click "Save".
    </Text>

    <CopyInput
      label="Copy this Redirect URI"
      value={connection?.saml_acs_url}
    />

    <Img
      className="my-8"
      height={1484}
      src="/images/97a1ec4a-fc6a-4359-997b-b19760e8a79e.png"
      width={2528}
    />

    <Confirm
      label="I've completed my SAML Client Configuration."
      onClick={handleNextStep}
    />
  </>
);

export const KeycloakSamlUserAttributes: FC<Readonly<ConnectionStepProps>> = ({
  onNextStep: handleNextStep,
}) => (
  <>
    <Text>Click the "Mappers" top menu option. Select "Create".</Text>

    <Img
      className="my-8"
      height={1352}
      src="/images/bb01dc4a-2d7e-494c-97a4-01eaebebad39.png"
      width={2252}
    />

    <Text>
      You'll need to create a "User Property" mapper for the following four
      attributes: "id", "email", "firstName", and "lastName".
    </Text>

    <Text>This is an example of how to fill out the fields for "id":</Text>

    <Img
      className="my-8"
      height={1286}
      src="/images/5de3447a-fbc2-48a6-8eca-0038fafff691.png"
      width={2014}
    />

    <Text>
      Also do this for the "email", "firstName", and "lastName" attributes:
    </Text>

    <Img
      className="my-8"
      height={1286}
      src="/images/b66f12f2-ecd0-44d3-90ce-418a6035d61b.png"
      width={2014}
    />

    <Img
      className="my-8"
      height={1286}
      src="/images/699a3b6a-aa51-4915-9914-884d55ca4d59.png"
      width={2014}
    />

    <Img
      className="my-8"
      height={1286}
      src="/images/b55c956d-3d0e-4385-9264-9e309eae3dea.png"
      width={2014}
    />

    <Confirm
      label="I've mapped the user attributes."
      onClick={handleNextStep}
    />
  </>
);

export const KeycloakSamlMetadataUpload: FC<Readonly<ConnectionStepProps>> = (
  connectionStepProps,
) => (
  <MetadataUrlStep
    {...connectionStepProps}
    idpEntityIdPlaceholder="https://mykeycloak.com/123-abc/"
    idpSsoUrlPlaceholder="http://mykeycloak.com/auth/realms/myrealm/protocol/saml"
    metadataUrlPlaceholder="https://mykeycloak.com/auth/realms/myrealm/protocol/saml/descriptor"
  >
    <Text>
      Select "Realm Settings" in the left sidebar navigation menu, and copy the
      "SAML 2.0 Identity Provider Metadata" link on the General page, and input
      it below.
    </Text>

    <Img
      className="my-8"
      height={1360}
      src="/images/d77e0885-48e9-4cc0-83ae-02515cc16046.png"
      width={2272}
    />
  </MetadataUrlStep>
);
