import { unreachable } from '@workos-inc/standard';
import { ConnectionType } from '../../../../graphql/generated';

export const getConnectionName = (type: ConnectionType): string => {
  switch (type) {
    case ConnectionType.Adfssaml:
      return 'Microsoft AD FS';
    case ConnectionType.AdpOidc:
      return 'ADP OpenID Connect';
    case ConnectionType.Auth0Saml:
      return 'Auth0 SAML';
    case ConnectionType.AzureSaml:
      return 'Azure AD';
    case ConnectionType.CasSaml:
      return 'CAS SAML';
    case ConnectionType.CloudflareSaml:
      return 'Cloudflare SAML';
    case ConnectionType.ClassLinkSaml:
      return 'ClassLink SAML';
    case ConnectionType.CyberArkSaml:
      return 'CyberArk SAML';
    case ConnectionType.DuoSaml:
      return 'Duo SAML';
    case ConnectionType.GenericOidc:
      return 'OpenID Connect';
    case ConnectionType.GenericSaml:
      return 'Generic SAML';
    case ConnectionType.GoogleOAuth:
      return 'Google OAuth';
    case ConnectionType.GoogleSaml:
      return 'Google SAML';
    case ConnectionType.JumpCloudSaml:
      return 'JumpCloud SAML';
    case ConnectionType.KeycloakSaml:
      return 'Keycloak SAML';
    case ConnectionType.LastPassSaml:
      return 'LastPass SAML';
    case ConnectionType.MagicLink:
      return 'Magic Link';
    case ConnectionType.MicrosoftOAuth:
      return 'Microsoft OAuth';
    case ConnectionType.MiniOrangeSaml:
      return 'miniOrange SAML';
    case ConnectionType.NetIqSaml:
      return 'NetIQ SAML';
    case ConnectionType.OktaSaml:
      return 'Okta';
    case ConnectionType.OneLoginSaml:
      return 'OneLogin';
    case ConnectionType.OracleSaml:
      return 'Oracle';
    case ConnectionType.PingFederateSaml:
      return 'PingFederate';
    case ConnectionType.PingOneSaml:
      return 'PingOne';
    case ConnectionType.RipplingSaml:
      return 'Rippling';
    case ConnectionType.SalesforceSaml:
      return 'Salesforce';
    case ConnectionType.ShibbolethGenericSaml:
      return 'Shibboleth Generic SAML';
    case ConnectionType.ShibbolethSaml:
      return 'Shibboleth';
    case ConnectionType.SimpleSamlPhpSaml:
      return 'SimpleSAMLphp SAML';
    case ConnectionType.VMwareSaml:
      return 'VMware Workspace One';
    default:
      return unreachable(type);
  }
};
