import { FC } from 'react';
import { StepData } from '../../../../shared/step-navigation';
import { useSsoStepRoutes } from '../../hooks/use-sso-step-routes';
import { MetadataUrlFormFields } from '../../interfaces/metadata-form-fields';
import { SsoStepNavigation } from '../../sso-step-navigation';
import { generateSsoStepData } from '../../utils/generate-sso-step-data';
import { provideIdpInformation } from './1-provide-idp-information';
import { createAGenericSamlApplication } from './2-create-a-generic-saml-application';
import { provideTheSpEntityId } from './3-provide-the-sp-entity-id';
import { uploadYourIdentityProviderMetadata } from './4-upload-your-identity-provider-metadata';
import { configureYourSamlClaims } from './5-configure-your-saml-claims';

interface GenericSamlStepsProps {}

const stepsData: StepData<MetadataUrlFormFields>[] = generateSsoStepData([
  provideIdpInformation,
  createAGenericSamlApplication,
  provideTheSpEntityId,
  uploadYourIdentityProviderMetadata,
  configureYourSamlClaims,
]);

export const GenericSamlSteps: FC<Readonly<GenericSamlStepsProps>> = () => {
  const stepRoutes = useSsoStepRoutes(stepsData);

  return (
    <SsoStepNavigation stepsData={stepsData}>{stepRoutes}</SsoStepNavigation>
  );
};
