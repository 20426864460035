import { InputGroup, Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Card } from '../../../../components/card';
import { Form } from '../../../../components/form';
import { Img } from '../../../../components/image';
import { useFormContext } from '../../../../shared/form-data-provider/form-data-provider';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { useStepsStore } from '../../../../shared/step-navigation/steps-store-provider';
import { HibobFormData } from './hibob-steps';

const Component: FC = () => {
  const { handleInputChange, handleNextStep, isUpdating } = useStepsStore();
  const { formData, formErrors } = useFormContext<HibobFormData>();

  return (
    <>
      <Text>
        An <strong> ID </strong> and <strong> Token </strong>
        will appear - make sure to save these somewhere secure should you need
        to access them again.
      </Text>

      <Img
        className="my-8"
        height={717}
        src="/images/6f8e2cec-3531-4f7e-8a1e-63df6ec9914e.png"
        width={1019}
      />
      <Text>Upload the Service User ID and Token below:</Text>

      <Card>
        <Form
          disabled={!formData.bob_service_user_id || !formData.bob_api_token}
          isLoading={isUpdating}
          isUpdate={
            !!(formErrors.bob_service_user_id || formErrors.bob_api_token)
          }
          onSubmit={handleNextStep}
        >
          <InputGroup
            autoFocus
            error={formErrors.bob_service_user_id?.message}
            id="bob_service_user_id"
            label="1. Service User ID"
            name="bob_service_user_id"
            onChange={handleInputChange}
            placeholder="SERVICE-387"
            value={formData.bob_service_user_id ?? ''}
          />

          <InputGroup
            error={formErrors.bob_api_token?.message}
            id="bob_api_token"
            label="2. Service User API Token"
            name="bob_api_token"
            onChange={handleInputChange}
            placeholder=""
            type="password"
            value={formData.bob_api_token ?? ''}
          />
        </Form>
      </Card>
    </>
  );
};

export const uploadHibobServiceUserIdAndToken: StepData<HibobFormData> = {
  title: 'Upload Hibob Service User ID and Token',
  component: Component,
  formKeys: ['bob_api_token', 'bob_service_user_id'],
};
