import axios from 'axios';
import { FC, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingScreen } from '../loading-screen';

interface SetupLinkHandlerProps {}

interface SetupResponse {
  sessionToken: string;
}

export const SetupLinkHandler: FC<Readonly<SetupLinkHandlerProps>> = () => {
  const { setupToken } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    const loadSetup = async () => {
      try {
        const apiUrl = process.env.NEXT_PUBLIC_API_URL;

        const { data } = await axios.post<SetupResponse>(
          `${apiUrl}/portal/init_setup_session`,
          {
            setupToken,
          },
        );

        if (!data) {
          return navigate('/not-found');
        }

        navigate(`/?token=${data.sessionToken}`, { replace: true });
      } catch (err) {
        navigate('/not-found');
      }
    };

    void loadSetup();
  }, [navigate, setupToken]);

  return <LoadingScreen />;
};
