import { FC, useEffect } from 'react';
import { usePortalSession } from '../portal-session-provider';
import { setPrimaryColorCssVariable } from './set-primary-color-css-variable';

export const ThemeLoader: FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const { primaryColor } = usePortalSession();
  useEffect(() => {
    if (primaryColor) {
      setPrimaryColorCssVariable(primaryColor);
    }
  }, [primaryColor]);
  return <>{children}</>;
};
