import { Pill } from '@workos-inc/component-library';
import classnames from 'classnames';
import { FC } from 'react';

export interface GroupsCellProps {
  groups: { id: string; name: string }[];
  rowId: string;
}

interface GroupCellProps {
  group: { id: string; name: string };
}

const GroupCell = ({ group }: GroupCellProps) => (
  <Pill className="-my-2" size="small">
    {group.name}
  </Pill>
);

export const GroupsCell: FC<Readonly<GroupsCellProps>> = ({
  groups = [],
  rowId,
}) => {
  const [firstGroup, ...additionalGroups] = groups;

  if (!firstGroup) {
    return <>-</>;
  }

  return (
    <div className="group cursor-default">
      <GroupCell group={firstGroup} />

      {additionalGroups.length > 0 && (
        <>
          <Pill
            className="-my-2 ml-1"
            data-testid="additional-groups"
            size="small"
          >
            +{additionalGroups.length}
          </Pill>

          <div
            aria-labelledby="user-menu"
            aria-orientation="vertical"
            className="fixed pt-2"
            role="menu"
          >
            <div
              className={classnames(
                'hidden cursor-default space-y-2 rounded-md border p-1 shadow-lg group-hover:block',
                'border-gray-lightmode-200 bg-white',
                'dark:border-gray-darkmode-200 dark:bg-gray-darkmode-50',
              )}
            >
              {groups.map((group) => (
                <div key={`groups-$${rowId}-${group.id}`} className="p-1">
                  <GroupCell group={group} />
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
