import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';

const Component: FC = () => (
  <StepContent confirmLabel="I've added the domain security policies to the security group and activated them.">
    <Text>
      Next, you'll need to add domain security policies to the newly created
      security group. You can access this on the Security Group Settings {'>'}{' '}
      Maintain Domain Permissions for Security Group page.
    </Text>

    <Img
      className="my-8"
      height={1044}
      src="/images/5b3e1ce7-db32-4c16-80b1-07e1dd348352.png"
      width={2020}
    />

    <Text>
      You'll need to permit the following domain security policies to have Get
      access under Integration Permissions:
    </Text>

    <ul className="my-4 list-inside list-disc space-y-3 text-gray-lightmode-300">
      <li>Person Data: Work Contact Information</li>
      <li>Workday Accounts</li>
      <li>Worker Data: Active and Terminated Workers</li>
      <li>Worker Data: All Positions</li>
      <li>Worker Data: Business Title on Worker Profile</li>
      <li>Worker Data: Current Staffing Information</li>
      <li>Worker Data: Public Worker Reports</li>
      <li>Worker Data: Workers</li>
    </ul>

    <Img
      className="my-8"
      height={328}
      src="/images/f6836a3a-5d54-4d46-ba18-3f2ac3226d8a.png"
      width={629}
    />

    <Text>
      To activate these new security settings, you need to go to the Activate
      Pending Security Policy Changes page and click OK.
    </Text>

    <Img
      className="my-8"
      height={2388}
      src="/images/bd19866c-dbb9-4828-9996-82f10a8ac0a3.png"
      width={1906}
    />

    <Text>Then, select the Confirm checkbox to finish activating.</Text>

    <Img
      className="my-8"
      height={708}
      src="/images/b8ee6926-c254-4f83-a987-2f1e433d587a.png"
      width={1484}
    />
  </StepContent>
);

export const addDomainSecurityPoliciesToTheSecurityGroup: StepData = {
  title: 'Add domain security policies to the Security Group',
  component: Component,
};
