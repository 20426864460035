import { FC } from 'react';
import { CenteredPageContent } from '../../../../components/centered-page-content';
import { LoadingScreen } from '../../../../components/loading-screen';
import { useSsoStore } from '../../sso-store-provider';
import { ConnectionMetadata } from './connection-metadata/connection-metadata';
import { ConnectionOverview } from './connection-overview';
import { DeleteConnection } from './delete-connection';
import { RecentEventFlashMessage } from './recent-event-flash-message';
import { ConnectionRecentEvents } from './sso-connection-events-table/connection-recent-events';

interface ConfiguredSsoConnectionPageProps {}

export const ConfiguredSsoConnectionPage: FC<
  Readonly<ConfiguredSsoConnectionPageProps>
> = () => {
  const { connection } = useSsoStore();

  if (!connection) {
    return <LoadingScreen />;
  }

  return (
    <CenteredPageContent className="flex flex-col gap-8">
      <RecentEventFlashMessage />
      <ConnectionOverview />
      <ConnectionMetadata />
      <ConnectionRecentEvents />
      <DeleteConnection />
    </CenteredPageContent>
  );
};
