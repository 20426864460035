import { unreachable } from '@workos-inc/standard';
import { ConnectionSessionFragment as RawConnectionSessionFragment } from '../generated';

export type ConnectionSessionFragment =
  // We're unable to remove a case from the `ConnectionSessionFragment` type and
  // the constituent types that make up that union are not exported, so we have
  // to use the inferred return type of `connectionSessionDeserializer` to name
  // the type that we want.
  ReturnType<typeof connectionSessionDeserializer>;

// Allow the return type to be inferred so we can name it above.
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const connectionSessionDeserializer = (
  connectionSessionFragment: RawConnectionSessionFragment,
) => {
  switch (connectionSessionFragment.__typename) {
    case 'OAuthSession': {
      const { oauthSessionState, ...oauthSession } = connectionSessionFragment;

      return {
        ...oauthSession,
        state: oauthSessionState,
      };
    }

    case 'OidcSession': {
      const { oidcSessionState, ...oidcSession } = connectionSessionFragment;

      return {
        ...oidcSession,
        state: oidcSessionState,
      };
    }

    case 'SAMLSession': {
      const { samlSessionState, ...samlSession } = connectionSessionFragment;

      return {
        ...samlSession,
        state: samlSessionState,
      };
    }

    default:
      return unreachable(connectionSessionFragment);
  }
};
