import '../styles/globals.css';
import type { AppProps } from 'next/app';
import { FC } from 'react';
import { BugsnagErrorBoundary } from '../components/error-boundaries';
import { FeatureFlagProvider } from '../components/feature-flag-provider';
import { NewAdminPortalWrapper } from '../new-admin-portal/components/new-admin-portal-wrapper';
import { initDataDogRum } from '../utils/telemetry/datadog';
import { ToastProvider } from '../utils/toast-context';

const MyApp: FC<Readonly<AppProps>> = ({ Component, pageProps }) => {
  initDataDogRum();

  return (
    <BugsnagErrorBoundary>
      <FeatureFlagProvider>
        <ToastProvider>
          <NewAdminPortalWrapper>
            <Component {...pageProps} />
          </NewAdminPortalWrapper>
        </ToastProvider>
      </FeatureFlagProvider>
    </BugsnagErrorBoundary>
  );
};

export default MyApp;
