import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { CopyInput } from '../../../../components/inputs';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';
import { useSsoStore } from '../../sso-store-provider';

const Component: FC = () => {
  const { connection } = useSsoStore();

  return (
    <StepContent confirmLabel="I've configured the SAML Protocol Settings">
      <Text>
        Navigate to the "Protocol Settings" page and select the "Configure
        Protocol Settings" button.
      </Text>

      <Img
        className="my-8"
        height={838}
        src="/images/09703b3e-fd1b-4629-a2e1-7e0588250a04.png"
        width={2458}
      />

      <Text>
        On the "Assertion Consumer Service URL" page, paste the ACS URL into the
        Endpoint URL field with a POST binding, then click "Next".
      </Text>

      <CopyInput
        id="acs-url"
        label="Copy this ACS URL"
        value={connection.saml_acs_url}
      />

      <Img
        className="my-8"
        height={838}
        src="/images/962df851-37a6-4b38-98d2-ddcb1ed0fcb3.png"
        width={2458}
      />

      <Text>
        On the "Allowable SAML Bindings" page, check at least POST and REDIRECT,
        then click "Next".
      </Text>

      <Img
        className="my-8"
        height={838}
        src="/images/0a9c8484-1e9b-4eff-8fe4-0eaa05f2e654.png"
        width={2458}
      />

      <Text>
        On the "Signature Policy" page, select "Always Sign Assertion", then
        click "Next".
      </Text>

      <Img
        className="my-8"
        height={838}
        src="/images/0be84165-cf45-4eca-9c56-1f838f4cef0f.png"
        width={2458}
      />

      <Text>
        On the "Encryption Policy" page, select "None", then click "Next".
      </Text>

      <Img
        className="my-8"
        height={1168}
        src="/images/3086d67d-ad32-46a7-bf38-3ef7aa7ada07.png"
        width={2458}
      />
    </StepContent>
  );
};

export const configureProtocolSettings: StepData = {
  title: 'Configure Protocol Settings',
  component: Component,
};
