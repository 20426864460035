import { FC } from 'react';
import * as React from 'react';

export const AttributeContainer: FC<{ children?: React.ReactNode }> = ({
  children,
}) => (
  <div className="mb-4 grid grid-cols-[1fr_min-content_1fr] items-center">
    {children}
  </div>
);
