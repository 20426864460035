import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';

const Component: FC = () => (
  <StepContent confirmLabel="I've initiated the SCIM sync run.">
    <Text>
      In CyberArk, navigate to the Settings {'>'} Users {'>'} Outbound
      Provisioning page. Under Synchronizations, start the sync. You can also
      set up scheduled syncs here.
    </Text>

    <Img
      className="my-8"
      height={1023}
      src="/images/ecc6072e-4739-49c5-8fc1-4adc92d3c92c.png"
      width={1585}
    />
  </StepContent>
);

export const initiateTheDirectorySyncRunInCyberArk: StepData = {
  title: 'Initiate the directory sync run in CyberArk',
  component: Component,
};
