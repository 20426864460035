import { Button } from '@workos-inc/component-library';
import { FC } from 'react';
import { ArrowLeft, ArrowRight } from 'react-feather';

export type PaginationProps = {
  hasNext?: boolean;
  hasPrevious?: boolean;
  onNextPage: () => void;
  onPreviousPage: () => void;
};

export const Pagination: FC<Readonly<PaginationProps>> = ({
  hasNext = true,
  hasPrevious = true,
  onPreviousPage: handlePreviousPage,
  onNextPage: handleNextPage,
}) => (
  <nav className="mt-4 mb-8 flex gap-4">
    <Button
      appearance="minimal"
      disabled={!hasPrevious}
      iconLeft={<ArrowLeft size={16} />}
      onClick={handlePreviousPage}
    >
      Previous
    </Button>

    <Button
      appearance="minimal"
      disabled={!hasNext}
      iconRight={<ArrowRight size={16} />}
      onClick={handleNextPage}
    >
      Next
    </Button>
  </nav>
);
