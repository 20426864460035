import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { CopyInput } from '../../../../components/inputs';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';
import { useSsoStore } from '../../sso-store-provider';

const Component: FC = () => {
  const { connection } = useSsoStore();

  return (
    <StepContent confirmLabel="I’ve added the Redirect URI into ADP.">
      <Text>
        In this step you'll navigate to the SSO configurations in ADP and add
        the Redirect URI provided below.
      </Text>

      <Img
        className="my-8"
        height={917}
        src="/images/6a139f97-cd70-4376-92e2-efa986dfb04f.png"
        width={1526}
      />

      <CopyInput
        id="oidc-redrect-uri"
        label="Copy this Redirect URI"
        value={connection.oidc_redirect_uri}
      />

      <Text>
        Now that a new project has been created browse to the "Development API
        Credentials" Tab within the project.
      </Text>

      <Text>Click on the "End-User/SSO sub-tab" from this view.</Text>

      <Text>
        Paste the Redirect URI into the App redirect URI field and click "Update
        Redirect".
      </Text>
    </StepContent>
  );
};

export const configureTheRedirectUriInAdp: StepData = {
  title: 'Configure the Redirect URI in ADP',
  component: Component,
};
