import {
  Alert,
  Button,
  Dialog,
  InputGroup,
  Pill,
  Text,
  Tooltip,
} from '@workos-inc/component-library';
import { unreachable } from '@workos-inc/standard';
import { ChangeEvent, FC, FormEvent, useState } from 'react';
import { Download, FileText, RefreshCcw, Trash } from 'react-feather';
import { useToast } from '../../../../../../utils/toast-context';
import { FileField } from '../../../../../components/fields';
import { graphql } from '../../../../../utils/graphql';
import { logError } from '../../../../../utils/logger';
import { useSsoStore } from '../../../sso-store-provider';

interface ConnectionMetadataManualProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onIsManualChange?: (isManual: boolean) => void;
}

export const ConnectionMetadataManual: FC<
  Readonly<ConnectionMetadataManualProps>
> = ({ onOpenChange: handleOpenChange, onIsManualChange, open }) => {
  const { connection, setSsoStore } = useSsoStore();

  const { showToast } = useToast();
  const [newX509Certificate, setNewX509Certificate] = useState<string>();
  const [samlIdpEntityId, setSamlIdpEntityId] = useState(
    connection?.saml.idpEntityId,
  );
  const [samlIdpUrl, setSamlIdpUrl] = useState(connection.saml_idp_url);
  const [isUpdatingConnection, setIsUpdatingConnection] = useState(false);

  const [connectionUpdateError, setConnectionUpdateError] =
    useState<JSX.Element>();

  const handleIdpEntityIdChange = async (
    event: ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    setSamlIdpEntityId(event.target.value);
  };

  const handleIpdUrlChange = async (
    event: ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    setSamlIdpUrl(event.target.value);
  };

  const handleUpdateManualConnection = async (event: FormEvent) => {
    event.preventDefault();

    const existingSamlX509Certificates = connection.samlX509Certificates.map(
      ({ value }) => value,
    );

    try {
      setIsUpdatingConnection(true);

      const response = await graphql().UpdateConnection({
        input: {
          connectionId: connection.id,
          saml: {
            idpEntityId: samlIdpEntityId,
            idpUrl: samlIdpUrl,
            x509Certs: newX509Certificate
              ? [...existingSamlX509Certificates, newX509Certificate]
              : existingSamlX509Certificates,
          },
        },
      });

      const result = response.data?.portal_updateConnection;

      switch (result?.__typename) {
        case 'Portal_ConnectionUpdated': {
          const { connection: updatedConnection } = result;

          handleOpenChange(false);

          showToast({
            title: `${updatedConnection.name} connection updated`,
            description:
              'Your changes have been successfully applied to the connection.',
          });

          setSsoStore({
            connection: updatedConnection,
          });

          break;
        }
        case 'VerifyConnectionFailed': {
          setConnectionUpdateError(
            <Text as="p">Failed to verify connection </Text>,
          );

          break;
        }
        case 'ConnectionNotFound': {
          setConnectionUpdateError(
            <Text as="p">
              The connection {result.connectionId} could not be found.
            </Text>,
          );

          break;
        }
        case 'InvalidSamlX509Certificate': {
          setConnectionUpdateError(
            <Text as="p">The given SAML X509 Certificate was invalid.</Text>,
          );

          break;
        }
        case undefined:
          break;
        default:
          return unreachable(result);
      }
    } catch (error) {
      logError(error);

      setConnectionUpdateError(<>Metadata URL is not valid.</>);
    } finally {
      setIsUpdatingConnection(false);
    }
  };

  const handleDeleteSamlX509Certificate = async (id: string) => {
    try {
      const response = await graphql().DeleteSamlX509Certificate({
        input: { samlX509CertificateId: id },
      });
      const result = response.data?.portal_deleteSamlX509Certificate;

      switch (result?.__typename) {
        case 'Portal_SamlX509CertificateDeleted':
          void setSsoStore({
            connection: {
              ...connection,
              samlX509Certificates: connection.samlX509Certificates.filter(
                ({ id }) => id !== result.samlX509Certificate.id,
              ),
            },
          });
          break;
        case 'SamlX509CertificateNotFound':
          setConnectionUpdateError(
            <Text as="p">
              Unable to find the SAML X.509 certificate. Has it already been
              deleted?
            </Text>,
          );
          break;
        case undefined:
          break;
        default:
          return unreachable(result);
      }
    } catch (error) {
      logError(error);

      setConnectionUpdateError(
        <Text as="p">
          An unknown error has occurred while attempting to delete the SAML
          X.509 certificate.
        </Text>,
      );
    }
  };

  return (
    <Dialog
      acceptButtonProps={{
        type: 'submit',
        form: 'manualConnectionMetadataForm',
      }}
      acceptText="Save Configuration"
      isLoading={isUpdatingConnection}
      onOpenChange={handleOpenChange}
      open={open}
      title={
        onIsManualChange ? 'Manual Configuration' : 'Metadata Configuration'
      }
      titleSuffix={
        onIsManualChange && (
          <Button
            appearance="secondary"
            iconLeft={<RefreshCcw size={16} />}
            onClick={() => onIsManualChange(false)}
            size="small"
          >
            Switch to Dynamic Configuration
          </Button>
        )
      }
    >
      <form
        id="manualConnectionMetadataForm"
        onSubmit={handleUpdateManualConnection}
      >
        <InputGroup
          id="saml_idp_url"
          label="Single Sign-On URL"
          name="saml_idp_url"
          onChange={handleIpdUrlChange}
          placeholder="https://foo-corp.okta.com/app/exkgq2/sso/saml"
          value={samlIdpUrl ?? ''}
        />

        <InputGroup
          className="mt-4"
          id="saml_idp_entity_id"
          label="Identity Provider Issuer"
          name="saml_idp_entity_id"
          onChange={handleIdpEntityIdChange}
          placeholder="http://www.okta.com/exkgq2c19CUpt2Brr46"
          value={samlIdpEntityId ?? ''}
        />

        {connection?.samlX509Certificates.map((samlX509Certificate) => (
          <div
            key={samlX509Certificate.id}
            className="mt-4 flex justify-between"
            data-testid={`certificate-info-${samlX509Certificate.id}`}
          >
            <Pill appearance="blue">
              <FileText className="mr-2" size={16} />
              certificate.pem
            </Pill>

            <div className="flex items-center space-x-2">
              <Tooltip content="Download certificate">
                <a
                  download="certificate.pem"
                  href={`data:text/plain;charset=utf-8, ${encodeURIComponent(
                    samlX509Certificate.value,
                  )}`}
                >
                  <Button appearance="secondary" size="small">
                    <Download size={16} />
                  </Button>
                </a>
              </Tooltip>

              <DeleteSamlX509Certificate
                onDeleteSamlX509Certificate={handleDeleteSamlX509Certificate}
                samlX509CertificateId={samlX509Certificate.id}
              />
            </div>
          </div>
        ))}

        <div className="mt-4">
          <FileField
            label="Add an X.509 Certificate"
            name="saml_x509_certs"
            onUpload={({ file }) => {
              setNewX509Certificate(file.content);
            }}
            value={newX509Certificate}
          />
        </div>

        {connectionUpdateError && (
          <Alert appearance="red" className="mt-6">
            <Text inheritColor as="p">
              {connectionUpdateError}
            </Text>
          </Alert>
        )}
      </form>
    </Dialog>
  );
};

interface DeleteSamlX509CertificateProps {
  onDeleteSamlX509Certificate: (id: string) => void;
  samlX509CertificateId: string;
}

const DeleteSamlX509Certificate: FC<
  Readonly<DeleteSamlX509CertificateProps>
> = ({ samlX509CertificateId, onDeleteSamlX509Certificate }) => {
  const [isDeleting, setIsDeleting] = useState(false);

  if (isDeleting) {
    return (
      <Button
        appearance="red"
        data-testid="delete-saml-certificate-confirmation-button"
        onClick={() => {
          void onDeleteSamlX509Certificate(samlX509CertificateId);
        }}
        size="small"
      >
        Confirm Delete
      </Button>
    );
  }

  return (
    <Button
      appearance="secondary"
      data-testid="delete-saml-certificate-button"
      onClick={() => setIsDeleting(true)}
      size="small"
    >
      <Tooltip content="Delete certificate">
        <Trash size={16} />
      </Tooltip>
    </Button>
  );
};
