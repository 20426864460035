import { Button, Pill, Text } from '@workos-inc/component-library';
import { formatDistance } from 'date-fns';
import { motion } from 'framer-motion';
import { Navigate } from 'react-router-dom';
import { DirectoryProviderLabels } from '../../../../utils/constants';
import { PageLayout } from '../../../components/page-layout/page-layout';
import { usePortalSession } from '../../../components/portal-session-provider';
import {
  ProviderSelection,
  useProviderSelection,
} from '../../../components/provider-selection';
import { isDomain } from '../../../utils/is-domain';
import { dsyncProviderConfigurators } from '../dsync-providers-configurators';
import { useCreateDirectory } from '../hooks/use-create-directory';
import { useEnabledDirectories } from '../hooks/use-enabled-directories';
import { supportedDirectoryTypes } from '../interfaces/supported-directory-type';

export const DirectorySyncProviderSelectionPage = () => {
  const { setupLink, appName, domains } = usePortalSession();
  const [creationState, createDirectory] = useCreateDirectory();
  const enabledDirectories = useEnabledDirectories(supportedDirectoryTypes);
  const {
    configData,
    handleSelectedProvider,
    resetProviderSelectionState,
    activeConfigurator,
    providerSelectionProps,
  } = useProviderSelection({
    initialDirectoryDomain: domains[0],
    requestState: creationState,
    providers: enabledDirectories,
    providerConfigurators: dsyncProviderConfigurators,
    providerLabels: DirectoryProviderLabels,
    onSubmit: async (providerConfigData) => {
      await createDirectory(providerConfigData);
    },
  });

  if (creationState.type === 'success') {
    return <Navigate replace to="/dsync/configure/1" />;
  }
  const isSubmissionDisabled =
    !configData.selectedProvider ||
    creationState.type === 'waiting' ||
    !isDomain(configData.directoryDomain || '');

  if (activeConfigurator) {
    return (
      <PageLayout
        key="config-step"
        description={activeConfigurator.description}
        navTitle="Configure Directory Sync"
        title={activeConfigurator.title}
      >
        <ProviderSelection {...providerSelectionProps} />

        <motion.div
          animate={{ opacity: 1, scale: 1 }}
          className="mt-6"
          initial={{ opacity: 0, scale: 0.5 }}
          transition={{ delay: 0.3 }}
        >
          <Button
            className="px-6"
            disabled={isSubmissionDisabled}
            onClick={handleSelectedProvider}
            size="large"
          >
            Create Directory
          </Button>
        </motion.div>
        <motion.div
          animate={{ opacity: 1, scale: 1 }}
          className="mt-3"
          initial={{ opacity: 0, scale: 0.5 }}
          transition={{ delay: 0.3 }}
        >
          <Button
            appearance="minimal"
            className="px-6"
            disabled={creationState.type === 'waiting'}
            onClick={() => resetProviderSelectionState()}
          >
            Change provider
          </Button>
        </motion.div>
      </PageLayout>
    );
  }

  return (
    <PageLayout
      description={
        <>
          <Text as="p" size="medium">
            Select the Directory Provider you want to use with
          </Text>{' '}
          <Pill className="max-w-none" size="small">
            {appName}
          </Pill>
        </>
      }
      navTitle="Configure Directory Sync"
      title="Directory Sync Configuration"
    >
      <ProviderSelection {...providerSelectionProps} />

      <motion.div
        animate={{ opacity: 1, scale: 1 }}
        className="mt-6"
        initial={{ opacity: 0, scale: 0.5 }}
        transition={{ delay: 0.3 }}
      >
        <Button
          className="px-6"
          disabled={
            !configData.selectedProvider || creationState.type === 'waiting'
          }
          onClick={handleSelectedProvider}
          size="large"
        >
          Get Started →
        </Button>
      </motion.div>

      {setupLink ? (
        <Text className="mt-4 text-center">
          Setup Link access available for{' '}
          {formatDistance(new Date(setupLink.expiresAt), Date.now())} or until
          configured.
        </Text>
      ) : null}
    </PageLayout>
  );
};
