import { unreachable } from '@workos-inc/standard';
import { FC } from 'react';
import {
  InvalidSamlX509Certificate,
  SamlX509CertificateValidationErrorCode,
} from '../../../graphql/generated';

export interface SamlX509CertificateValidationErrorProps {
  error: InvalidSamlX509Certificate;
}

export const SamlX509CertificateValidationError: FC<
  SamlX509CertificateValidationErrorProps
> = ({ error }) => {
  const { validationError } = error;

  switch (validationError) {
    case SamlX509CertificateValidationErrorCode.ExpiredCertificate:
      return <>The metadata contains an expired X.509 certificate.</>;
    case SamlX509CertificateValidationErrorCode.MalformedCertificate:
      return <>The metadata contains a malformed X.509 certificate.</>;
    default:
      return unreachable(validationError);
  }
};
