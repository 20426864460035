import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { CopyInput } from '../../../../components/inputs';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';
import { useSsoStore } from '../../sso-store-provider';

const Component: FC = () => {
  const { connection } = useSsoStore();
  return (
    <StepContent confirmLabel="I’ve configured the Service Provider Entity ID in Duo.">
      <Text>
        Next, you'll begin to configure the Generic Service Provider settings,
        starting with the Entity ID.
      </Text>

      <CopyInput
        id="saml-entity-id"
        label="Copy this Identifier"
        value={connection.saml_entity_id}
      />

      <Text>
        Copy the provided Entity ID and paste it into the "Entity ID" field
        under the "Service Provider" section.
      </Text>

      <Img
        className="my-8"
        height={1040}
        src="/images/8562779e-d625-48f3-a024-1522bcc84092.png"
        width={2188}
      />
    </StepContent>
  );
};

export const provideTheEntityIdToDuo: StepData = {
  title: 'Provide the Entity ID to Duo',
  component: Component,
};
