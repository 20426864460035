import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';

const Component: FC = () => (
  <StepContent confirmLabel="I've added a custom SAML app.">
    <Text>
      On your Google Admin dashboard, select "Apps" from the sidebar menu, and
      then select "Web and Mobile Apps" from the following list. On this page,
      select "Add App" and then "Add custom SAML app".
    </Text>

    <Img
      className="my-8"
      height={1009}
      src="/images/2586671f-9aec-4889-affd-76821224584f.png"
      width={1474}
    />
  </StepContent>
);

export const addCustomSamlApplication: StepData = {
  title: 'Add Custom SAML Application',
  component: Component,
};
