import { unreachable } from '@workos-inc/standard';
import { FC } from 'react';
import { SamlConfigurationType } from '../../../hooks/use-update-connection/utils/connection-configuration-type';
import { useSsoStore } from '../../../sso-store-provider';
import { ConnectionMetadataManual } from './connection-metadata-manual';
import { ConnectionMetadataUrl } from './connection-metadata-url';
import { ConnectionMetadataXml } from './connection-metadata-xml';

interface ConnectionMetadataDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  configurationType: SamlConfigurationType;
}

export const ConnectionMetadataDialog: FC<ConnectionMetadataDialogProps> = ({
  open,
  onOpenChange: handleOpenChange,
  configurationType,
}) => {
  const { selectedConfiguration, setSsoStore } = useSsoStore();

  if (selectedConfiguration === 'manual') {
    return (
      <ConnectionMetadataManual
        onIsManualChange={() =>
          setSsoStore({ selectedConfiguration: 'dynamic' })
        }
        onOpenChange={handleOpenChange}
        open={open}
      />
    );
  }

  switch (configurationType) {
    case 'MetadataUrl':
      return (
        <ConnectionMetadataUrl
          onIsManualChange={() =>
            setSsoStore({ selectedConfiguration: 'manual' })
          }
          onOpenChange={handleOpenChange}
          open={open}
        />
      );
    case 'MetadataXml':
      return (
        <ConnectionMetadataXml
          onIsManualChange={() =>
            setSsoStore({ selectedConfiguration: 'manual' })
          }
          onOpenChange={handleOpenChange}
          open={open}
        />
      );
    case 'Manual':
      return (
        <ConnectionMetadataManual onOpenChange={handleOpenChange} open={open} />
      );
    default:
      return unreachable(configurationType);
  }
};
