import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';

const Component: FC = () => (
  <StepContent confirmLabel="I've added users and/or groups to the SAML app.">
    <Text>
      In order to test out SSO authentication, you will first need to assign
      your Okta SAML app to Groups.
    </Text>

    <Img
      className="my-8"
      height={853}
      src="/images/0e09272c-08a4-4305-bf60-7e8e5ff9e6ef.png"
      width={1457}
    />

    <Text>
      Click on the "Assignments" tab of the app and then select "Assign to
      Groups".
    </Text>

    <Img
      className="my-8"
      height={853}
      src="/images/d1f50dd5-cf6b-4b8c-8fea-c71e40dc3017.png"
      width={1457}
    />

    <Text>
      Find the Group(s) that you would like to assign to the app and click
      "Assign" next to it. Click "Done" when you're finished.
    </Text>
  </StepContent>
);

export const addUsersToSamlApp: StepData = {
  title: 'Add Users to SAML App',
  component: Component,
};
