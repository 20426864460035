import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { CopyInput } from '../../../../components/inputs';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';
import { useDsyncStore } from '../../dsync-store-provider';

const Component: FC = () => {
  const { directory } = useDsyncStore();

  return (
    <StepContent confirmLabel="I’ve confirmed, I've entered the bearer token, users and groups.">
      <Text>
        Copy and paste the Bearer Token into the "Bearer Token" field.
      </Text>

      <CopyInput
        id="directory-bearer-token"
        label="Copy this Bearer Token"
        type="password"
        value={directory.bearerToken}
      />

      <Img
        className="my-8"
        height={1109}
        src="/images/7dc156e2-7584-4efd-a748-39419b182875.png"
        width={1402}
      />

      <Text>
        In order for your users and groups to be synced, you will need to assign
        them to your Rippling Application. Match the Rippling users to the
        account, or create a new application account for the user(s).
      </Text>

      <Img
        className="my-8"
        height={1109}
        src="/images/3f3c8972-2cf6-401c-9379-0ec043f6dba1.png"
        width={1402}
      />

      <Text>Create Groups to add to the application as needed.</Text>

      <Img
        className="my-8"
        height={1109}
        src="/images/36b16a45-3db7-4793-8b87-b1fec0467e2d.png"
        width={1402}
      />
    </StepContent>
  );
};

export const configureBearerTokenUsersAndGroups: StepData = {
  title: 'Configure Bearer Token, Users, and Groups',
  component: Component,
};
