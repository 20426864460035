import { InlineCode, Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { ExternalLink } from '../../../../components/link';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';
import { ConnectionAttributeMapping } from '../../attribute-mapping';
import { useSsoStore } from '../../sso-store-provider';

const Component: FC = () => {
  const { connection } = useSsoStore();

  return (
    <StepContent confirmLabel="I've finished configuring the SAML attributes.">
      <Text>
        At minumum, the Attribute Statement in the SAML Response should include{' '}
        <InlineCode>id</InlineCode>, <InlineCode>email</InlineCode>,{' '}
        <InlineCode>firstName</InlineCode>, and{' '}
        <InlineCode>lastName</InlineCode> attributes. Refer to the{' '}
        <ExternalLink
          newTab
          appearance="highlight"
          href="https://shibboleth.atlassian.net/wiki/spaces/CONCEPT/pages/928645122/SAMLAttributeNaming"
        >
          Shibboleth documentation
        </ExternalLink>{' '}
        for more information on adding and mapping attributes.
      </Text>

      <ConnectionAttributeMapping connectionType={connection.type} />
    </StepContent>
  );
};

export const configureSamlAttributes: StepData = {
  title: 'Configure SAML Attributes',
  component: Component,
};
