import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';

const Component: FC = () => (
  <StepContent confirmLabel="I have created an SP SCIM Connection in PingFederate.">
    <Text>
      Log in as an Administrator to your PingFederate instance, and select
      "Applications" {'> '}
      "SP Connections".
    </Text>

    <Img
      className="my-8"
      height={1382}
      src="/images/82c127f9-3309-4f76-8bd1-267ff839e9da.png"
      width={2118}
    />

    <Text>Select "Create Connection".</Text>

    <Img
      className="my-8"
      height={1382}
      src="/images/59168455-f847-441a-9af7-71e0a9778a99.png"
      width={2118}
    />

    <Text>
      On the Connection Template page, select "Use a Template for this
      Connection" and then select "SCIM Connector" from the dropdown list. If
      you don’t see the SCIM Connector option, go back to the Install SCIM
      Connector in PingFederate step. Click "Next".
    </Text>

    <Img
      className="my-8"
      height={1156}
      src="/images/401ed988-9cdd-4e29-9823-cbb3be44d86e.png"
      width={2490}
    />

    <Text>
      On the Connection Type page, make sure Outbound Provisioning is checked
      with the SCIM Connector Type. Click "Next".
    </Text>

    <Img
      className="my-8"
      height={1214}
      src="/images/64077d0b-ae10-44e0-987e-16242656e937.png"
      width={2490}
    />

    <Text>
      On the General Info page, give this connection a descriptive name, and
      click "Next".
    </Text>

    <Img
      className="my-8"
      height={2222}
      src="/images/dd692373-be1d-47f6-b139-fb70298b1a6e.png"
      width={2490}
    />
  </StepContent>
);

export const createAScimConnectorApplicationInPingFederate: StepData = {
  title: 'Create a SCIM Connector Application in PingFederate',
  component: Component,
};
