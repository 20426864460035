import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { CopyInput } from '../../../../components/inputs';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';
import { useSsoStore } from '../../sso-store-provider';

const Component: FC = () => {
  const { connection } = useSsoStore();

  return (
    <StepContent confirmLabel="I've created and configured my application">
      <Text>
        For SSO to properly function with your Identity Provider, you'll need to
        create and configure your application to:
      </Text>

      <ul className="my-4 list-inside list-disc space-y-3 text-gray-lightmode-300">
        <li>Support the authorization code grant type</li>
        <li>Have the URL below listed as a login redirect URI</li>
      </ul>

      <CopyInput
        id="oidc-redirect-url"
        label="Copy your login redirect URI"
        value={connection.oidc_redirect_uri}
      />
    </StepContent>
  );
};

export const createAnApplicationWithYourIdp: StepData = {
  title: 'Create an Application with your IdP',
  component: Component,
};
