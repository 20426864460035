import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import * as React from 'react';

type TitleProps = {
  children?: React.ReactNode;
  className?: string;
};

export const Title: FC<Readonly<TitleProps>> = ({ children, className }) => (
  <Text as="h1" className={className} size="xlarge" weight="medium">
    {children}
  </Text>
);
