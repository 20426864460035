import { format, parseISO } from 'date-fns';

export function formatAuditLogEventDate(
  dateString: string,
  dateFormat = 'MMMM dd, yyyy',
): string | null {
  try {
    const date = parseISO(dateString);
    return format(date, dateFormat);
  } catch (error) {
    return null;
  }
}
