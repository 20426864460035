import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';

const Component: FC = () => (
  <StepContent
    confirmLabel="I've finished configuring the Browser SSO Settings
  and clicked 'Next'."
  >
    <Text>
      On the "Browser SSO" page, select the "Configure Browser SSO" button.
    </Text>

    <Img
      className="my-8"
      height={826}
      src="/images/0bc740d8-a692-440a-8e5c-1c931f11e80e.png"
      width={2298}
    />

    <Text>
      On the "SAML Profiles" page, select "SP-initiated SSO" under the "Single
      Sign-On (SSO) Profiles", and then click "Next".
    </Text>

    <Img
      className="my-8"
      height={842}
      src="/images/9c5bb231-dca7-4957-b0bd-eb6208ecf626.png"
      width={2298}
    />

    <Text>Configure the Assertion Lifetime and click "Next".</Text>

    <Img
      className="my-8"
      height={842}
      src="/images/a21cc383-9152-4089-8cc8-33354c771ca4.png"
      width={2298}
    />
  </StepContent>
);

export const configureBrowserSsoSettings: StepData = {
  title: 'Configure Browser SSO Settings',
  component: Component,
};
