import { Button, EmptyState } from '@workos-inc/component-library';
import { FC } from 'react';
import { Server } from 'react-feather';

interface AuditLogsEmptyStateProps {
  onReset: () => void;
}

export const AuditLogsEmptyState: FC<AuditLogsEmptyStateProps> = ({
  onReset: handleReset,
}) => (
  <EmptyState
    icon={
      <Server
        className="mb-4 h-10 w-10 text-gray-lightmode-300 dark:text-gray-darkmode-300"
        size={16}
      />
    }
    title="You don't have any audit log events."
  >
    <Button appearance="secondary" onClick={handleReset}>
      Clear Search Query
    </Button>
  </EmptyState>
);
