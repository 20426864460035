import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';

const Component: FC = () => (
  <StepContent confirmLabel="I've mapped the user attributes.">
    <Text>
      In the Setup search bar browse to the "Manage Connected Apps" portal.
      Click on your application and this will open the view where you can
      configure the attribute mapping, and later on the user profile access
      permissions.
    </Text>

    <Img
      className="my-8"
      height={736}
      src="/images/7ae58ca2-e715-44f3-9742-6bb2f4e4f203.png"
      width={1424}
    />

    <Text>
      Viewing the app, scroll down to the "Custom Attributes" section and select
      "New".
    </Text>
    <Img
      className="my-8"
      height={1274}
      src="/images/56ece4cb-6eec-4886-bd66-2891823e996a.png"
      width={3500}
    />

    <Text>Configure the fields so the mapping matches the following:</Text>

    <Img
      className="my-8"
      height={1268}
      src="/images/de3490a6-4257-49f5-978b-b66ee40ac09d.png"
      width={3478}
    />
  </StepContent>
);

export const mapUserAttributes: StepData = {
  title: 'Map User Attributes',
  component: Component,
};
