import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';

const Component: FC = () => (
  <StepContent confirmLabel="I've completed the feedback form and clicked “Finish”.">
    <Text>
      Select "I'm an Okta customer adding an internal app" from the options
      menu.
    </Text>

    <Img
      className="my-8"
      height={853}
      src="/images/8a6cea2c-c387-477c-af5d-b8261d3a7b57.png"
      width={1457}
    />

    <Text>Complete the form with any comments and select “Finish”.</Text>
  </StepContent>
);

export const submitApplicationFeedback: StepData = {
  title: 'Submit Application Feedback',
  component: Component,
};
