import { unreachable } from '@workos-inc/standard';
import { FC, useCallback } from 'react';
import { Navigate } from 'react-router-dom';
import { DirectoryType } from '../../../../graphql/generated';
import { PageTitle } from '../../../components/with-page-title';
import { useDsyncStore } from '../dsync-store-provider';
import { AzureScimSteps } from '../providers/azure-scim';
import { BambooHrSteps } from '../providers/bamboo-hr';
import { BreatheHrSteps } from '../providers/breathe-hr';
import { CezanneHrSteps } from '../providers/cezanne-hr';
import { CyberArkScimSteps } from '../providers/cyberark-scim';
import { FourthHrSteps } from '../providers/fourth-hr';
import { GenericScimSteps } from '../providers/generic-scim';
import { GoogleWorkspaceSteps } from '../providers/google-workspace';
import { HibobSteps } from '../providers/hibob';
import { JumpCloudScimSteps } from '../providers/jump-cloud-scim';
import { OktaSteps } from '../providers/okta';
import { OneLoginScimSteps } from '../providers/one-login-scim';
import { PeopleHrSteps } from '../providers/people-hr/people-hr-steps';
import { PingFederateSteps } from '../providers/ping-federate';
import { RipplingScimSteps } from '../providers/rippling-scim';
import { WorkdaySteps } from '../providers/workday';
import { getDirectoryName } from '../utils/get-directory-name';
import { getSupportedDirectoryType } from '../utils/get-supported-directory-type';

interface DsyncStepsProps {}

export const DsyncSteps: FC<Readonly<DsyncStepsProps>> = () => {
  const { directory } = useDsyncStore();

  const directoryType = directory
    ? getSupportedDirectoryType(directory)
    : undefined;

  const steps = useCallback(() => {
    if (!directoryType) {
      return null;
    }

    switch (directoryType) {
      case DirectoryType.AzureScimv2_0:
        return <AzureScimSteps />;
      case DirectoryType.BambooHr:
        return <BambooHrSteps />;
      case DirectoryType.BreatheHr:
        return <BreatheHrSteps />;
      case DirectoryType.CezanneHr:
        return <CezanneHrSteps />;
      case DirectoryType.CyberArkScimV2_0:
        return <CyberArkScimSteps />;
      case DirectoryType.FourthHr:
        return <FourthHrSteps />;
      case DirectoryType.GoogleWorkspace:
        return <GoogleWorkspaceSteps />;
      case DirectoryType.Hibob:
        return <HibobSteps />;
      case DirectoryType.JumpCloudScimV2_0:
        return <JumpCloudScimSteps />;
      case DirectoryType.OktaScimv2_0:
        return <OktaSteps />;
      case DirectoryType.OneLoginScimV2_0:
        return <OneLoginScimSteps />;
      case DirectoryType.PeopleHr:
        return <PeopleHrSteps />;
      case DirectoryType.PingFederateScimV2_0:
        return <PingFederateSteps />;
      case DirectoryType.RipplingScimV2_0:
        return <RipplingScimSteps />;
      case DirectoryType.Workday:
        return <WorkdaySteps />;
      case DirectoryType.GenericScimv1_1:
      case DirectoryType.GenericScimv2_0:
        return <GenericScimSteps />;
      default:
        return unreachable(directoryType);
    }
  }, [directoryType]);

  if (!directory) {
    return <Navigate replace to="/dsync" />;
  }

  if (!directoryType) {
    return <Navigate to="/unsupported-provider" />;
  }

  const directoryProviderName = getDirectoryName(directoryType);

  return (
    <PageTitle title={`Configure Directory with ${directoryProviderName}`}>
      {steps()}
    </PageTitle>
  );
};
