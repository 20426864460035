import { InputGroup, Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Card } from '../../../../components/card';
import { Form } from '../../../../components/form';
import { useFormContext } from '../../../../shared/form-data-provider/form-data-provider';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { useStepsStore } from '../../../../shared/step-navigation/steps-store-provider';
import { BreatheHrFormData } from './breathe-hr-steps';

const Component: FC = () => {
  const { handleInputChange, handleNextStep, isUpdating } = useStepsStore();
  const { formData, formErrors } = useFormContext<BreatheHrFormData>();

  return (
    <>
      <Text>Upload the API key you generated in your last step.</Text>

      <Card>
        <Form
          disabled={!formData.breatheHrApiKey}
          isLoading={isUpdating}
          isUpdate={!!formErrors.breatheHrApiKey}
          onSubmit={handleNextStep}
        >
          <InputGroup
            autoFocus
            error={formErrors.breatheHrApiKey?.message}
            id="breatheHrApiKey"
            label="Breathe HR API Key"
            name="breatheHrApiKey"
            onChange={handleInputChange}
            placeholder=""
            type="password"
            value={formData.breatheHrApiKey ?? ''}
          />
        </Form>
      </Card>
    </>
  );
};

export const uploadBreatheHrApiKey: StepData<BreatheHrFormData> = {
  title: 'Upload Breathe HR API Key',
  component: Component,
  formKeys: ['breatheHrApiKey'],
};
