import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { CopyInput } from '../../../../components/inputs';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';
import { useSsoStore } from '../../sso-store-provider';

const Component: FC = () => {
  const { connection } = useSsoStore();
  return (
    <StepContent confirmLabel="I've created a SAML application.">
      <Text>
        Next, create the connection between Cloudflare and the Service Provider.
        From the Cloudflare Zero Trust dashboard Access menu, select
        "Applications", then "Add an application".
      </Text>

      <Img
        className="my-8"
        height={1600}
        src="/images/74b6f749-4289-40bd-ac29-4ab89f011387.png"
        width={2702}
      />

      <Text>Select "SaaS" for the type of application.</Text>

      <Img
        className="my-8"
        height={1808}
        src="/images/691c79b4-9b1c-4f8a-ab45-669b0c3e61c3.png"
        width={2952}
      />

      <CopyInput
        id="saml-acs-url"
        label="Copy this ACS URL"
        value={connection?.saml_acs_url}
      />

      <CopyInput
        id="sp-entity-id"
        label="Copy this SP Entity ID"
        value={connection?.saml_entity_id}
      />

      <Text>
        Select the name of your application you created in step 1 from the
        dropdown menu. If your application is not listed, type the name to save
        it.
      </Text>

      <Text>
        Paste the ACS URL and SP Entity ID to the corresponding fields in
        Cloudflare. Then select the Name ID Format that you would like to use
        for this application. For this example we’ll use Unique ID.
      </Text>

      <Img
        className="my-8"
        height={1958}
        src="/images/1522c566-a0a3-45a6-9c96-39a728267851.png"
        width={2952}
      />
    </StepContent>
  );
};

export const addAnSsoApplicationInCloudflare: StepData = {
  title: 'Add an SSO Application in Cloudflare',
  component: Component,
};
