import { DirectoryType } from '../../../../graphql/generated';
import { ScimDirectoryType } from '../interfaces/supported-directory-type';

const ALL_SCIM_DIRECTORY_TYPES = (() => {
  const scimDirectoryTypes: {
    [P in ScimDirectoryType]: undefined;
  } = {
    [DirectoryType.AzureScimv2_0]: undefined,
    [DirectoryType.CyberArkScimV2_0]: undefined,
    [DirectoryType.GenericScimv1_1]: undefined,
    [DirectoryType.GenericScimv2_0]: undefined,
    [DirectoryType.JumpCloudScimV2_0]: undefined,
    [DirectoryType.OktaScimv1_1]: undefined,
    [DirectoryType.OktaScimv2_0]: undefined,
    [DirectoryType.OneLoginScimV2_0]: undefined,
    [DirectoryType.PingFederateScimV2_0]: undefined,
    [DirectoryType.RipplingScimV2_0]: undefined,
  };

  return new Set(Object.keys(scimDirectoryTypes));
})();

export const isScimDirectory = (
  directoryType: DirectoryType,
): directoryType is ScimDirectoryType =>
  ALL_SCIM_DIRECTORY_TYPES.has(directoryType);
