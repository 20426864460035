import { Text } from '@workos-inc/component-library';
import { DirectoryStepProps } from '../../../interfaces/step-props';
import { Confirm } from '../../confirm';
import { Img } from '../../image';
import { CopyInput } from '../../input';
import { Link } from '../../link';
import { MapAttributesStep } from './map-attributes-step';
import { ArrayStepsConfig } from './steps-config';

export const stepsJumpCloud: ArrayStepsConfig = {
  providerLabel: 'JumpCloud SCIM v2',
  type: 'Array',
  steps: [
    {
      name: 'Select JumpCloud Application',
      // eslint-disable-next-line react/display-name
      render: ({ onNextStep: handleNextStep }: DirectoryStepProps) => (
        <>
          <Text>
            Log in to the JumpCloud admin dashboard, select "SSO" on the left
            and select your Application.
          </Text>

          <Img
            className="my-8"
            height={3996}
            src="/images/c1dbceee-1b3e-4c53-be26-bc5514e6fe1f.png"
            width={8124}
          />

          <Text>
            If you haven't created an application, you'll need to first create a
            custom SAML application in JumpCloud. JumpCloud only supports
            configuring SCIM provisioning in an existing SAML application. Make
            sure to create your SAML application before moving on to SCIM
            provisioning.
          </Text>

          <Confirm
            label="I’ve selected my JumpCloud application."
            onClick={handleNextStep}
          />
        </>
      ),
    },
    {
      name: 'Configure JumpCloud SCIM',
      // eslint-disable-next-line react/display-name
      render: ({
        directory,
        onNextStep: handleNextStep,
      }: DirectoryStepProps) => (
        <>
          <Text>
            Select "Identity Management" from the top navigation menu.
          </Text>

          <Img
            className="my-8"
            height={2560}
            src="/images/29f389d8-0ccb-4402-8d75-0a1ba135051d.png"
            width={4732}
          />

          <Text>
            Scroll down to the "Configuration settings" section. Make sure SCIM
            2.0 is selected as the SCIM version.
          </Text>

          <Text>Copy and paste the endpoint into the "Base URL" field.</Text>

          <CopyInput label="Copy this Endpoint" value={directory?.endpoint} />

          <Text>
            Copy and paste the Bearer Token into the "Token Key" field.
          </Text>

          <CopyInput
            label="Copy this Bearer Token"
            type="password"
            value={directory?.bearerToken}
          />

          <Text>
            Next, test the connection to confirm the configuration settings.
          </Text>

          <Img
            className="my-8"
            height={1039}
            src="/images/b5761f11-54d4-41ef-99ab-26318a0c44a6.png"
            width={1920}
          />

          <Text>
            After you receive a success message for the configuration, make sure
            the Group Management toggle is "On", and then activate the settings.
          </Text>

          <Img
            className="my-8"
            height={1039}
            src="/images/8e35cb63-a808-43fc-bd45-398d87c9726c.png"
            width={1920}
          />

          <Text>
            After the activation step is successful, save the configuration.
          </Text>

          <Img
            className="my-8"
            height={1039}
            src="/images/63c38760-e960-4554-8f07-33c27e1ca375.png"
            width={1920}
          />

          <Confirm
            label="I’ve confirmed, activated and saved the configuration settings."
            onClick={handleNextStep}
          />
        </>
      ),
    },
    {
      name: 'Assign User Groups to your Application',
      // eslint-disable-next-line react/display-name
      render: ({ onNextStep: handleNextStep }: DirectoryStepProps) => (
        <>
          <Text>
            In order for your users and groups to be synced, you will need to
            assign them to your JumpCloud Application. Select "User Groups" from
            the top navigation menu. Select the groups of users you would like
            to sync and save your changes.
          </Text>

          <Img
            className="my-8"
            height={948}
            src="/images/739a3ff5-8f91-4c27-8f07-3fc3ea4f9b5a.png"
            width={1686}
          />

          <Confirm
            label="I’ve completed my assignments."
            onClick={handleNextStep}
          />
        </>
      ),
    },
    {
      name: 'Set up Attribute Mapping',
      isCustomAttributeMapping: true,
      // eslint-disable-next-line react/display-name
      render: (directoryStepProps: DirectoryStepProps) => (
        <MapAttributesStep {...directoryStepProps}>
          <Text>
            There are additional attributes needed by the application for it to
            fully function. Note that basic attributes like a user's name or
            email are already mapped, and so are not present below.
          </Text>

          <Text>
            JumpCloud offers the following list of{' '}
            <Link
              newTab
              appearance="highlight"
              href={
                'https://support.jumpcloud.com/support/s/article/Custom-SCIM-Identity-Management#attributemappings'
              }
            >
              default attribute mappings
            </Link>{' '}
            in their documentation.
          </Text>

          <Text>
            Map attributes from your Directory Provider's responses to the
            attributes shown below.
          </Text>
        </MapAttributesStep>
      ),
    },
  ],
};
