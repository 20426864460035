import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';
import { ConnectionAttributeMapping } from '../../attribute-mapping';
import { useSsoStore } from '../../sso-store-provider';

const Component: FC = () => {
  const { connection } = useSsoStore();

  return (
    <StepContent
      confirmLabel={
        'I\'ve finished configuring the Attribute Statements and clicked "Continue to Next Step".'
      }
    >
      <Text>
        On the "SSO Attribute Mapping" step, click on the "Add new attribute"
        button to add new attributes to map.
      </Text>

      <Img
        height={1392}
        src="/images/3aa894ca-786f-47a5-b019-6ddbae47b0f6.png"
        width={2056}
      />

      <Text>
        Fill in the following Attribute Statements and click “Continue to Next
        Step”:
      </Text>

      <ConnectionAttributeMapping connectionType={connection?.type} />
    </StepContent>
  );
};

export const configureAttributeStatements: StepData = {
  title: 'Configure Attribute Statements',
  component: Component,
};
