import {
  Alert,
  Button,
  Dialog,
  SamlX509CertificatePill,
  Text,
} from '@workos-inc/component-library';
import { unreachable } from '@workos-inc/standard';
import { FC, FormEvent, useState } from 'react';
import { RefreshCcw } from 'react-feather';
import { SamlX509CertificateValidationError } from '../../../../../../components/sso/connection/saml-x509-certificate-validation-error';
import { useToast } from '../../../../../../utils/toast-context';
import { FileField } from '../../../../../components/fields';
import { graphql } from '../../../../../utils/graphql';
import { logError } from '../../../../../utils/logger';
import { useSsoStore } from '../../../sso-store-provider';

interface ConnectionMetadataXmlProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onIsManualChange: (isManual: boolean) => void;
}

export const ConnectionMetadataXml: FC<
  Readonly<ConnectionMetadataXmlProps>
> = ({ open, onOpenChange: handleOpenChange, onIsManualChange }) => {
  const { showToast } = useToast();
  const { connection, setSsoStore } = useSsoStore();

  const [metadataXmlFile, setMetadataXmlFile] = useState<string | undefined>();

  const [isUpdatingConnectionMetadata, setIsUpdatingConnectionMetadata] =
    useState(false);
  const [connectionUpdateError, setConnectionUpdateError] =
    useState<JSX.Element>();

  const handleUpdateConnection = async (event: FormEvent) => {
    event.preventDefault();
    if (!metadataXmlFile) {
      return;
    }

    try {
      setIsUpdatingConnectionMetadata(true);

      const response = await graphql().UpdateConnectionFromMetadataXml({
        input: {
          connectionId: connection.id,
          metadataXml: metadataXmlFile,
        },
      });

      const result = response.data?.portal_updateConnectionFromMetadataXml;

      switch (result?.__typename) {
        case 'Portal_ConnectionUpdatedFromMetadataXml': {
          const { connection: updatedConnection } = result;

          handleOpenChange(false);
          setConnectionUpdateError(undefined);

          showToast({
            title: `${updatedConnection.name} connection updated`,
            description:
              'Your changes have been successfully applied to the connection.',
          });

          void setSsoStore({ connection: updatedConnection });
          break;
        }
        case 'ConnectionNotFound': {
          setConnectionUpdateError(
            <Text as="p">
              The connection {result.connectionId} could not be found.
            </Text>,
          );

          break;
        }
        case 'Portal_MetadataParseFailed': {
          setConnectionUpdateError(<Text as="p">{result.reason}</Text>);

          break;
        }
        case 'InvalidSamlX509Certificate': {
          setConnectionUpdateError(
            <SamlX509CertificateValidationError error={result} />,
          );

          break;
        }
        case undefined:
          break;
        default:
          return unreachable(result);
      }
    } catch (error) {
      logError(error);

      setConnectionUpdateError(<>The XML File is not valid.</>);
    } finally {
      setIsUpdatingConnectionMetadata(false);
    }
  };

  return (
    <Dialog
      acceptButtonProps={{
        type: 'submit',
        form: 'xmlConnectionMetadataForm',
      }}
      acceptText="Save Metadata Configuration"
      isAcceptDisabled={!metadataXmlFile}
      isLoading={isUpdatingConnectionMetadata}
      onOpenChange={handleOpenChange}
      open={open}
      title="XML File Metadata Configuration"
      titleSuffix={
        <Button
          appearance="secondary"
          iconLeft={<RefreshCcw size={16} />}
          onClick={() => onIsManualChange(true)}
          size="small"
        >
          Switch to Manual Configuration
        </Button>
      }
    >
      <form id="xmlConnectionMetadataForm" onSubmit={handleUpdateConnection}>
        {(connection.samlX509Certificates.length ?? 0) > 0 && (
          <div className="mb-4 flex flex-col gap-2">
            <Text as="p" size="small">
              Active Metadata X.509 Certificates
            </Text>

            {connection.samlX509Certificates.map((samlX509Certificate) => (
              <SamlX509CertificatePill
                key={samlX509Certificate.id}
                {...samlX509Certificate}
              />
            ))}
          </div>
        )}

        <FileField
          accept=".xml"
          id="saml_idp_metadata_xml"
          label="XML Metadata File"
          name="saml_idp_metadata_xml"
          onUpload={({ file }) => {
            setMetadataXmlFile(file.content);
          }}
          value={metadataXmlFile}
        />

        {connectionUpdateError && (
          <Alert appearance="red" className="mt-6">
            <Text inheritColor as="p">
              {connectionUpdateError}
            </Text>
          </Alert>
        )}
      </form>
    </Dialog>
  );
};
