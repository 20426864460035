import { InputGroup, Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Card } from '../../../../components/card';
import { Form } from '../../../../components/form';
import { useFormContext } from '../../../../shared/form-data-provider/form-data-provider';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { useStepsStore } from '../../../../shared/step-navigation/steps-store-provider';
import { FourthHrFormData } from './fourth-hr-steps';

const Component: FC = () => {
  const { handleInputChange, handleNextStep, isUpdating } = useStepsStore();
  const { formData, formErrors } = useFormContext<FourthHrFormData>();

  return (
    <>
      <Text>
        Upload your Fourth HR Organization ID, Username, and Password. Please
        speak to your nominated Fourth HR contact to retrieve your credentials.
      </Text>

      <Card>
        <Form
          disabled={
            !formData.fourthHrOrganizationId ||
            !formData.fourthHrUsername ||
            !formData.fourthHrPassword
          }
          isLoading={isUpdating}
          isUpdate={
            !!(
              formErrors.fourthHrOrganizationId ||
              formErrors.fourthHrUsername ||
              formErrors.fourthHrPassword
            )
          }
          onSubmit={handleNextStep}
        >
          <InputGroup
            autoFocus
            error={formErrors.fourthHrOrganizationId?.message}
            id="fourthHrOrganizationId"
            label="1. Fourth HR Organization ID"
            name="fourthHrOrganizationId"
            onChange={handleInputChange}
            placeholder=""
            value={formData.fourthHrOrganizationId ?? ''}
          />

          <InputGroup
            error={formErrors.fourthHrUsername?.message}
            id="fourthHrUsername"
            label="2. Fourth HR Username"
            name="fourthHrUsername"
            onChange={handleInputChange}
            placeholder=""
            value={formData.fourthHrUsername ?? ''}
          />

          <InputGroup
            error={formErrors.fourthHrPassword?.message}
            id="fourthHrPassword"
            label="3. Fourth HR Password"
            name="fourthHrPassword"
            onChange={handleInputChange}
            placeholder=""
            type="password"
            value={formData.fourthHrPassword ?? ''}
          />
        </Form>
      </Card>
    </>
  );
};

export const uploadFourthHrOrganizationIdUsernameAndPassword: StepData<FourthHrFormData> =
  {
    title: 'Upload Fourth HR Organization ID, Username and Password',
    component: Component,
    formKeys: [
      'fourthHrOrganizationId',
      'fourthHrUsername',
      'fourthHrPassword',
    ],
  };
