import { Alert, Text } from '@workos-inc/component-library';
import { unreachable } from '@workos-inc/standard';
import { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  connectionSessionDeserializer,
  ConnectionSessionFragment,
} from '../../../../../graphql/deserializers/connection-session-deserializer';
import {
  OAuthSessionState,
  OidcSessionState,
  SamlSessionState,
} from '../../../../../graphql/generated';
import { graphql } from '../../../../utils/graphql';

export interface RecentEventFlashMessageProps {}

export const RecentEventFlashMessage: FC<
  Readonly<RecentEventFlashMessageProps>
> = () => {
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get('session');

  const [recentEvent, setRecentEvent] = useState<
    ConnectionSessionFragment | undefined
  >();

  useEffect(() => {
    const loadConnectionSessions = async (): Promise<void> => {
      if (sessionId) {
        const connectionSessionResponse = await graphql().ConnectionSession({
          id: sessionId,
        });

        if (connectionSessionResponse?.data) {
          const { portal_connectionSession: connectionSession } =
            connectionSessionResponse?.data;
          setRecentEvent(connectionSessionDeserializer(connectionSession));
        }
      }
    };

    void loadConnectionSessions();
  }, [sessionId]);

  if (!recentEvent) {
    return null;
  }

  switch (recentEvent.state) {
    case OAuthSessionState.Authorized:
    case OAuthSessionState.Successful:
    case OidcSessionState.Authorized:
    case OidcSessionState.Successful:
    case SamlSessionState.Authorized:
    case SamlSessionState.Successful:
      return (
        <Alert appearance="red">
          <Text inheritColor as="p">
            Your Single Sign-On test was successful. Your connection is ready
            for use.
          </Text>
        </Alert>
      );
    case OAuthSessionState.Failed:
    case OidcSessionState.Failed:
    case SamlSessionState.Failed:
      return (
        <Alert appearance="red">
          <Text inheritColor as="p">
            There was a problem with your Single Sign-On test. Learn more about
            how to resolve this issue.
          </Text>
        </Alert>
      );
    case OAuthSessionState.Started:
    case OidcSessionState.Started:
    case SamlSessionState.Started:
      return null;
    default:
      unreachable(recentEvent);
  }
};
