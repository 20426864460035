import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { usePortalSession } from '../../../../components/portal-session-provider';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';

const Component: FC = () => {
  const { appName } = usePortalSession();

  return (
    <StepContent confirmLabel="I’ve completed my assignments.">
      <Text>
        In order for your users and groups of users to be synced to {appName}{' '}
        you will need to assign them to your Azure AD SAML Application. Select
        "Users and groups" from the "Manage" section of the navigation menu.
      </Text>

      <Img
        className="my-8"
        height={1292}
        src="/images/7fe69c75-311e-409b-905a-dbd6b6087394.png"
        width={2230}
      />

      <Text>Select "Add user/group" from the top menu.</Text>

      <Img
        className="my-8"
        height={1260}
        src="/images/817e4d3d-7470-4e20-b9f1-af74ea8adc73.png"
        width={2222}
      />

      <Text>
        Select "None selected" under the "Users and Groups". In the menu, select
        the users and groups of users that you want to add to the SAML
        application, and click "Select".
      </Text>

      <Img
        className="my-8"
        height={1280}
        src="/images/54841bd3-33b9-451b-a5ab-dd94c0040034.png"
        width={2312}
      />

      <Text>
        Select "Assign" to add the selected users and groups of users to your
        SAML application.
      </Text>

      <Img
        className="my-8"
        height={1198}
        src="/images/5420b145-6086-4141-b096-9ae9f6ea8529.png"
        width={1696}
      />
    </StepContent>
  );
};

export const assignPeopleAndGroups: StepData = {
  title: 'Assign People & Groups',
  component: Component,
};
