import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Ol } from '../../../../../components/typography';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { metadataXmlFormKeys } from '../../constants/metadata-form-keys';
import { MetadataXmlFormFields } from '../../interfaces/metadata-form-fields';
import { MetadataXmlStep } from '../../metadata-step/metadata-xml-step';

const Component: FC = () => (
  <MetadataXmlStep
    idpEntityIdPlaceholder="https://app.onelogin.com/saml/metadata/..."
    idpSsoUrlPlaceholder="https://foocorp.onelogin.com/trust/saml2/http-post/sso/..."
  >
    <Ol className="my-4">
      <li>Click the “SSO” tab from the left sidebar.</li>

      <li>
        Click the "More Actions" dropdown and then select "SAML Metadata".
      </li>

      <li>An XML file will download.</li>
    </Ol>

    <Img
      className="my-8"
      height={779}
      src="/images/55c7cbb9-fbda-4ab9-932e-25a551ee16c5.png"
      width={1408}
    />

    <Text>Upload the XML Metadata file below.</Text>
  </MetadataXmlStep>
);

export const uploadOneLoginMetadataFile: StepData<MetadataXmlFormFields> = {
  title: 'Upload OneLogin Metadata File',
  component: Component,
  formKeys: metadataXmlFormKeys,
};
