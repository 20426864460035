import { Label, RadioGroup, Text } from '@workos-inc/component-library';
import classnames from 'classnames';
import { FC } from 'react';
import { Activity, Crosshair, User } from 'react-feather';
import { DataSet } from './types';

interface DatasetPickerProps {
  filters: [string, string][];
  dataset: DataSet;
  onDatasetChange: (dataset: DataSet) => void;
}

export const DatasetPicker: FC<DatasetPickerProps> = ({
  filters,
  dataset,
  onDatasetChange,
}) => (
  <div>
    <Text as="p" className="mb-2 leading-none" multiline={false} size="small">
      Dataset
    </Text>
    <RadioGroup
      className="flex flex-col gap-2"
      onValueChange={(value: DataSet) => onDatasetChange(value)}
      value={dataset}
    >
      <Label
        className={classnames(
          'flex cursor-default gap-2',
          filters.length === 0 && 'opacity-50',
        )}
        htmlFor={DataSet.Filtered}
      >
        <RadioGroup.Item
          className="mt-0.5 shrink-0"
          disabled={filters.length === 0}
          id={DataSet.Filtered}
          value={DataSet.Filtered}
        />
        <div
          className="flex flex-col overflow-hidden"
          data-testid="dataset-picker-filtered-events-container"
        >
          <span>Filtered events</span>
          {filters.length > 0 && (
            <div
              className={classnames(
                'mt-1 flex gap-x-2',
                dataset !== DataSet.Filtered && 'opacity-50',
              )}
            >
              {filters.map(([key, value]) => (
                <FilterItem key={key} id={key} value={value} />
              ))}
            </div>
          )}
        </div>
      </Label>

      <Label className="flex cursor-default gap-2" htmlFor={DataSet.All}>
        <RadioGroup.Item
          className="mt-0.5"
          id={DataSet.All}
          value={DataSet.All}
        />
        <span>All events</span>
      </Label>
    </RadioGroup>
  </div>
);

const FilterIcons = {
  actor: User,
  event: Activity,
  target: Crosshair,
};

interface FilterItemProps {
  id: string;
  value: string;
}

const FilterItem = ({ id, value }: FilterItemProps) => {
  const Icon = FilterIcons[id as keyof typeof FilterIcons];
  return (
    // eslint-disable-next-line max-len
    <div className="grid h-6 w-fit grid-cols-[repeat(3,max-content)] items-center gap-x-1 rounded bg-[#e6f7ff] px-1 text-sm leading-none text-[#006adb] focus-within:shadow-[inset_0_0_0_1px_rgba(0,0,0,0.05)] dark:bg-[#2A3949] dark:text-[#A9D2FF]">
      {Icon && <Icon className="opacity-50" size={14} />}
      <span>{id}:</span>
      <span>{value}</span>
    </div>
  );
};
