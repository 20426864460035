import { Button } from '@workos-inc/component-library';
import classnames from 'classnames';
import { motion } from 'framer-motion';
import { FC, useState } from 'react';
import { PageLayout } from '../../../../components/page-layout';
import { graphql } from '../../../../utils/graphql';

interface IdentityProviderRequestFormProps {
  onCloseRequestForm: () => void;
}

export const IdentityProviderRequestForm: FC<
  Readonly<IdentityProviderRequestFormProps>
> = ({ onCloseRequestForm: handleCloseRequestForm }) => {
  const [message, setMessage] = useState('');

  const [hasSentIdentityProviderRequest, setHasSentIdentityProviderRequest] =
    useState(false);

  const handleRequestIdentityProvider = async (): Promise<void> => {
    await graphql().RequestIdentityProvider({
      identityProvider: message,
      pageRequestedFrom: 'Main Connection Selector',
    });

    setHasSentIdentityProviderRequest(true);
  };

  if (hasSentIdentityProviderRequest) {
    return (
      <div className="flex flex-col items-center">
        <PageLayout
          description="Our team has been notified."
          navTitle="Configure Single Sign-On"
          title="Thank you for your request."
        >
          <motion.div
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            transition={{ delay: 0.1 }}
          >
            <Button
              onClick={() => {
                setHasSentIdentityProviderRequest(false);
                handleCloseRequestForm();
              }}
            >
              Select an available provider
            </Button>
          </motion.div>
        </PageLayout>
      </div>
    );
  }

  return (
    <PageLayout
      description="Which identity provider are you using?"
      navTitle="Configure Single Sign-On"
      title="Identity Provider Configuration"
    >
      <div className="flex w-full max-w-lg flex-col items-center">
        <motion.div
          animate={{ opacity: 1 }}
          className="flex w-full flex-col items-center"
          initial={{ opacity: 0 }}
          transition={{ delay: 0.1 }}
        >
          <textarea
            className={classnames(
              'h-28 w-full resize-none rounded border p-3 text-sm outline-none transition duration-100 ease-in-out',
              'border-gray-lightmode-200 bg-white text-gray-lightmode-400 placeholder:text-gray-lightmode-300',
            )}
            data-testid="feedback-textarea"
            id="feedback"
            name="feedback"
            onChange={(event) => setMessage(event.target.value)}
            placeholder="Tell us more about your identity provider and your single sign-on needs."
            value={message}
          />

          <Button
            className="mt-4 self-end"
            disabled={!message}
            onClick={handleRequestIdentityProvider}
          >
            Submit
          </Button>
        </motion.div>

        <motion.div
          animate={{ opacity: 1, scale: 1 }}
          initial={{ opacity: 0, scale: 0.5 }}
          transition={{ delay: 0.2 }}
        >
          <Button
            appearance="minimal"
            className="mt-16"
            onClick={handleCloseRequestForm}
          >
            Back to providers
          </Button>
        </motion.div>
      </div>
    </PageLayout>
  );
};
