import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { usePortalSession } from '../../../../components/portal-session-provider';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';

const Component: FC = () => {
  const { appName } = usePortalSession();

  return (
    <StepContent confirmLabel="I’ve selected a SAML Enterprise Application">
      <Text>
        Log in to the OneLogin Admin Dashboard and select "Applications" in the
        navigation bar.
      </Text>

      <Img
        className="my-8"
        height={1142}
        src="/images/3eb4dd3a-b114-4af0-b8fb-bde51324b739.png"
        width={2250}
      />

      <Text>Click "Add App" on the Applications page.</Text>

      <Img
        className="my-8"
        height={1142}
        src="/images/39fb7a8d-8dc0-4aca-8883-90bdb2bdaa26.png"
        width={2250}
      />

      <Text>
        Search and select the app that you're connecting with from the App list.
      </Text>

      <Img
        className="my-8"
        height={1142}
        src="/images/9934f2f4-b7c6-455f-9ea7-cdfa4e5e214c.png"
        width={2250}
      />

      <Text>
        Pick a descriptive name for your app, such as {appName}, then click
        "Save" to complete adding the app to your application list.
      </Text>

      <Img
        className="my-8"
        height={1452}
        src="/images/643fcfc2-ddfb-4c73-b288-c2c1412a101a.png"
        width={2250}
      />
    </StepContent>
  );
};

export const addASamlApplication: StepData = {
  title: 'Add the SAML Application',
  component: Component,
};
