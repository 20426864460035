import { getNewDirectoryErrorCode } from '../../../../utils/get-error-code';

export function parseDirectoryCreationError(
  error: unknown,
  domain: string | undefined,
) {
  const errorCode = getNewDirectoryErrorCode(error);
  const message =
    errorCode === 'duplicate_domain'
      ? `A directory for "${domain}" already exists.`
      : undefined;
  return { message, errorCode };
}
