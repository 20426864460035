import { unreachable } from '@workos-inc/standard';
import { ConnectionType } from '../../../../graphql/generated';

export enum ConnectionProtocol {
  Saml = 'SAML',
  Oidc = 'OIDC',
  OAuth = 'OAuth',
  MagicLink = 'MagicLink',
}

export const getConnectionProtocol = (
  connectionType: ConnectionType,
): ConnectionProtocol => {
  switch (connectionType) {
    case ConnectionType.Adfssaml:
    case ConnectionType.Auth0Saml:
    case ConnectionType.AzureSaml:
    case ConnectionType.CasSaml:
    case ConnectionType.CloudflareSaml:
    case ConnectionType.ClassLinkSaml:
    case ConnectionType.CyberArkSaml:
    case ConnectionType.DuoSaml:
    case ConnectionType.GenericSaml:
    case ConnectionType.GoogleSaml:
    case ConnectionType.JumpCloudSaml:
    case ConnectionType.KeycloakSaml:
    case ConnectionType.LastPassSaml:
    case ConnectionType.MiniOrangeSaml:
    case ConnectionType.NetIqSaml:
    case ConnectionType.OktaSaml:
    case ConnectionType.OneLoginSaml:
    case ConnectionType.OracleSaml:
    case ConnectionType.PingFederateSaml:
    case ConnectionType.PingOneSaml:
    case ConnectionType.RipplingSaml:
    case ConnectionType.SalesforceSaml:
    case ConnectionType.ShibbolethGenericSaml:
    case ConnectionType.ShibbolethSaml:
    case ConnectionType.SimpleSamlPhpSaml:
    case ConnectionType.VMwareSaml:
      return ConnectionProtocol.Saml;

    case ConnectionType.AdpOidc:
    case ConnectionType.GenericOidc:
      return ConnectionProtocol.Oidc;

    case ConnectionType.GoogleOAuth:
    case ConnectionType.MicrosoftOAuth:
      return ConnectionProtocol.OAuth;

    case ConnectionType.MagicLink:
      return ConnectionProtocol.MagicLink;

    default:
      unreachable(connectionType);
  }
};
