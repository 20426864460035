import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { CopyInput } from '../../../../components/inputs';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';
import { useSsoStore } from '../../sso-store-provider';

const Component: FC = () => {
  const { connection } = useSsoStore();
  return (
    <StepContent confirmLabel="I've created a SAML Client">
      <Text>
        Log in to your Keycloak Admin Console, and navigate to the Realm you
        want to set up the SAML client in. Select "Clients" from the side menu
        and then select "Create".
      </Text>

      <Img
        className="my-8"
        height={1670}
        src="/images/258416f7-e05d-4935-b46d-23ac64599b7b.png"
        width={2122}
      />

      <Text>
        Copy the Client ID and Client SAML Endpoint below and input them on the
        Add Client setup page.
      </Text>

      <CopyInput
        id="saml-entity-id"
        label="Copy this Client ID"
        value={connection.saml_entity_id}
      />

      <CopyInput
        id="saml-acs-url"
        label="Copy this Client SAML Endpoint"
        value={connection.saml_acs_url}
      />

      <Img
        className="my-8"
        height={1352}
        src="/images/18b4f365-aefc-467a-8cbb-8b61b277de81.png"
        width={2158}
      />
    </StepContent>
  );
};

export const createSamlClient: StepData = {
  title: 'Create SAML Client',
  component: Component,
};
