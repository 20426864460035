import { Screenshot, Zoomable } from '@workos-inc/component-library';
import NextImage, { ImageProps } from 'next/image';
import { forwardRef } from 'react';

type BaseProps = Pick<ImageProps, 'className' | 'onLoad'>;

// Component-library uses Slot to forward ref, but NextImage doesn't support it
// we don't need the ref in there and this is a workaround while we don't use the most recent Screenshot from "design-system"
const Image = forwardRef<HTMLImageElement, ImageProps>(
  (props, _forwardedRef) => <NextImage {...props} />,
);

Image.displayName = 'Image';

export interface ImgProps extends BaseProps {
  width: number;
  height: number;
  src: string;
}

const shimmer = (width: number, height: number) => `
<svg width="${width}" height="${height}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <defs>
    <linearGradient id="g">
      <stop stop-color="#eee" offset="20%" />
      <stop stop-color="#f2f2f2" offset="50%" />
      <stop stop-color="#eee" offset="70%" />
    </linearGradient>
  </defs>
  <rect width="${width}" height="${height}" fill="#eee" />
  <rect id="r" width="${width}" height="${height}" fill="url(#g)" />
  <animate xlink:href="#r" attributeName="x" from="-${width}" to="${width}" dur="1s" repeatCount="indefinite"  />
</svg>`;

const toBase64 = (str: string) =>
  typeof window === 'undefined'
    ? Buffer.from(str).toString('base64')
    : window.btoa(str);

export const Img = forwardRef<HTMLDivElement, Readonly<ImgProps>>(
  ({ className, src, height, width, onLoad: handleLoad }, forwardedRef) => (
    <Zoomable className={className} expandedHeight={1200} expandedWidth={1200}>
      <Screenshot ref={forwardedRef}>
        <Screenshot.Background>
          <Screenshot.Image asChild>
            <Image
              blurDataURL={`data:image/svg+xml;base64,${toBase64(
                shimmer(6, 6),
              )}`}
              height={height}
              onLoad={handleLoad}
              placeholder="blur"
              src={src}
              width={width}
            />
          </Screenshot.Image>
        </Screenshot.Background>
      </Screenshot>
    </Zoomable>
  ),
);

Img.displayName = 'Img';
