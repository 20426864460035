import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';
import { ConnectionAttributeMapping } from '../../attribute-mapping';
import { useSsoStore } from '../../sso-store-provider';

const Component: FC = () => {
  const { connection } = useSsoStore();

  return (
    <StepContent
      confirmLabel="I’ve finished configuring the Attribute Statements
  and clicked “Save”."
    >
      <Text>
        Select the "SAML Response" tab and use the "Add" button to add the
        following key-value pairs. Then, click "Save".
      </Text>

      <Img
        className="my-8"
        height={812}
        src="/images/dfa45599-be42-4201-a6b7-26e94b4dc23e.png"
        width={1451}
      />

      <ConnectionAttributeMapping connectionType={connection.type} />
    </StepContent>
  );
};

export const configureAttributeStatements: StepData = {
  title: 'Configure Attribute Statements',
  component: Component,
};
