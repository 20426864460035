import { InputGroup, Text } from '@workos-inc/component-library';
import { Card } from '../../../components/card';
import { FileField } from '../../../components/fields';
import { Form } from '../../../components/form';
import { useFormContext } from '../../../shared/form-data-provider/form-data-provider';
import { useStepsStore } from '../../../shared/step-navigation/steps-store-provider';
import { SsoFormMetadata } from '../interfaces/sso-form-metadata';

export interface ManualMetadataStepProps {
  onConfigurationTypeChange: (configurationType: 'dynamic') => void;
  idpEntityIdPlaceholder: string;
  idpSsoUrlPlaceholder: string;
}

export const ManualMetadataStep = <TFormData extends SsoFormMetadata>({
  idpEntityIdPlaceholder,
  idpSsoUrlPlaceholder,
  onConfigurationTypeChange,
}: ManualMetadataStepProps) => {
  const { handleInputChange, handleNextStep, isUpdating } = useStepsStore();
  const { formData, setFormData, formErrors } = useFormContext<TFormData>();
  return (
    <div>
      <Text as="h2" size="large" weight="medium">
        Manual Configuration
      </Text>

      <Text>
        See your Identity Provider’s documentation on how to configure a Service
        Provider.
      </Text>

      <Card>
        <Form
          disabled={
            !formData?.saml_idp_url ||
            !formData?.saml_x509_certificates?.[0] ||
            formErrors.saml_idp_url?.value === formData?.saml_idp_url
          }
          isLoading={isUpdating}
          isUpdate={
            !!(
              formErrors?.saml_idp_url ||
              formErrors?.saml_x509_certificates?.message
            )
          }
          onSubmit={handleNextStep}
          secondaryAction={() => onConfigurationTypeChange('dynamic')}
          secondaryText="Configure Dynamically"
        >
          <InputGroup
            error={formErrors?.saml_idp_url?.message || ''}
            id="saml_idp_url"
            label="1. Identity Provider Single Sign-On URL"
            name="saml_idp_url"
            onChange={handleInputChange}
            placeholder={idpSsoUrlPlaceholder}
            value={formData?.saml_idp_url || ''}
          />

          <InputGroup
            error={formErrors?.saml_idp_entity_id?.message || ''}
            id="saml_idp_entity_id"
            label="2. Identity Provider Issuer"
            name="saml_idp_entity_id"
            onChange={handleInputChange}
            placeholder={idpEntityIdPlaceholder}
            value={formData.saml_idp_entity_id || ''}
          />

          <FileField
            error={formErrors?.saml_x509_certificates}
            id="saml-x509-certificates"
            label="3. X.509 Certificate"
            name="saml_x509_certificates"
            onUpload={({ file }) => {
              setFormData({
                ...formData,
                saml_x509_certificates: [file.content],
              });
            }}
            value={formData?.saml_x509_certificates?.[0]}
          />
        </Form>
      </Card>
    </div>
  );
};
