import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Card } from '../../../../components/card';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';

const CERTIFICATE_FORMAT = `-----BEGIN CERTIFICATE-----
<PUBLIC KEY VALUE>==
-----END CERTIFICATE-----`;

const CERTIFICATE_COMPLETE = `-----BEGIN CERTIFICATE-----
MIIDUTCCAjmgAwIBAgIRAN557boQ2ZxW4Ww08cZYK2IwDQYJKoZIhvcNAQELBQAw
YjELMAkGA1UEBhMCVVMxDjAMxxxxxAgTBVRleGFzMQ8wDQYDVQQHEwZBdXN0aW4x
EzARBgNVBAoTCkNsb3VkZmxhcmUxHTAbBgNVBAMTFGNsb3VkZmxhcmVhY2Nlc3Mu
Y29tMB4XDTxxxxxwMjE5MzMxM1oXDTMyMDIwMjE5MzMxM1owYjELMAkGA1UEBhMC
VVMxDjAMBgNVBAgTBVRleGFzMQ8wDQYDVQQHEwZBdXN0aW4xEzARBgNVBAoTCkNs
b3VkZmxhcmUxHTAbBgNVBAMTFGNsb3VkZmxxxxxhY2Nlc3MuY29tMIIBIjANBgkq
hkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA49p6jXzk65DeG4DI2NgW0UOOJrd+9qYS
OCuBYq/e4IqSeqchsm1JDY9MjB6xmiw+urC1qWuj0MS4dwAJQwiGFbCGDh5m4FAF
mZm5VaMkW5Q+MG5zXRfeLmhvLuT0XVBcDlkwPC3k28/moKi1KlwPcujLF43+rf2d
8Rm6ZNCJgfVzRxxxxxPd5NGpNlEZ0ViPXM1gsO15/1Iginevv+xKqRTx0vMsNLWJ
BwWLAAqm5b6U9XQefwy9lPqPywFwCuZEMXwI9Rpm0f2xmOK56EudtdSkQ1JtSgYX
x9rf/97NfP8wI2x1IncQtwdWNdW5cvxMqYU/Za6WZvjNCnpFQGXLJQIDAQABowIw
ADANBgkqhkiG9w0BAQsFAAOCAQEARZ0h2ZeNXSme0EbQeJfEFOX+mj9rPkHIJFfQ
G7+dRG6DwDubxG56TsvUINcJX8O5C6oQ0T6dRutO/jG5LxJqmCz5wLUTA/6/YLDk
95gbYyJ/yfLm4sd6DEoXzWSld+EZ5b86pxFnvR/+cPY2tcSghQ+moZKR5THwHLsZ
hie2Pr6UVvuS5D9BC4ijR+cPyB5r4qliI9C1p8phuZctoX9dPpFY+UwkWgUDx9sz
UXFJsqueoibxfVqh4Jzdw+2XH6xN3WvTdJN4Sh1fqEpBeOxxxxxlRrCAJiMnLtG6
QgHF9ZnNRbIFcUHF/lyWY3oxcvgeUwEnE5QVVbdoMMGKKgffbQ==
-----END CERTIFICATE-----`;

const Component: FC = () => (
  <StepContent confirmLabel="I've formatted the Public Key as an X.509 Certificate.">
    <Text>
      Cloudflare provides a Public Key value that needs to be formatted as an
      X.509 Certificate.
    </Text>

    <Text>
      To format the Public Key, copy the value to a text editor and add the
      following header and footer, along with two equal characters at the end of
      the Public Key, "{'=='}". Ensure there are no spaces above or below the
      Key value, then save with the file extension ".cert".
    </Text>

    <Card>
      <pre>
        <code>{CERTIFICATE_FORMAT}</code>
      </pre>
    </Card>

    <Text>
      The file should look like the below when you're finished. Note that there
      are two equal characters, {'=='}, appended to the end of the Public Key.
      These must be added manually.
    </Text>

    <Card>
      <pre>
        <code>{CERTIFICATE_COMPLETE}</code>
      </pre>
    </Card>

    <Text>
      Save the file with a <code>.cert</code> extension. This <code>.cert</code>{' '}
      file will be uploaded in the next step.
    </Text>
  </StepContent>
);

export const formatTheX509Certificate: StepData = {
  title: 'Format the X.509 Certificate',
  component: Component,
};
