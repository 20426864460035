import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { metadataUrlFormKeys } from '../../constants/metadata-form-keys';
import { MetadataUrlFormFields } from '../../interfaces/metadata-form-fields';
import { MetadataUrlStep } from '../../metadata-step';

const Component: FC = () => (
  <MetadataUrlStep
    idpEntityIdPlaceholder="https://auth.workos.com/AbC123..."
    idpSsoUrlPlaceholder="https://mycompany.us.auth0.com/samlp/AbC123..."
    metadataUrlPlaceholder="https://"
  >
    <Text>
      The final step for implementing SAML SSO requires sharing your identity
      provider's metadata with the application.
    </Text>

    <Text as="h2" size="large" weight="medium">
      URL Metadata Configuration
    </Text>

    <Text>
      On the application's Settings page, scroll down to the bottom and expand
      the "Advanced Settings" section. Select the "Endpoints" tab and copy the
      SAML Metadata URL and enter it in the field below.
    </Text>

    <Img
      className="my-8"
      height={955}
      src="/images/5e1c43ed-6df9-4a7b-98ae-ce3130d263b1.png"
      width={1524}
    />
  </MetadataUrlStep>
);

export const uploadIdentityProviderMetadata: StepData<MetadataUrlFormFields> = {
  title: 'Upload Identity Provider Metadata',
  component: Component,
  formKeys: metadataUrlFormKeys,
};
