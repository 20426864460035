import { InputGroup, Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Card } from '../../../../components/card';
import { Form } from '../../../../components/form';
import { Img } from '../../../../components/image';
import { useFormContext } from '../../../../shared/form-data-provider';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { useStepsStore } from '../../../../shared/step-navigation/steps-store-provider';
import { SsoFormMetadata } from '../../interfaces/sso-form-metadata';
import { AdpOidcFormFields } from './adp-oidc-steps';

const Component: FC = () => {
  const { formData, formErrors } = useFormContext<SsoFormMetadata>();

  const { handleInputChange, handleNextStep, isUpdating } = useStepsStore();

  return (
    <>
      <Text>
        Next, under the same tab used in the previous step, you'll provide the
        Client ID and Client Secret from ADP.
      </Text>

      <Img
        className="my-8"
        height={807}
        src="/images/cfeda6c7-62d4-4f6b-bc91-c88bb2b3e61f.png"
        width={1267}
      />

      <Text>
        Simply click to reveal the secret and copy and paste both the client ID
        and client secret into the fields below.
      </Text>

      <Card>
        <Form
          disabled={!formData.oidc_client_id || !formData.oidc_client_secret}
          isLoading={isUpdating}
          isUpdate={
            !!(formErrors.oidc_client_id || formErrors.oidc_client_secret)
          }
          onSubmit={handleNextStep}
        >
          <InputGroup
            autoFocus
            error={formErrors.oidc_client_id?.message}
            id="oidc_client_id"
            label="Client ID"
            name="oidc_client_id"
            onChange={handleInputChange}
            value={formData.oidc_client_id ?? ''}
          />

          <InputGroup
            error={formErrors.oidc_client_secret?.message}
            id="oidc_client_secret"
            label="Client Secret"
            name="oidc_client_secret"
            onChange={handleInputChange}
            value={formData.oidc_client_secret ?? ''}
          />
        </Form>
      </Card>
    </>
  );
};

export const provideTheClientIdAndSecretFromAdp: StepData<AdpOidcFormFields> = {
  title: 'Provide the Client ID and Secret from ADP',
  component: Component,
  formKeys: ['oidc_client_id', 'oidc_client_secret'],
};
