import { GraphQLResponse } from 'graphql-request/dist/types';
import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import * as React from 'react';
import {
  DirectoryCustomAttributeFragment,
  DirectoryFragment,
  UpdateDirectoryMutation,
  UpdateDirectoryMutationVariables,
} from '../../../../graphql/generated';
import { LoadingScreen } from '../../../components/loading-screen';
import {
  RecordStateDispatcher,
  useRecordState,
} from '../../../hooks/use-record-state';
import { graphql } from '../../../utils/graphql';
import { logError } from '../../../utils/logger';

export interface DsyncStoreProviderProps {
  children?: React.ReactNode;
  initialDirectory?: DirectoryFragment;
  initialDirectoryCustomAttributes?: DirectoryCustomAttributeFragment[];
}

type DsyncStore = {
  directory: DirectoryFragment;
  directoryCustomAttributes: DirectoryCustomAttributeFragment[];
};

type DsyncStoreContext = DsyncStore & {
  setDsyncStore: RecordStateDispatcher<DsyncStore>;
  updateDirectory: (
    args: UpdateDirectoryMutationVariables,
  ) => Promise<GraphQLResponse<UpdateDirectoryMutation> | undefined>;
};

const DsyncContext = createContext<DsyncStoreContext | null>(null);

export const useDsyncStore = () => {
  const context = useContext(DsyncContext);

  if (!context) {
    throw new Error('useDsyncStore can only be used within DsyncContext');
  }

  return context;
};

export const DsyncStoreProvider: FC<Readonly<DsyncStoreProviderProps>> = ({
  children,
  initialDirectory,
  initialDirectoryCustomAttributes,
}) => {
  const [dsyncStore, setDsyncStore] = useRecordState<DsyncStore>({
    directory: initialDirectory,
    directoryCustomAttributes: initialDirectoryCustomAttributes || [],
  });

  const [loadingDirectory, setLoadingDirectory] = useState(!initialDirectory);
  const [
    loadingDirectoryCustomAttributes,
    setLoadingDirectoryCustomAttributes,
  ] = useState(false);

  useEffect(() => {
    const loadDirectory = async () => {
      const { data } = await graphql().Directories();

      const [directory] =
        data?.directories.data.filter(
          (directory) => directory.__typename === 'portal_Directory',
        ) ?? [];

      if (directory && directory.__typename === 'portal_Directory') {
        setDsyncStore({
          directory,
        });
      }

      if (data) {
        setLoadingDirectory(false);
      }
    };

    if (!initialDirectory) {
      void loadDirectory();
    }
  }, [initialDirectory, setDsyncStore]);

  useEffect(() => {
    const loadDirectoryCustomAttributes = async () => {
      setLoadingDirectoryCustomAttributes(true);

      const attributesResponse = await graphql().DirectoryCustomAttributes({
        directoryId: dsyncStore.directory.id,
      });
      if (attributesResponse.data) {
        const attributes =
          attributesResponse.data.portal_directoryCustomAttributes.data;
        setDsyncStore({
          directoryCustomAttributes: attributes,
        });
      }

      setLoadingDirectoryCustomAttributes(false);
    };

    if (!initialDirectoryCustomAttributes && dsyncStore.directory?.id) {
      void loadDirectoryCustomAttributes();
    }
  }, [
    dsyncStore.directory?.id,
    initialDirectoryCustomAttributes,
    setDsyncStore,
  ]);

  const updateDirectory = useCallback(
    async (
      args: UpdateDirectoryMutationVariables,
    ): Promise<GraphQLResponse<UpdateDirectoryMutation> | undefined> => {
      let response;

      try {
        response = await graphql().UpdateDirectory(args);
      } catch (error) {
        logError(error);
      }

      if (response?.data?.portal_updateDirectory) {
        setDsyncStore({ directory: response.data.portal_updateDirectory });
      }

      return response;
    },
    [setDsyncStore],
  );

  const context = useMemo<DsyncStoreContext>(
    () => ({ ...dsyncStore, setDsyncStore, updateDirectory }),
    [dsyncStore, setDsyncStore, updateDirectory],
  );

  if (loadingDirectory || loadingDirectoryCustomAttributes) {
    return <LoadingScreen />;
  }

  return (
    <DsyncContext.Provider value={context}>{children}</DsyncContext.Provider>
  );
};
