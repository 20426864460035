import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { CopyInput } from '../../../../components/inputs';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';
import { useSsoStore } from '../../sso-store-provider';

const Component: FC = () => {
  const { connection } = useSsoStore();

  return (
    <StepContent confirmLabel="I’ve configured the SP Entity ID / Issuer / Audience and ACS URL.">
      <Text>
        Log in to the CyberArk Identity Admin Portal and select "Web Apps" from
        the left-side navigation.
      </Text>

      <Img
        className="my-8"
        height={1688}
        src="/images/0b6cc08c-4396-4690-b6ec-bfdd19482407.png"
        width={2946}
      />

      <Text>
        Select "Add Web Apps" to begin creating a new SAML application.
      </Text>

      <Img
        className="my-8"
        height={1688}
        src="/images/dbdefe19-2a7e-40fe-9946-d72e14fc5a0e.png"
        width={2946}
      />

      <Text>Select the "Custom" tab and then click to add "SAML".</Text>

      <Img
        className="my-8"
        height={813}
        src="/images/a3f19885-982a-4909-bc4b-7171dc91379d.png"
        width={1440}
      />

      <Text>Select "Yes" to begin setting up the SAML App.</Text>

      <Img
        className="my-8"
        height={813}
        src="/images/26b4f311-11c6-492d-a047-8b5cb7208473.png"
        width={1440}
      />

      <Text>
        Enter a descriptive App Name and description, then click "Save".
      </Text>

      <Img
        className="my-8"
        height={883}
        src="/images/fbd41606-77cf-4183-91f6-d779b95c073c.png"
        width={1461}
      />

      <CopyInput
        id="saml-acs-url"
        label="Copy this ACS URL"
        value={connection.saml_acs_url}
      />

      <Text>
        On the "Trust" tab of the SAML App, go to the "Service Provider
        Configuration Section" and select "Manual Configuration". Input the ACS
        URL above as both the "SP Entity Id / Issuer / Audience" and "Assertion
        Consumer Service (ACS) URL".
      </Text>

      <Text>
        IMPORTANT: Be sure to check "Both" under "Sign Response or Assertion?"
      </Text>

      <Img
        className="my-8"
        height={812}
        src="/images/c6fa4b05-b31d-4ae5-9d5b-6ee8a7d01fa9.png"
        width={1451}
      />
    </StepContent>
  );
};

export const createSamlIntegration: StepData = {
  title: 'Create SAML Integration',
  component: Component,
};
