import { EventName } from './constants';
import { graphql } from './graphql';

export const trackEvent = (
  event_name: EventName,
  attributes?: Record<string, unknown>,
): void => {
  void graphql().TrackEvent({
    event_name,
    attributes,
  });
};
