import { Card, Select, Text } from '@workos-inc/component-library';
import { unreachable } from '@workos-inc/standard';
import classNames from 'classnames';
import { FC, useEffect, useMemo, useState } from 'react';
import { ConnectionSessionFragment } from '../../../../../../graphql/deserializers/connection-session-deserializer';
import {
  OAuthSessionState,
  OidcSessionState,
  SamlSessionState,
} from '../../../../../../graphql/generated';
import { usePortalSession } from '../../../../../components/portal-session-provider';
import { graphql } from '../../../../../utils/graphql';
import { useSsoStore } from '../../../sso-store-provider';
import { getConnectionName } from '../../../utils/get-connection-name';
import {
  ConnectionProtocol,
  getConnectionProtocol,
} from '../../../utils/get-connection-protocol';
import { getEvents } from '../../../utils/get-events';
import { ConnectionErrors } from '../connection-errors';
import { ConnectionRecentEventsTable } from './connection-recent-events-table';

interface ConnectionRecentEventsProps {}

const ALL_STATES = 'All States';

const generateStateOptions = (connectionType: ConnectionProtocol): string[] => {
  const stateOptionsEnum = (() => {
    switch (connectionType) {
      case ConnectionProtocol.Saml:
        return SamlSessionState;
      case ConnectionProtocol.OAuth:
        return OAuthSessionState;
      case ConnectionProtocol.Oidc:
        return OidcSessionState;
      case ConnectionProtocol.MagicLink:
        return {};
      default:
        return unreachable(connectionType);
    }
  })();

  return Object.keys(stateOptionsEnum).reduce(
    (options, value) => [...options, value],
    [ALL_STATES],
  );
};

const getOptionColor = (state: string) => {
  switch (state) {
    case 'Authorized':
      return 'bg-blue-darken';
    case 'Failed':
      return 'bg-red-darken';
    case 'Started':
      return 'bg-yellow';
    case 'Successful':
      return 'bg-green-darken';
    default:
      return 'bg-gray-lightmode-300';
  }
};

const shouldRenderEvents = (protocol: ConnectionProtocol): boolean => {
  switch (protocol) {
    case ConnectionProtocol.Saml:
    case ConnectionProtocol.OAuth:
    case ConnectionProtocol.Oidc:
      return true;
    case ConnectionProtocol.MagicLink:
      return false;
    default:
      return unreachable(protocol);
  }
};

export const ConnectionRecentEvents: FC<
  Readonly<ConnectionRecentEventsProps>
> = () => {
  const { connection } = useSsoStore();
  const { appName } = usePortalSession();

  const [events, setEvents] = useState<ConnectionSessionFragment[]>([]);
  const [filteredState, setFilteredState] = useState<string>('All States');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadEvents = async () => {
      const connectionType = getConnectionProtocol(connection.type);

      const stateFilter =
        filteredState !== 'All States' ? filteredState : undefined;

      const response = await graphql().ConnectionSessions({
        samlSessionState:
          connectionType === ConnectionProtocol.Saml
            ? (stateFilter as SamlSessionState)
            : undefined,

        oauthSessionState:
          connectionType === ConnectionProtocol.OAuth
            ? (stateFilter as OAuthSessionState)
            : undefined,

        oidcSessionState:
          connectionType === ConnectionProtocol.Oidc
            ? (stateFilter as OidcSessionState)
            : undefined,
      });

      if (response?.data) {
        const connectionData = response.data.portal_connections.data[0];

        if (connectionData?.__typename === 'portal_Connection') {
          setEvents(getEvents(connectionData, connectionType));
        }
      }

      setIsLoading(false);
    };

    void loadEvents();
  }, [connection.type, filteredState]);

  const connectionType = getConnectionProtocol(connection.type);

  // Only update the actual displayed state once sessions are updated.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const displayedState = useMemo(() => filteredState, [events]);
  const options = generateStateOptions(connectionType);

  const noFilterStateSelected = displayedState === ALL_STATES;

  if (!shouldRenderEvents(connectionType) || isLoading) {
    return null;
  }

  return (
    <>
      {events[0] && (
        <ConnectionErrors
          appName={appName}
          connection={connection}
          errors={events[0].errors}
        />
      )}
      <Card>
        <Card.Header className="flex items-center justify-between">
          <Card.Title>Recent Events</Card.Title>

          <div>
            <Select
              name="state-filter"
              onValueChange={(value) => setFilteredState(value)}
              value={filteredState || 'placeholder'}
            >
              <Select.Trigger id="state-filter" />

              <Select.Content>
                <Select.Item disabled value="placeholder">
                  <Select.ItemText>Select a state</Select.ItemText>
                </Select.Item>

                {options.map((option) => (
                  <Select.Item key={option} value={option}>
                    <div className="flex items-center gap-2">
                      <div
                        className={classNames(
                          'rounded-full w-[8px] h-[8px]',
                          getOptionColor(option),
                        )}
                      />
                      <Select.ItemText>{option}</Select.ItemText>
                    </div>
                    <Select.ItemIndicator />
                  </Select.Item>
                ))}
              </Select.Content>
            </Select>
          </div>
        </Card.Header>

        <Card.Body className="p-0 pt-4">
          {events.length > 0 ? (
            <ConnectionRecentEventsTable events={events} />
          ) : (
            <div className="flex flex-col items-center p-8 text-center">
              <Text as="h3" size="large" weight="medium">
                No {!noFilterStateSelected && displayedState} Connection Events
              </Text>

              {noFilterStateSelected && (
                <Text
                  className="mt-4"
                  data-testid="empty-state-with-no-filter-selected"
                >
                  This table will display events when users begin authenticating
                  with your {getConnectionName(connection.type)} connection.
                </Text>
              )}
            </div>
          )}
        </Card.Body>
      </Card>
    </>
  );
};
