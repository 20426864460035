import { unreachable } from '@workos-inc/standard';
import { useCallback, useState } from 'react';
import {
  ConnectionFragment,
  ConnectionType,
} from '../../../../graphql/generated';
import { usePortalSession } from '../../../components/portal-session-provider';
import { ProviderSelectionState } from '../../../components/provider-selection';
import { graphql } from '../../../utils/graphql';
import { RequestState } from '../interfaces/request-state';
import { useSsoStore } from '../sso-store-provider';

export const useCreateConnection = () => {
  const { organization, untypedConnection } = usePortalSession();
  const { setSsoStore } = useSsoStore();

  const [creationState, setCreationState] = useState<RequestState>({
    type: 'idle',
  });

  const createConnection = useCallback(
    async (
      providerData: ProviderSelectionState<ConnectionType>,
    ): Promise<void> => {
      if (creationState.type !== 'idle') {
        return;
      }

      const { selectedProvider } = providerData;

      if (!selectedProvider) {
        return;
      }

      setCreationState({ type: 'waiting' });

      let connection: ConnectionFragment | undefined;

      try {
        if (untypedConnection) {
          const { data } = await graphql().CreateConnectionFromUntyped({
            input: {
              connectionId: untypedConnection.id,
              type: selectedProvider,
            },
          });

          const result = data?.portal_createConnectionFromUntyped;

          switch (result?.__typename) {
            case 'Portal_ConnectionCreated': {
              connection = result.connection;
              break;
            }
            case 'ConnectionNotFound': {
              throw new Error(`Connection '${result.connectionId}' not found.`);
            }
            case 'ConnectionIsAlreadyTyped': {
              throw new Error(
                `Connection '${result.connectionId}' already has a type.`,
              );
            }
            default:
              return unreachable(result);
          }
        } else {
          const { data } = await graphql().CreateConnection({
            name: organization?.name || '',
            type: selectedProvider,
          });

          connection = data?.portal_createConnection;

          setSsoStore({ connection });
          setCreationState({ type: 'success' });
        }
      } catch (error) {
        setCreationState({
          type: 'failed',
          value: {
            error,
          },
        });
      }
    },
    [creationState, organization?.name, setSsoStore, untypedConnection],
  );

  return [createConnection, creationState] as const;
};
