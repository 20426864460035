import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';

const Component: FC = () => (
  <StepContent confirmLabel="I’ve created a SAML Enterprise Application">
    <Text>
      In the OneLogin platform, go to the Admin Dashboard and select the
      "Applications" tab in the navigation bar.
    </Text>

    <Img
      className="my-8"
      height={1734}
      src="/images/3b7bc5e9-0486-4e22-96b8-c080869239b8.png"
      width={2816}
    />

    <Text>
      Select your application from the list of applications. If creating a new
      application, select the "SAML Custom Connector (Advanced)" type and
      continue.
    </Text>

    <Img
      className="my-8"
      height={638}
      src="/images/6d83bddd-b75c-4ef0-ac9d-a3c8c681d3c0.png"
      width={1464}
    />
  </StepContent>
);

export const createASamlApplication: StepData = {
  title: 'Create a SAML Application',
  component: Component,
};
