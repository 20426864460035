import { Button, Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { ArrowLeft } from 'react-feather';
import { useSearchParams } from 'react-router-dom';

interface ExpiredProps {}

export const Expired: FC<Readonly<ExpiredProps>> = () => {
  const [searchParams] = useSearchParams();

  const returnUrl = searchParams.get('return_url');

  return (
    <div className="mt-28 flex flex-col items-center">
      <Text as="h1" className="mb-4" size="xxlarge" weight="medium">
        Your session expired.
      </Text>

      <Text className="mb-6">
        Your portal session expired. Head back to the application to create a
        new one.
      </Text>

      {returnUrl && (
        <Button
          appearance="minimal"
          iconLeft={<ArrowLeft size={16} />}
          onClick={() => {
            if (returnUrl) {
              window.location.href = returnUrl;
            }
          }}
        >
          Go back
        </Button>
      )}
    </div>
  );
};
