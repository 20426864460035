import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';

const Component: FC = () => (
  <StepContent confirmLabel="I’ve completed user provisioning.">
    <Text>
      In order for your users and groups to be synced, you will need to assign
      them to your OneLogin Application. Select "Users" from the top navigation
      menu.
    </Text>

    <Text>
      Next, find a user you'd like to provision to the SCIM app. Within that
      user profile, select the "Applications" tab on the left. Then, click the
      "+" symbol.
    </Text>

    <Img
      className="my-8"
      height={1210}
      src="/images/ee1e01fa-26f3-49e1-8515-3fedfbdee14b.png"
      width={2358}
    />

    <Text>Select the appropriate app and hit "Continue".</Text>

    <Img
      className="my-8"
      height={1210}
      src="/images/f00d45d9-7128-43dd-8bdb-cc48e7eebf7b.png"
      width={2358}
    />

    <Text>Select "Save" in the next modal to confirm the change.</Text>

    <Img
      className="my-8"
      height={1764}
      src="/images/e9e5ef85-5efb-4b71-8283-e8d570f3103e.png"
      width={2478}
    />
  </StepContent>
);

export const assignUsersToYourApplication: StepData = {
  title: 'Assign Users to your Application',
  component: Component,
};
