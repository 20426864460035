import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { ExternalLink } from '../../../../components/link';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { metadataUrlFormKeys } from '../../constants/metadata-form-keys';
import { MetadataUrlFormFields } from '../../interfaces/metadata-form-fields';
import { MetadataUrlStep } from '../../metadata-step';

const Component: FC = () => (
  <MetadataUrlStep
    idpEntityIdPlaceholder="https://mysimplesamlphpserver.com/abc123..."
    idpSsoUrlPlaceholder="https://mysimplesamlphpserver.com/..."
    metadataUrlPlaceholder="https://"
  >
    <Text>
      Obtain the IdP Metadata URL. As noted in the{' '}
      <ExternalLink
        newTab
        appearance="highlight"
        href="https://simplesamlphp.org/docs/latest/simplesamlphp-idp.html"
      >
        "Adding this IdP to other SPs" section of the SimpleSAMLphp
        documentation
      </ExternalLink>
      , the IdP metadata URL should be available from /saml2/idp/metadata.php
    </Text>

    <Text>
      Alternatively, you can manually configure the connection by providing the
      IdP URI (Entity ID), IdP SSO URL and X.509 Certificate.
    </Text>

    <Text as="h2" size="large" weight="medium">
      URL Metadata Configuration
    </Text>
  </MetadataUrlStep>
);

export const uploadIdentityProviderMetadata: StepData<MetadataUrlFormFields> = {
  title: 'Upload Identity Provider Metadata',
  component: Component,
  formKeys: metadataUrlFormKeys,
};
