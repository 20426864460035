import { Button } from '@workos-inc/component-library';
import classnames from 'classnames';
import { FC } from 'react';

export interface PaginationProps {
  className?: string;
  hasNext?: boolean;
  hasPrevious?: boolean;
  onNextPage: () => void;
  onPreviousPage: () => void;
}

export const Pagination: FC<Readonly<PaginationProps>> = ({
  className,
  hasNext = true,
  hasPrevious = true,
  onPreviousPage: handlePreviousPage,
  onNextPage: handleNextPage,
}) => {
  if (!hasNext && !hasPrevious) {
    return null;
  }

  return (
    <nav
      className={classnames(
        'flex items-center justify-between border-t py-3 px-4 sm:px-6',
        'border-gray-lightmode-200 bg-white',
        'dark:border-gray-darkmode-200 dark:bg-gray-darkmode-50',
        className,
      )}
    >
      <div className="hidden sm:block" />
      <div className="flex flex-1 justify-between sm:justify-end">
        <Button
          appearance="minimal"
          disabled={!hasPrevious}
          onClick={handlePreviousPage}
          size="small"
        >
          Previous
        </Button>
        <Button
          appearance="minimal"
          className="ml-3"
          disabled={!hasNext}
          onClick={handleNextPage}
          size="small"
        >
          Next
        </Button>
      </div>
    </nav>
  );
};
