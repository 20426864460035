import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Topnav } from '../../../components/topnav';
import { AuditLogEvent } from '../pages/audit-log-event';
import { AuditLogEvents } from '../pages/audit-log-events';

interface AuditLogsRouterProps {}

export const AuditLogsRouter: FC<Readonly<AuditLogsRouterProps>> = () => (
  <>
    <Topnav />

    <Routes>
      <Route element={<Navigate to="/audit-logs/events" />} path="/" />
      <Route element={<AuditLogEvent />} path="/events/:eventId" />
      <Route element={<AuditLogEvents />} path="/events" />
    </Routes>
  </>
);
