import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { usePortalSession } from '../../../../components/portal-session-provider';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';

const Component: FC = () => {
  const { appName } = usePortalSession();

  return (
    <StepContent confirmLabel="I’ve created my Okta application.">
      <Text>
        From your Okta Application dashboard, select "Browse App Catalog".
      </Text>

      <Img
        className="my-8"
        height={800}
        src="/images/8e00419f-9859-438e-8858-fe900287834f.png"
        width={1596}
      />

      <Text>
        Next, search for "SCIM 2.0 Test App (Oauth Bearer Token)" and select the
        corresponding result.
      </Text>

      <Img
        className="my-8"
        height={717}
        src="/images/f9c70752-9685-4476-8795-c01164dbd6fd.png"
        width={1587}
      />

      <Text>On the following page, click "Add Integration".</Text>

      <Img
        className="my-8"
        height={743}
        src="/images/6ea231e0-7df1-4c4d-a0b4-cc74f09f0044.png"
        width={1583}
      />

      <Text>
        Give your application a descriptive name such as{' '}
        <strong>{appName}</strong> and click Next.
      </Text>

      <Img
        className="my-8"
        height={743}
        src="/images/9da066fe-3e89-4050-adcb-2c4b65ece7c6.png"
        width={1583}
      />

      <Text>
        Many applications will work with the default configuration that is set
        on your new application. If you require any additional configuration for
        your directory such as configuring Attribute Statements, do so on the
        Sign-On Options page. Click "Done" to complete creating your
        application.
      </Text>

      <Img
        className="my-8"
        height={1023}
        src="/images/df731580-8eb0-40d0-8f64-51e72978b77e.png"
        width={1596}
      />
    </StepContent>
  );
};

export const createOktaApplication: StepData = {
  title: 'Create Okta Application',
  component: Component,
};
