import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';

const Component: FC = () => (
  <StepContent confirmLabel="I’ve completed my assignments.">
    <Text>
      In order for your users and groups to be synced, you will need to assign
      them to your JumpCloud Application. Select "User Groups" from the top
      navigation menu. Select the groups of users you would like to sync and
      save your changes.
    </Text>

    <Img
      className="my-8"
      height={948}
      src="/images/739a3ff5-8f91-4c27-8f07-3fc3ea4f9b5a.png"
      width={1686}
    />
  </StepContent>
);

export const assignUserGroupsToYourApplication: StepData = {
  title: 'Assign User Groups to your Application',
  component: Component,
};
