import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { metadataXmlFormKeys } from '../../constants/metadata-form-keys';
import { MetadataXmlFormFields } from '../../interfaces/metadata-form-fields';
import { MetadataXmlStep } from '../../metadata-step';

const Component: FC = () => (
  <MetadataXmlStep
    idpEntityIdPlaceholder="https://identity.lastpass.com/..."
    idpSsoUrlPlaceholder="https://identity.lastpass.com/SAML/..."
  >
    <Text as="h2" size="large" weight="medium">
      Metadata File Configuration
    </Text>

    <Text>
      Back on the "SSO apps" tab of the LastPass admin console, select the SAML
      app that you just created.
    </Text>

    <Img
      height={1516}
      src="/images/99a9a771-02bc-4817-b576-414bafa2d6f2.png"
      width={2622}
    />

    <Text>
      On the "Configure app" modal, click on "Expand" to the right of "Set up
      app".
    </Text>

    <Img
      height={1512}
      src="/images/e717af8d-f5aa-4629-ae38-d5d0376d57dd.png"
      width={2270}
    />

    <Text>
      At the bottom of the "Set up app" section, click on "Download metadata
      (XML)". Upload this file below.
    </Text>

    <Img
      height={1726}
      src="/images/bbd5f4bc-8386-4650-9559-f072b265d71b.png"
      width={2604}
    />
  </MetadataXmlStep>
);

export const uploadIdentityProviderMetadata: StepData<MetadataXmlFormFields> = {
  title: 'Upload Identity Provider Metadata',
  component: Component,
  formKeys: metadataXmlFormKeys,
};
