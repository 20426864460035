import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { CopyInput } from '../../../../components/inputs';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';
import { useDsyncStore } from '../../dsync-store-provider';

const Component: FC = () => {
  const { directory } = useDsyncStore();

  return (
    <StepContent confirmLabel="I’ve confirmed, I've entered the SCIM Base URL and set the SCIM settings.">
      <Text>Set the SCIM version to 2.0.</Text>

      <Text>Copy and paste the endpoint into the "SCIM Base URL" field.</Text>

      <CopyInput
        id="directory-endpoint"
        label="Copy this Endpoint"
        value={directory.endpoint}
      />

      <Img
        className="my-8"
        height={1109}
        src="/images/1aae60cf-49dc-4514-8ac3-b745d14a3094.png"
        width={1402}
      />

      <Text>
        Set the SCIM authorization method to "Bearer Token". Check off features
        for groups, pagination, delete groups and PATCH groups.
      </Text>

      <Img
        className="my-8"
        height={1109}
        src="/images/23d3979e-f1bd-45ad-bef4-4398ec81425b.png"
        width={1402}
      />

      <Text>
        Add SCIM attributes externalId, emails.primary, name.givenName,
        name.familyName.
      </Text>

      <Img
        className="my-8"
        height={1090}
        src="/images/16762d45-8db5-4a51-946a-ef479ee19a6e.png"
        width={1510}
      />
    </StepContent>
  );
};

export const configureRipplingScimApp: StepData = {
  title: 'Configure Rippling SCIM App',
  component: Component,
};
