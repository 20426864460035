import { FC } from 'react';
import { StepData } from '../../../../shared/step-navigation';
import { useSsoStepRoutes } from '../../hooks/use-sso-step-routes';
import { MetadataUrlFormFields } from '../../interfaces/metadata-form-fields';
import { SsoStepNavigation } from '../../sso-step-navigation';
import { generateSsoStepData } from '../../utils/generate-sso-step-data';
import { createAnSsoConfigurationInDuo } from './1-create-an-sso-configuration-in-duo';
import { createACloudApplicationInDuo } from './2-create-a-cloud-application-in-duo';
import { provideTheEntityIdToDuo } from './3-provide-the-entity-id-to-duo';
import { uploadMetadataFromDuo } from './4-upload-metadata-from-duo';
import { enterTheAcsUrlInDuo } from './5-enter-the-acs-url-in-duo';
import { configureSamlResponseSettingsInDuo } from './6-configure-saml-response-settings-in-duo';
import { configureTheSamlClaims } from './7-configure-the-saml-claims';

interface DuoSamlStepsProps {}

const stepsData: StepData<MetadataUrlFormFields>[] = generateSsoStepData([
  createAnSsoConfigurationInDuo,
  createACloudApplicationInDuo,
  provideTheEntityIdToDuo,
  uploadMetadataFromDuo,
  enterTheAcsUrlInDuo,
  configureSamlResponseSettingsInDuo,
  configureTheSamlClaims,
]);

export const DuoSamlSteps: FC<Readonly<DuoSamlStepsProps>> = () => {
  const stepRoutes = useSsoStepRoutes(stepsData);

  return (
    <SsoStepNavigation stepsData={stepsData}>{stepRoutes}</SsoStepNavigation>
  );
};
