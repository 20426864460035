import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { CopyInput } from '../../../../components/inputs';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';
import { useSsoStore } from '../../sso-store-provider';

const Component: FC = () => {
  const { connection } = useSsoStore();

  return (
    <StepContent
      confirmLabel={
        'I\'ve configured the Assertion Consumer Service (ACS) and Entity ID and clicked "Continue to Next Step".'
      }
    >
      <CopyInput
        id="acs-url"
        label="Copy this ACS URL"
        value={connection?.saml_acs_url}
      />

      <CopyInput
        id="entity-id"
        label="Copy this Entity ID"
        value={connection?.saml_entity_id}
      />

      <Text>
        Submit ACS URL as the "Assertion Consumer Service (ACS)" and the Entity
        ID as the "Entity ID".
      </Text>

      <Img
        height={1938}
        src="/images/66903d7d-0206-4a8f-b880-83ba1a020f80.png"
        width={2146}
      />

      <Text>Click "Continue to Next Step".</Text>
    </StepContent>
  );
};

export const submitAcsUrlAndEntityId: StepData = {
  title: 'Submit ACS URL and Entity ID',
  component: Component,
};
