import { FC } from 'react';
import { StepData } from '../../../../shared/step-navigation';
import { useSsoStepRoutes } from '../../hooks/use-sso-step-routes';
import { SsoStepNavigation } from '../../sso-step-navigation';
import { generateSsoStepData } from '../../utils/generate-sso-step-data';
import { addASamlApplication } from './1-add-a-saml-application';
import { configureCustomerIdInOneLogin } from './2-configure-customer-id-in-onelogin';
import { uploadX509Certificate } from './3-upload-x509-certificate';
import { provideSaml20Endpoint } from './4-provide-saml-2-0-endpoint';

interface OneLoginSamlOacStepsProps {}

export type OneLoginSamlOacFormFields = {
  saml_x509_certificates: string[];
  saml_idp_url: string;
};

const stepsData: StepData<OneLoginSamlOacFormFields>[] = generateSsoStepData([
  addASamlApplication,
  configureCustomerIdInOneLogin,
  uploadX509Certificate,
  provideSaml20Endpoint,
]);

export const OneLoginSamlOacSteps: FC<
  Readonly<OneLoginSamlOacStepsProps>
> = () => {
  const stepRoutes = useSsoStepRoutes(stepsData);

  return (
    <SsoStepNavigation stepsData={stepsData}>{stepRoutes}</SsoStepNavigation>
  );
};
