import { FC } from 'react';
import { StepData } from '../../../../shared/step-navigation';
import { useSsoStepRoutes } from '../../hooks/use-sso-step-routes';
import { MetadataUrlFormFields } from '../../interfaces/metadata-form-fields';
import { SsoStepNavigation } from '../../sso-step-navigation';
import { generateSsoStepData } from '../../utils/generate-sso-step-data';
import { createSamlClient } from './1-create-saml-client';
import { samlClientConfiguration } from './2-saml-client-configuration';
import { mapUserAttributes } from './3-map-user-attributes';
import { uploadIdpMetadata } from './4-upload-idp-metadata';

interface KeycloakSamlStepsProps {}

const stepsData: StepData<MetadataUrlFormFields>[] = generateSsoStepData([
  createSamlClient,
  samlClientConfiguration,
  mapUserAttributes,
  uploadIdpMetadata,
]);

export const KeycloakSamlSteps: FC<Readonly<KeycloakSamlStepsProps>> = () => {
  const stepRoutes = useSsoStepRoutes(stepsData);

  return (
    <SsoStepNavigation stepsData={stepsData}>{stepRoutes}</SsoStepNavigation>
  );
};
