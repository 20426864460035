import {
  ConnectionFragment,
  UpdateConnectionFromMetadataXmlMutation,
} from '../../../../../../graphql/generated';
import { graphql } from '../../../../../utils/graphql';
import { SsoFormMetadata } from '../../../interfaces/sso-form-metadata';

export const completeConnectionFromMetadataXml = async (
  formData: SsoFormMetadata,
  connection: ConnectionFragment,
): Promise<
  UpdateConnectionFromMetadataXmlMutation['portal_updateConnectionFromMetadataXml']
> => {
  if (!formData?.saml_idp_metadata_xml) {
    throw new Error(
      `saml_idp_metadata_xml can't be ${typeof formData?.saml_idp_metadata_xml}`,
    );
  }

  const response = await graphql().UpdateConnectionFromMetadataXml({
    input: {
      connectionId: connection.id,
      metadataXml: formData.saml_idp_metadata_xml,
    },
  });

  return response.data?.portal_updateConnectionFromMetadataXml;
};
