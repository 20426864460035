import { Button, Pill, Text } from '@workos-inc/component-library';
import formatDistance from 'date-fns/formatDistance';
import { motion } from 'framer-motion';
import { FC, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { PageLayout } from '../../../../components/page-layout';
import { usePortalSession } from '../../../../components/portal-session-provider';
import {
  ProviderSelection,
  useProviderSelection,
} from '../../../../components/provider-selection';
import { SsoProviderLabels } from '../../../../utils/constants';
import { useCreateConnection } from '../../hooks/use-create-connection';
import { useEnabledConnections } from '../../hooks/use-enabled-connections';
import { supportedConnectionTypes } from '../../interfaces/supported-connection-type';
import { IdentityProviderRequestForm } from './identity-provider-request-form';

interface SsoProviderSelectionPageProps {}

export const SsoProviderSelectionPage: FC<
  Readonly<SsoProviderSelectionPageProps>
> = () => {
  const { appName, setupLink } = usePortalSession();
  const [createConnection, creationState] = useCreateConnection();
  const enabledConnections = useEnabledConnections(supportedConnectionTypes);
  const [isRequestingProvider, setIsRequestingProvider] = useState(false);

  const { configData, handleSelectedProvider, providerSelectionProps } =
    useProviderSelection({
      onSubmit: async (providerConfigData) => {
        await createConnection(providerConfigData);
      },
      requestState: creationState,
      providers: enabledConnections,
      providerLabels: SsoProviderLabels,
    });

  if (creationState.type === 'success') {
    return <Navigate replace to="/sso" />;
  }

  if (isRequestingProvider) {
    return (
      <IdentityProviderRequestForm
        onCloseRequestForm={() => setIsRequestingProvider(false)}
      />
    );
  }

  return (
    <PageLayout
      description={
        <>
          <Text as="p" size="medium">
            Select the Identity Provider you want to use with
          </Text>{' '}
          <Pill className="max-w-none" size="small">
            {appName}
          </Pill>
        </>
      }
      navTitle="Configure Single Sign-On"
      title="Identity Provider Configuration"
    >
      <ProviderSelection {...providerSelectionProps} />

      <motion.div
        animate={{ opacity: 1, scale: 1 }}
        className="mt-6 flex flex-col items-center"
        initial={{ opacity: 0, scale: 0.5 }}
        transition={{ delay: 0.3 }}
      >
        <Button
          className="px-6"
          disabled={
            !configData.selectedProvider || creationState.type === 'waiting'
          }
          onClick={handleSelectedProvider}
          size="large"
        >
          Get Started →
        </Button>

        <Button
          appearance="minimal"
          className="mt-4"
          onClick={() => setIsRequestingProvider(true)}
        >
          Don't see your provider?
        </Button>

        {setupLink ? (
          <Text className="mt-4 text-center">
            Setup Link access available for{' '}
            {formatDistance(new Date(setupLink.expiresAt), Date.now())} or until
            configured.
          </Text>
        ) : null}
      </motion.div>
    </PageLayout>
  );
};
