import {
  MetadataUrlFormFields,
  MetadataXmlFormFields,
} from '../interfaces/metadata-form-fields';

export const metadataUrlFormKeys: (keyof MetadataUrlFormFields)[] = [
  'saml_idp_entity_id',
  'saml_idp_metadata_url',
  'saml_idp_url',
  'saml_x509_certificates',
];

export const metadataXmlFormKeys: (keyof MetadataXmlFormFields)[] = [
  'saml_idp_entity_id',
  'saml_idp_metadata_xml',
  'saml_idp_url',
  'saml_x509_certificates',
];
