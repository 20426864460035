import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';

const Component: FC = () => (
  <StepContent confirmLabel="I’ve added users and/or groups to the SAML app.">
    <Text>
      To give users permission to authenticate via this SAML app, you will need
      to assign individual users and/or groups of users to the CyberArk SAML
      app.
    </Text>

    <Text>Click on the "Permissions" tab, and select "Add"</Text>

    <Img
      className="my-8"
      height={814}
      src="/images/050f6bfb-6cfe-4a73-a282-6a46d89dc633.png"
      width={1423}
    />

    <Text>
      Search for the individual user(s) and/or group(s) that you would like to
      assign to the app, and check the box next to them. Click "Add" when you
      are finished. Once users have been successfully added, you should also
      notice the "Status" of your CyberArk SAML app change to "Deployed".
    </Text>

    <Img
      className="my-8"
      height={814}
      src="/images/236bc4af-b19c-4598-a9db-bae816be1f2c.png"
      width={1423}
    />
  </StepContent>
);

export const addUsersToSamlApp: StepData = {
  title: 'Add Users to SAML App',
  component: Component,
};
