import { InputGroup, Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Card } from '../../../../components/card';
import { Form } from '../../../../components/form';
import { useFormContext } from '../../../../shared/form-data-provider';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { useStepsStore } from '../../../../shared/step-navigation/steps-store-provider';
import { CezanneHrFormData } from './cezanne-hr-steps';

const Component: FC = () => {
  const { handleInputChange, handleNextStep, isUpdating } =
    useStepsStore<CezanneHrFormData>();
  const { formData, formErrors } = useFormContext<CezanneHrFormData>();

  return (
    <>
      <Text>
        Upload your Cezanne HR Client ID and Client Secret. To obtain these
        credentials, you will need to request a new API Application from the
        Cezanne HR Support Team.
      </Text>

      <Card>
        <Form
          disabled={
            !formData.cezanneHrClientId || !formData.cezanneHrClientSecret
          }
          isLoading={isUpdating}
          isUpdate={
            !!(formErrors.cezanneHrClientId || formErrors.cezanneHrClientSecret)
          }
          onSubmit={handleNextStep}
        >
          <InputGroup
            error={formErrors.cezanneHrClientId?.message ?? ''}
            id="cezanneHrClientId"
            label="1. Cezanne HR Client ID"
            name="cezanneHrClientId"
            onChange={handleInputChange}
            placeholder=""
            value={formData.cezanneHrClientId ?? ''}
          />

          <InputGroup
            error={formErrors.cezanneHrClientSecret?.message ?? ''}
            id="cezanneHrClientSecret"
            label="2. Cezanne HR Client Secret"
            name="cezanneHrClientSecret"
            onChange={handleInputChange}
            placeholder=""
            type="password"
            value={formData.cezanneHrClientSecret ?? ''}
          />
        </Form>
      </Card>
    </>
  );
};

export const uploadCezanneHrClientIdAndSecret: StepData<CezanneHrFormData> = {
  title: 'Upload Cezanne HR Client ID and Client Secret',
  component: Component,
  formKeys: ['cezanneHrClientId', 'cezanneHrClientSecret'],
};
