import {
  ConnectionFragment,
  ConnectionType,
} from '../../../../graphql/generated';
import {
  SupportedConnectionType,
  supportedConnectionTypes,
} from '../interfaces/supported-connection-type';

export const getSupportedConnectionType = (
  connection: ConnectionFragment,
): SupportedConnectionType | undefined => {
  if (
    (supportedConnectionTypes as readonly ConnectionType[]).includes(
      connection.type,
    )
  ) {
    return connection.type as SupportedConnectionType;
  }
};
