import { ConnectionType } from '../graphql/generated';

const connectionTypeNameMap = {
  [ConnectionType.Adfssaml]: 'AD FS',
  [ConnectionType.AdpOidc]: 'ADP OpenID Connect',
  [ConnectionType.Auth0Saml]: 'Auth0',
  [ConnectionType.AzureSaml]: 'Azure AD',
  [ConnectionType.CasSaml]: 'CAS SAML',
  [ConnectionType.CloudflareSaml]: 'Cloudflare SAML',
  [ConnectionType.ClassLinkSaml]: 'ClassLink',
  [ConnectionType.CyberArkSaml]: 'CyberArk',
  [ConnectionType.DuoSaml]: 'Duo SAML',
  [ConnectionType.GenericOidc]: 'OpenID Connect',
  [ConnectionType.GenericSaml]: 'Generic SAML',
  [ConnectionType.GoogleOAuth]: 'Google OAuth',
  [ConnectionType.GoogleSaml]: 'Google SAML',
  [ConnectionType.JumpCloudSaml]: 'JumpCloud',
  [ConnectionType.KeycloakSaml]: 'Keycloak SAML',
  [ConnectionType.LastPassSaml]: 'LastPass SAML',
  [ConnectionType.MagicLink]: 'Magic Link',
  [ConnectionType.MicrosoftOAuth]: 'Microsoft OAuth',
  [ConnectionType.MiniOrangeSaml]: 'miniOrange SAML',
  [ConnectionType.NetIqSaml]: 'NetIQ SAML',
  [ConnectionType.OktaSaml]: 'Okta',
  [ConnectionType.OneLoginSaml]: 'OneLogin',
  [ConnectionType.OracleSaml]: 'Oracle SAML',
  [ConnectionType.PingFederateSaml]: 'PingFederate',
  [ConnectionType.PingOneSaml]: 'PingOne',
  [ConnectionType.RipplingSaml]: 'Rippling SAML',
  [ConnectionType.SalesforceSaml]: 'Salesforce',
  [ConnectionType.ShibbolethGenericSaml]: 'Shibboleth Generic SAML',
  [ConnectionType.ShibbolethSaml]: 'Shibboleth SAML',
  [ConnectionType.SimpleSamlPhpSaml]: 'SimpleSAMLphp SAML',
  [ConnectionType.VMwareSaml]: 'VMware Workspace One',
};

export const connectionTypeName = (connectionType: ConnectionType): string =>
  connectionTypeNameMap[connectionType];
