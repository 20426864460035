import { Text } from '@workos-inc/component-library';
import { FC } from 'react';

interface NotFoundProps {}

export const NotFound: FC<Readonly<NotFoundProps>> = () => (
  <div className="mt-28 flex flex-col items-center">
    <Text as="h1" className="mb-4" size="xxlarge" weight="medium">
      You don't have a Portal session.
    </Text>

    <Text>
      Please try again with a valid portal session to continue configuration.
    </Text>
  </div>
);
