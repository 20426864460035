import { useEffect } from 'react';
import { useMatch } from 'react-router-dom';
import { ConnectionState } from '../../../../graphql/generated';
import { usePortalSession } from '../../../components/portal-session-provider';
import { useSsoStore } from '../sso-store-provider';

export const SsoExitWarningCheck = () => {
  const onConfigurePage = useMatch('/sso/configure/*');
  const { setShowLoseProgressOnExitWarning } = usePortalSession();
  const { connection } = useSsoStore();

  useEffect(() => {
    if (connection?.state === ConnectionState.Inactive && onConfigurePage) {
      setShowLoseProgressOnExitWarning(true);
    } else {
      setShowLoseProgressOnExitWarning(false);
    }
  }, [connection, setShowLoseProgressOnExitWarning, onConfigurePage]);

  return null;
};
