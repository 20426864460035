import { unreachable } from '@workos-inc/standard';
import { DirectoryType } from '../../../../graphql/generated';

export const getDirectoryName = (directoryType: DirectoryType): string => {
  switch (directoryType) {
    case DirectoryType.AzureScimv2_0:
      return 'Azure AD SCIM v2.0';
    case DirectoryType.BambooHr:
      return 'BambooHR';
    case DirectoryType.BreatheHr:
      return 'Breathe HR';
    case DirectoryType.CezanneHr:
      return 'Cezanne HR';
    case DirectoryType.FourthHr:
      return 'Fourth HR';
    case DirectoryType.CyberArkScimV2_0:
      return 'CyberArk SCIM v2.0';
    case DirectoryType.GenericScimv1_1:
      return 'Generic SCIM v1.1';
    case DirectoryType.GenericScimv2_0:
      return 'Generic SCIM v2.0';
    case DirectoryType.GoogleWorkspace:
      return 'Google Workspace';
    case DirectoryType.Gusto:
      return 'Gusto';
    case DirectoryType.Hibob:
      return 'Hibob';
    case DirectoryType.JumpCloudScimV2_0:
      return 'JumpCloud SCIM v2.0';
    case DirectoryType.OktaScimv1_1:
      return 'Okta SCIM v1.1';
    case DirectoryType.OktaScimv2_0:
      return 'Okta SCIM v2.0';
    case DirectoryType.OneLoginScimV2_0:
      return 'OneLogin SCIM v2.0';
    case DirectoryType.PeopleHr:
      return 'People HR';
    case DirectoryType.PingFederateScimV2_0:
      return 'PingFederate SCIM v2.0';
    case DirectoryType.Rippling:
      return 'Rippling';
    case DirectoryType.RipplingScimV2_0:
      return 'Rippling SCIM v2.0';
    case DirectoryType.S3:
      return 'S3';
    case DirectoryType.Workday:
      return 'Workday';
    default:
      return unreachable(directoryType);
  }
};
