import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { CopyInput } from '../../../../components/inputs';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';
import { useSsoStore } from '../../sso-store-provider';

const Component: FC = () => {
  const { connection } = useSsoStore();
  return (
    <StepContent confirmLabel="I've completed my SAML Client Configuration.">
      <Text>
        On the Settings page, scroll down and make sure "Sign Assertions" is
        turned ON, and "Client Signature Required" is turned OFF.
      </Text>

      <Img
        className="my-8"
        height={1292}
        src="/images/ab2cc845-8919-4bac-ac62-583790e810c1.png"
        width={2066}
      />

      <Text>
        Copy the Redirect URI below and enter it in the "Valid Redirect URIs"
        box. This is the same as the Client SAML Endpoint from the previous
        step. Scroll down and click "Save".
      </Text>

      <CopyInput
        id="saml-acs-url"
        label="Copy this Redirect URI"
        value={connection.saml_acs_url}
      />

      <Img
        className="my-8"
        height={1484}
        src="/images/97a1ec4a-fc6a-4359-997b-b19760e8a79e.png"
        width={2528}
      />
    </StepContent>
  );
};

export const samlClientConfiguration: StepData = {
  title: 'SAML Client Configuration',
  component: Component,
};
