import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';
import { ConnectionAttributeMapping } from '../../attribute-mapping';
import { useSsoStore } from '../../sso-store-provider';

const Component: FC = () => {
  const { connection } = useSsoStore();

  return (
    <StepContent confirmLabel="I've finished configuring the Attribute Mapping.">
      <Text>Provide the following Attribute Mappings and select "Finish":</Text>

      <ConnectionAttributeMapping connectionType={connection.type} />

      <Img
        className="my-8"
        height={1034}
        src="/images/f5bdf97e-407d-4d13-a3ff-68d2c56998b0.png"
        width={1271}
      />
    </StepContent>
  );
};

export const configureAttributeMapping: StepData = {
  title: 'Configure Attribute Mapping',
  component: Component,
};
