import { unreachable } from '@workos-inc/standard';
import { SamlX509CertificateValidationErrorCode } from '../../../../../../graphql/generated';
import { getUpdateType } from './get-update-type';

export const parseInvalidSamlX509CertificateError = (
  validationError: SamlX509CertificateValidationErrorCode,
  updateType: Exclude<ReturnType<typeof getUpdateType>, undefined>,
) => {
  const field = (() => {
    switch (updateType) {
      case 'Manual':
        return 'saml_x509_certificates';
      case 'MetadataUrl':
        return 'saml_idp_metadata_url';
      case 'MetadataXml':
        return 'saml_idp_metadata_xml';
      default:
        return unreachable(updateType);
    }
  })();

  const message = (() => {
    switch (field) {
      case 'saml_idp_metadata_url':
      case 'saml_idp_metadata_xml':
        switch (validationError) {
          case SamlX509CertificateValidationErrorCode.ExpiredCertificate:
            return 'The metadata contains an expired X.509 certificate.';
          case SamlX509CertificateValidationErrorCode.MalformedCertificate:
            return 'The metadata contains a malformed X.509 certificate.';
          default:
            return unreachable(validationError);
        }
      case 'saml_x509_certificates':
        switch (validationError) {
          case SamlX509CertificateValidationErrorCode.ExpiredCertificate:
            return 'Expired SAML X509 Certificate. Did you upload the correct file?';
          case SamlX509CertificateValidationErrorCode.MalformedCertificate:
            return 'Invalid SAML X509 Certificate. Did you upload the correct file?';
          default:
            return unreachable(validationError);
        }
      default:
        return unreachable(field);
    }
  })();

  return { field, message };
};
