import { Text } from '@workos-inc/component-library';
import * as React from 'react';
import { ConnectionStepProps } from '../../../interfaces/step-props';
import { Confirm } from '../../confirm';
import { Img } from '../../image';
import { CopyInput } from '../../input';
import { MetadataUrlStep } from './saml/metadata-url-step';

export const SalesforceCreateCert: React.FC<Readonly<ConnectionStepProps>> = ({
  onNextStep: handleNextStep,
}) => (
  <>
    <Text>
      Log in to your Salesforce Account, and navigate to "Setup" by clicking the
      Settings Cog on the top right and selecting "Setup".
    </Text>

    <Text>
      Once in setup mode you can use the search bar to easily navigate around
      between settings pages. The first page to navigate to is the "Certificate
      and Key Management" page.
    </Text>

    <Img
      className="my-8"
      height={901}
      src="/images/c3c88f8e-bb40-4e21-a0aa-7f8741d37e93.png"
      width={1435}
    />

    <Text>
      If a key does not exist that you would like to use, generate a new one by
      clicking "Create Self-Signed Certificate"
    </Text>

    <Img
      className="my-8"
      height={1904}
      src="/images/62346683-435b-4d72-bfc7-1d35474ff0d1.png"
      width={3336}
    />

    <Text>
      Configure the certificate. It's not necessary to have an Exportable
      Private Key, but if you are using a key-certificate store you can choose
      this option.
    </Text>

    <Img
      className="my-8"
      height={1284}
      src="/images/b7c9e7ed-5797-45d6-92f2-102396c33d2e.png"
      width={3334}
    />

    <Text></Text>

    <Confirm
      label="I've created a Self-Signed Certificate in Salesforce"
      onClick={handleNextStep}
    />
  </>
);

export const SalesforceConfigureIdp: React.FC<
  Readonly<ConnectionStepProps>
> = ({ onNextStep: handleNextStep }) => (
  <>
    <Text>
      In the "Identity Provider" portal, select "Enable Identity Provider" if
      you have not already done this.
    </Text>

    <Img
      className="my-8"
      height={737}
      src="/images/1651c684-6b10-4427-b03b-363ede9c0315.png"
      width={1586}
    />

    <Text>
      You will need to select the correct certificate from the previous step.
    </Text>

    <Img
      className="my-8"
      height={737}
      src="/images/b0dd15cb-5105-48e4-a3d8-3354f083257f.png"
      width={1586}
    />

    <Text>Remain in the "Identity Provider" portal for the next step.</Text>

    <Confirm
      label="I've enabled Salesforce as an IdP"
      onClick={handleNextStep}
    />
  </>
);

export const SalesforceMetadataUpload: React.FC<
  Readonly<ConnectionStepProps>
> = (connectionStepProps) => (
  <MetadataUrlStep
    {...connectionStepProps}
    idpEntityIdPlaceholder="https://mydomain.my.salesforce.com"
    idpSsoUrlPlaceholder="https://mydomain.my.salesforce.com/idp/endpoint/HttpRedirect"
    metadataUrlPlaceholder="https://mydomain.my.salesforce.com/.well-known/samlidp.xml"
  >
    <Text>
      In the "Identity Provider" portal, once enabled you will also see a
      Metadata URL.
    </Text>
    <Img
      className="my-8"
      height={1003}
      src="/images/8c718340-9b43-4845-8d7b-2d56f9dc2f16.png"
      width={1566}
    />

    <Text> Paste this in below:</Text>
  </MetadataUrlStep>
);

export const SalesforceConfigureApp: React.FC<
  Readonly<ConnectionStepProps>
> = ({ connection, onNextStep: handleNextStep }) => (
  <>
    <Text>
      In the "App Manager" portal select the option for "New Connected App".
    </Text>
    <Img
      className="my-8"
      height={1560}
      src="/images/b9a87fbe-9034-4905-b5b3-3a248843e2d4.png"
      width={3452}
    />
    <Text>
      Give the App and API a meaningful name and set a contact email that
      corresponds to who you'd reach out to for support should there be an
      issue.
    </Text>

    <Img
      className="my-8"
      height={1502}
      src="/images/2cf68d40-cb13-4768-92bb-d2b8039ec2cc.png"
      width={3514}
    />

    <Text>
      Scroll down further to the "Web App Settings" and check the box for
      "Enable SAML".
    </Text>

    <CopyInput
      label="Copy this Entity Id "
      value={connection?.saml_entity_id}
    />
    <CopyInput label="Copy this ACS URL" value={connection?.saml_acs_url} />

    <Text>
      Enter the Entity ID and ACS URL into their respective places within the
      Settings.
    </Text>
    <Text>
      The "Subject Type" should be set to "User ID" and the "Name ID Format"
      should be set to urn:oasis:names:tv:SAML:1.1:nameid-format:emailAddress .
      The "Issuer" should populate correctly with your Salesforce subdomain. For
      the IdP Certificate, select the certificate that matches the one
      previously used when enabling the Identity Provider, and for the "Signing
      Algorithm for SAML Messages" choose "SHA256".
    </Text>

    <Img
      className="my-8"
      height={743}
      src="/images/f7e23ef6-ee00-499a-9b19-4838d4a900de.png"
      width={1423}
    />

    <Confirm
      label="I've created my Salesforce Connected App."
      onClick={handleNextStep}
    />
  </>
);

export const SalesforceConfigureAttributes: React.FC<
  Readonly<ConnectionStepProps>
> = ({ onNextStep: handleNextStep }) => (
  <>
    <Text>
      In the Setup search bar browse to the "Manage Connected Apps" portal.
      Click on your application and this will open the view where you can
      configure the attribute mapping, and later on the user profile access
      permissions.
    </Text>

    <Img
      className="my-8"
      height={736}
      src="/images/7ae58ca2-e715-44f3-9742-6bb2f4e4f203.png"
      width={1424}
    />

    <Text>
      Viewing the app, scroll down to the "Custom Attributes" section and select
      "New".
    </Text>
    <Img
      className="my-8"
      height={1274}
      src="/images/56ece4cb-6eec-4886-bd66-2891823e996a.png"
      width={3500}
    />

    <Text>Configure the fields so the mapping matches the following:</Text>

    <Img
      className="my-8"
      height={1268}
      src="/images/de3490a6-4257-49f5-978b-b66ee40ac09d.png"
      width={3478}
    />

    <Confirm
      label="I've mapped the user attributes."
      onClick={handleNextStep}
    />
  </>
);

export const SalesforceAddUserProfiles: React.FC<
  Readonly<ConnectionStepProps>
> = ({ onNextStep: handleNextStep }) => (
  <>
    <Text>
      Select "Manage Profiles" section in the app view. Grant access to the
      appropriate profiles that should have access to the application.
    </Text>
    <Img
      className="my-8"
      height={812}
      src="/images/84b366ef-1643-421c-95f7-ef6ed049b3f8.png"
      width={1440}
    />
    <Text>
      The image below shows an example of a successful configuration allowing
      all "End User" profiles to access the Connected App:
    </Text>
    <Img
      className="my-8"
      height={777}
      src="/images/6ecdeed1-bcca-40ea-af4d-624443efcfd8.png"
      width={1432}
    />
    <Text>Press "Save".</Text>
    <Confirm
      label="I've granted access to profiles who should access the Connected App."
      onClick={handleNextStep}
    />
  </>
);
