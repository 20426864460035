import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { metadataUrlFormKeys } from '../../constants/metadata-form-keys';
import { MetadataUrlFormFields } from '../../interfaces/metadata-form-fields';
import { MetadataUrlStep } from '../../metadata-step';

const Component: FC = () => (
  <MetadataUrlStep
    idpEntityIdPlaceholder="https://mydomain.my.salesforce.com"
    idpSsoUrlPlaceholder="https://mydomain.my.salesforce.com/idp/endpoint/HttpRedirect"
    metadataUrlPlaceholder="https://mydomain.my.salesforce.com/.well-known/samlidp.xml"
  >
    <Text>
      In the "Identity Provider" portal, once enabled you will also see a
      Metadata URL.
    </Text>
    <Img
      className="my-8"
      height={1003}
      src="/images/8c718340-9b43-4845-8d7b-2d56f9dc2f16.png"
      width={1566}
    />

    <Text> Paste this in below:</Text>
  </MetadataUrlStep>
);

export const uploadIdentityProviderMetadata: StepData<MetadataUrlFormFields> = {
  title: 'Upload Identity Provider Metadata',
  component: Component,
  formKeys: metadataUrlFormKeys,
};
