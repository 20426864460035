import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { CopyInput } from '../../../../components/inputs';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';
import { useSsoStore } from '../../sso-store-provider';

const Component: FC = () => {
  const { connection } = useSsoStore();

  return (
    <StepContent confirmLabel="I’ve completed my Basic SAML Configuration.">
      <Text>Click the “Configuration” tab from the left sidebar.</Text>

      <Img
        className="my-8"
        height={2002}
        src="/images/fdbf90cc-fec5-4fe3-a0f4-cc6c2a06f6eb.png"
        width={2816}
      />

      <CopyInput
        id="saml-acs-url-validator-copy-button"
        label="Copy this ACS URL Validator"
        value={
          connection.saml_acs_url
            ? `^${connection.saml_acs_url.replace(/\//g, '\\/')}$`
            : undefined
        }
      />

      <Text>Submit the “ACS URL Validator” in the Application details.</Text>

      <Img
        className="my-8"
        height={2002}
        src="/images/04124f22-68a6-4a8e-821f-92a81e690d62.png"
        width={2816}
      />

      <CopyInput
        id="saml-acs-url-copy-button"
        label="Copy this ACS URL"
        value={connection.saml_acs_url}
      />

      <Text>Submit the “ACS URL” in the Application details.</Text>

      <Img
        className="my-8"
        height={2002}
        src="/images/ba24f597-f52f-4651-8b7c-f7366ccba63f.png"
        width={2816}
      />

      <Text>
        Enter the Login URL that your SP uses to initiate the login in the Login
        URL field.
      </Text>

      <Img
        className="my-8"
        height={2002}
        src="/images/72d7b813-6b62-4823-900d-4691f98122ec.png"
        width={2816}
      />
    </StepContent>
  );
};

export const basicSamlConfiguration: StepData = {
  title: 'Basic SAML Configuration',
  component: Component,
};
