import {
  Card,
  Code,
  CopyIcon,
  copyTextToClipboard,
  FormItem,
  IconButton,
  Label,
  Pill,
  Text,
  Tooltip,
} from '@workos-inc/component-library';
import { unreachable } from '@workos-inc/standard';
import { FC, ReactElement, useState } from 'react';
import beautify from 'xml-beautifier';
import { ConnectionErrors } from '../../../../../components/sso/connection-errors';
import { ConnectionSessionFragment } from '../../../../../graphql/deserializers/connection-session-deserializer';
import {
  ConnectionFragment,
  ConnectionSessionError,
} from '../../../../../graphql/generated';
import { ProviderLogo } from '../../../../components/provider-logo';
import { formatDate, formatName } from '../../../../utils/formatters';
import { getConnectionName } from '../../utils/get-connection-name';
import { getSessionStateBadge } from '../../utils/get-session-state-badges';

type EventProps = {
  appName: string;
  event: ConnectionSessionFragment;
  connection: ConnectionFragment;
};

export const ConnectionEvent: FC<Readonly<EventProps>> = ({
  appName,
  event,
  connection,
}) => {
  const { type: connectionType } = connection;

  return (
    <div className="flex flex-col gap-8">
      <Card>
        <Card.Header className="flex items-center justify-between">
          <Card.Title>Event Details</Card.Title>
          <ProviderLogo provider={connectionType} width="60px" />
        </Card.Header>
        <Card.Body className="flex flex-col gap-4">
          <FormItem isInline>
            <Label htmlFor="state">State</Label>
            <Pill
              appearance={getSessionStateBadge(event.state)}
              id="state"
              size="small"
            >
              {event.state}
            </Pill>
          </FormItem>

          <FormItem isInline>
            <Label htmlFor="email">Email</Label>
            <Text as="span" id="email">
              {event.profile?.email || '-'}
            </Text>
          </FormItem>

          <FormItem isInline>
            <Label htmlFor="name">Name</Label>
            <Text as="span" id="name">
              {formatName(event.profile)}
            </Text>
          </FormItem>

          <FormItem isInline>
            <Label htmlFor="timestamp">Timestamp</Label>
            <Text as="span" id="timestamp">
              {formatDate(event.createdAt)}
            </Text>
          </FormItem>
        </Card.Body>
      </Card>

      {event.errors && (
        <ConnectionErrors
          appName={appName}
          connection={connection}
          errors={event.errors as ConnectionSessionError[]}
        />
      )}

      {(() => {
        switch (event.__typename) {
          case 'SAMLSession':
            return (
              <>
                <CodeWrapper
                  code={event.samlRequest}
                  copyButtonDataTestId="saml-request-copy-button"
                  placeholder={
                    <Text as="h3" size="large" weight="medium">
                      No request
                    </Text>
                  }
                  title="SAML Request"
                />
                <CodeWrapper
                  code={event.samlResponse}
                  copyButtonDataTestId="saml-response-copy-button"
                  placeholder={
                    <>
                      <Text
                        as="h3"
                        className="mb-4"
                        size="large"
                        weight="medium"
                      >
                        No response
                      </Text>

                      <Text>
                        This often occurs when the user who initiated the SAML
                        request has not yet completed the sign in process with{' '}
                        {getConnectionName(connectionType)}.
                      </Text>
                    </>
                  }
                  title="SAML Response"
                />
              </>
            );
          case 'OAuthSession':
          case 'OidcSession':
            return (
              <CodeWrapper
                code={event.profile?.rawAttributes}
                copyButtonDataTestId="oidc-response-copy-button"
                language={'json'}
                placeholder={
                  <>
                    <Text as="h3" className="mb-4" size="large" weight="medium">
                      No response
                    </Text>

                    <Text>
                      This often occurs when the user who initiated the{' '}
                      {event.__typename === 'OAuthSession'
                        ? 'OAuth'
                        : 'OpenID Connect'}{' '}
                      request has not yet completed the sign in process with{' '}
                      {getConnectionName(connectionType)}.
                    </Text>
                  </>
                }
                title="OAuth Response"
              />
            );
          default:
            return unreachable(event);
        }
      })()}
    </div>
  );
};

type CodeWrapperProps = {
  code?: string | null;
  copyButtonDataTestId: string;
  language?: 'markup' | 'json';
  placeholder: ReactElement;
  title: string;
};

const CodeWrapper = ({
  code,
  copyButtonDataTestId,
  language = 'markup',
  placeholder,
  title,
}: CodeWrapperProps): ReactElement => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = (): void => {
    const currentValue = code || '';
    void copyTextToClipboard(currentValue);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 3000);
  };

  if (!code) {
    return (
      <Card>
        <Card.Header>
          <Card.Title>{title}</Card.Title>
        </Card.Header>
        <div className="flex flex-col items-center p-8 text-center">
          {placeholder}
        </div>
      </Card>
    );
  }

  return (
    <Card>
      <Card.Header className="flex items-center justify-between">
        <Card.Title>{title} </Card.Title>
        <Tooltip content={isCopied ? 'Copied' : 'Copy'}>
          <IconButton>
            <CopyIcon
              data-testid={copyButtonDataTestId}
              isCopied={isCopied}
              onClick={handleCopy}
            />
          </IconButton>
        </Tooltip>
      </Card.Header>

      <Card.Body className="mt-4 bg-gray-lightmode-100 dark:bg-gray-darkmode-100">
        <Code language={language}>
          {language === 'markup'
            ? beautify(code)
            : JSON.stringify(code, null, 2)}
        </Code>
      </Card.Body>
    </Card>
  );
};
