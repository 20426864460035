import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';
import { ConnectionAttributeMapping } from '../../attribute-mapping';
import { useSsoStore } from '../../sso-store-provider';

const Component: FC = () => {
  const { connection } = useSsoStore();

  return (
    <StepContent confirmLabel="I've finished configuring the SAML claims.">
      <Text>
        Expand the Attribute Configuration section on the SSO Configuration page
        and add the following 4 required attributes: firstName, lastName, email,
        and id.
      </Text>

      <Text>Ensure the following attribute mapping is set:</Text>

      <ConnectionAttributeMapping connectionType={connection.type} />
    </StepContent>
  );
};

export const configureSamlClaims: StepData = {
  title: 'Configure SAML Claims',
  component: Component,
};
