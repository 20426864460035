import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { metadataUrlFormKeys } from '../../constants/metadata-form-keys';
import { MetadataUrlFormFields } from '../../interfaces/metadata-form-fields';
import { MetadataUrlStep } from '../../metadata-step';

const Component: FC = () => (
  <MetadataUrlStep
    idpEntityIdPlaceholder="http://www.duo.com/exkgq2c19CUpt2Brr46"
    idpSsoUrlPlaceholder="http://www.duo.com/exkgq2c19CUpt2Brr46"
    metadataUrlPlaceholder="https://"
  >
    <Text>
      The next step is to obtain the Metadata URL from Duo. The Metadata URL can
      be found in your application settings page within Duo.
    </Text>

    <Img
      className="my-8"
      height={1426}
      src="/images/625ff0fa-2c12-43e9-8b7a-66aa4c670423.png"
      width={2226}
    />

    <Text>Paste the Metadata URL in the field below.</Text>
  </MetadataUrlStep>
);

export const uploadMetadataFromDuo: StepData<MetadataUrlFormFields> = {
  title: 'Upload Metadata from Duo',
  component: Component,
  formKeys: metadataUrlFormKeys,
};
