import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { usePortalSession } from '../../../../components/portal-session-provider';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';
import { BambooHrFormData } from './bamboo-hr-steps';

const Component: FC = () => {
  const { appName } = usePortalSession();

  return (
    <StepContent confirmLabel="I've generated my BambooHR API key.">
      <Text>
        Log in to your BambooHR instance as an admin user and select your name
        in the upper right-hand corner of the BambooHR console, then select “API
        keys” in the menu.
      </Text>

      <Img
        className="my-8"
        height={1049}
        src="/images/4332398c-79b8-4e22-b5be-5c0a1d10ee95.png"
        width={1536}
      />

      <Text>Select "Add New Key".</Text>

      <Img
        className="my-8"
        height={1049}
        src="/images/4dc48693-b83c-4cd6-a3ad-e004aa29ef79.png"
        width={1536}
      />
      <Text>
        Give your key a descriptive name such as <strong>{appName}</strong> and
        select "Generate Key".
      </Text>

      <Img
        className="my-8"
        height={813}
        src="/images/4bab0640-61a6-474e-a778-6fd3255ed062.png"
        width={1440}
      />
      <Text>
        Select "Copy Key" and save this API key, which you'll upload in the next
        step.
      </Text>

      <Img
        className="my-8"
        height={813}
        src="/images/e7c56878-b7d4-4ef9-84d0-841e7e37c847.png"
        width={1440}
      />
    </StepContent>
  );
};

export const generateApiKeyInBambooHr: StepData<BambooHrFormData> = {
  title: 'Generate API Key in BambooHR',
  component: Component,
};
