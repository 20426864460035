import { Button, DropdownMenu } from '@workos-inc/component-library';
import classNames from 'classnames';
import { ComponentProps, FC, SVGProps } from 'react';
import { Icon } from 'react-feather';

const Dropdown: FC<{ children?: React.ReactNode }> = ({ children }) => (
  <DropdownMenu>
    <DropdownMenu.Trigger asChild>
      <Button iconRight={<DownArrow className="ml-1" />}>Actions</Button>
    </DropdownMenu.Trigger>
    <DropdownMenu.Content align="end">{children}</DropdownMenu.Content>
  </DropdownMenu>
);

interface DropdownItemProps extends ComponentProps<typeof DropdownMenu.Item> {
  icon: Icon;
}

export const Item: FC<DropdownItemProps> = ({
  className,
  icon: Icon,
  children,
  ...props
}) => (
  <DropdownMenu.Item
    {...props}
    className={classNames('flex items-center gap-x-2')}
  >
    <Icon opacity={50} size={14} />
    {children}
  </DropdownMenu.Item>
);

const DownArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height={6}
    width={10}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M5 5.5 9.33.25H.67L5 5.5Z" fill="currentColor" />
  </svg>
);

export const AuditLogsActionsDropdown = Object.assign(Dropdown, {
  Item,
  Separator: DropdownMenu.Separator,
});
