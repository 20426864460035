import { Spinner as SpinnerComponent } from '@workos-inc/component-library';
import classNames from 'classnames';
import { FC } from 'react';

type SpinnerProps = {
  isCube?: boolean;
};

export const Spinner: FC<Readonly<SpinnerProps>> = ({ isCube }) => {
  if (isCube) {
    return <SpinnerCube />;
  }

  return (
    <div className="flex items-center justify-center">
      <SpinnerComponent />
    </div>
  );
};

const SpinnerCube: FC = () => {
  const cubes = [0, 1, 3, 2].map((v, i) => (
    <div
      key={v}
      className={classNames(
        'float-left w-6/12 h-1/2 relative scale-110',
        'before:absolute before:top-0 before:left-0 before:w-full before:h-full before:bg-primary before:origin-bottom-right',
        {
          'rotate-90': i === 1,
          '-rotate-90': i === 2,
          'rotate-180': i === 3,
        },
        {
          'before:animate-[fold_2.4s_infinite_linear_both_0s]': i === 0,
          'before:animate-[fold_2.4s_infinite_linear_both_0.3s]': i === 1,
          'before:animate-[fold_2.4s_infinite_linear_both_0.9s]': i === 2,
          'before:animate-[fold_2.4s_infinite_linear_both_0.6s]': i === 3,
        },
      )}
    />
  ));

  return (
    <div className="flex h-full w-full animate-[fadeIn_250ms_1s_forwards] items-center justify-center">
      <div className="relative h-10 w-10 rotate-45">{cubes}</div>
    </div>
  );
};
