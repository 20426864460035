import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { CopyInput } from '../../../../components/inputs';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';
import { useSsoStore } from '../../sso-store-provider';

const Component: FC = () => {
  const { connection } = useSsoStore();

  return (
    <StepContent confirmLabel="I've created my Salesforce Connected App.">
      <Text>
        In the "App Manager" portal select the option for "New Connected App".
      </Text>
      <Img
        className="my-8"
        height={1560}
        src="/images/b9a87fbe-9034-4905-b5b3-3a248843e2d4.png"
        width={3452}
      />
      <Text>
        Give the App and API a meaningful name and set a contact email that
        corresponds to who you'd reach out to for support should there be an
        issue.
      </Text>

      <Img
        className="my-8"
        height={1502}
        src="/images/2cf68d40-cb13-4768-92bb-d2b8039ec2cc.png"
        width={3514}
      />

      <Text>
        Scroll down further to the "Web App Settings" and check the box for
        "Enable SAML".
      </Text>

      <CopyInput
        id="entity-id"
        label="Copy this Entity Id "
        value={connection?.saml_entity_id}
      />
      <CopyInput
        id="acs-url"
        label="Copy this ACS URL"
        value={connection?.saml_acs_url}
      />

      <Text>
        Enter the Entity ID and ACS URL into their respective places within the
        Settings.
      </Text>
      <Text>
        The "Subject Type" should be set to "User ID" and the "Name ID Format"
        should be set to urn:oasis:names:tv:SAML:1.1:nameid-format:emailAddress
        . The "Issuer" should populate correctly with your Salesforce subdomain.
        For the IdP Certificate, select the certificate that matches the one
        previously used when enabling the Identity Provider, and for the
        "Signing Algorithm for SAML Messages" choose "SHA256".
      </Text>

      <Img
        className="my-8"
        height={743}
        src="/images/f7e23ef6-ee00-499a-9b19-4838d4a900de.png"
        width={1423}
      />
    </StepContent>
  );
};

export const createAConnectedApp: StepData = {
  title: 'Create a Connected App',
  component: Component,
};
