import { FC, useEffect } from 'react';
import * as React from 'react';
import { usePortalSession } from '../portal-session-provider/portal-session-provider';

const PageTitleEffect: FC<{
  title: string;
}> = ({ title }) => {
  const { setPageTitle } = usePortalSession();
  useEffect(() => {
    setPageTitle(title);
  }, [setPageTitle, title]);
  return null;
};

/**
 * Guarantees that the page title is going to be updated
 * before any other child WithPageTitle components
 */
export const PageTitle: FC<{
  title: string;
  children?: React.ReactNode;
}> = ({ title, children }) => (
  <>
    <PageTitleEffect title={title} />
    {children}
  </>
);
