import { Text } from '@workos-inc/component-library';
import { DirectoryStepProps } from '../../../interfaces/step-props';
import { Confirm } from '../../confirm';
import { Img } from '../../image';
import { CopyInput } from '../../input';
import { MapAttributesStep } from './map-attributes-step';
import { ArrayStepsConfig } from './steps-config';

export const stepsRipplingScim: ArrayStepsConfig = {
  providerLabel: 'Rippling SCIM v2.0',
  type: 'Array',
  steps: [
    {
      name: 'Create a Rippling SCIM application',
      // eslint-disable-next-line react/display-name
      render: ({ onNextStep: handleNextStep }: DirectoryStepProps) => (
        <>
          <Text>
            If you are an admin of your Rippling instance, log in and select the
            Custom App option in the menu under the Identity Management
            category.
          </Text>

          <Img
            className="my-8"
            height={939}
            src="/images/2851bbb9-a486-4e51-90c1-6265959cb336.png"
            width={1387}
          />
          <Text>Select "Create New App" in the Custom App page.</Text>

          <Img
            className="my-8"
            height={939}
            src="/images/bd2b1fba-2433-4fc2-b20b-7a0ca712dd20.png"
            width={1387}
          />

          <Text>
            Fill out the application's name, upload an image for the logo, and
            check the "SCIM Provisioning" box. Click "Create App" and the page
            will update with more option fields regarding SCIM setup.
          </Text>

          <Img
            className="my-8"
            height={939}
            src="/images/30904e12-3522-4c21-b663-9e700fa06ea6.png"
            width={1387}
          />

          <Confirm
            label="I've filled out the basic app info and clicked Create App."
            onClick={handleNextStep}
          />
        </>
      ),
    },
    {
      name: 'Configure Rippling SCIM App',
      // eslint-disable-next-line react/display-name
      render: ({
        directory,
        onNextStep: handleNextStep,
      }: DirectoryStepProps) => (
        <>
          <Text>Set the SCIM version to 2.0.</Text>

          <Text>
            Copy and paste the endpoint into the "SCIM Base URL" field.
          </Text>

          <CopyInput label="Copy this Endpoint" value={directory?.endpoint} />

          <Img
            className="my-8"
            height={1109}
            src="/images/1aae60cf-49dc-4514-8ac3-b745d14a3094.png"
            width={1402}
          />

          <Text>
            Set the SCIM authorization method to "Bearer Token". Check off
            features for groups, pagination, delete groups and PATCH groups.
          </Text>

          <Img
            className="my-8"
            height={1109}
            src="/images/23d3979e-f1bd-45ad-bef4-4398ec81425b.png"
            width={1402}
          />

          <Text>
            Add SCIM attributes externalId, emails.primary, name.givenName,
            name.familyName.
          </Text>

          <Img
            className="my-8"
            height={1090}
            src="/images/16762d45-8db5-4a51-946a-ef479ee19a6e.png"
            width={1510}
          />

          <Confirm
            label="I’ve confirmed, I've entered the SCIM Base URL and set the SCIM settings."
            onClick={handleNextStep}
          />
        </>
      ),
    },
    {
      name: 'Add Supported SCIM Attributes',
      isCustomAttributeMapping: true,
      render: (directoryStepProps: DirectoryStepProps) => (
        <MapAttributesStep {...directoryStepProps}>
          <Text>
            There are additional attributes needed by your application for it to
            fully function. Please add and map Rippling attributes to their
            corresponding values.
          </Text>

          <Img
            className="my-8"
            height={1090}
            src="/images/16762d45-8db5-4a51-946a-ef479ee19a6e.png"
            width={1510}
          />
        </MapAttributesStep>
      ),
    },
    {
      name: 'Configure Bearer Token, Users, and Groups',
      // eslint-disable-next-line react/display-name
      render: ({
        directory,
        onNextStep: handleNextStep,
      }: DirectoryStepProps) => (
        <>
          <Text>
            Copy and paste the Bearer Token into the "Bearer Token" field.
          </Text>

          <CopyInput
            label="Copy this Bearer Token"
            type="password"
            value={directory?.bearerToken}
          />

          <Img
            className="my-8"
            height={1109}
            src="/images/7dc156e2-7584-4efd-a748-39419b182875.png"
            width={1402}
          />

          <Text>
            In order for your users and groups to be synced, you will need to
            assign them to your Rippling Application. Match the Rippling users
            to the account, or create a new application account for the user(s).
          </Text>

          <Img
            className="my-8"
            height={1109}
            src="/images/3f3c8972-2cf6-401c-9379-0ec043f6dba1.png"
            width={1402}
          />

          <Text>Create Groups to add to the application as needed.</Text>

          <Img
            className="my-8"
            height={1109}
            src="/images/36b16a45-3db7-4793-8b87-b1fec0467e2d.png"
            width={1402}
          />

          <Confirm
            label="I’ve confirmed, I've entered the bearer token, users and groups."
            onClick={handleNextStep}
          />
        </>
      ),
    },
    {
      name: 'Set up Attribute Mapping',
      // eslint-disable-next-line react/display-name
      render: ({ onNextStep: handleNextStep }: DirectoryStepProps) => (
        <>
          <Text>Make sure all attributes previously added are enabled.</Text>

          <Img
            className="my-8"
            height={1109}
            src="/images/15000694-0040-4b4c-8731-9052925987cb.png"
            width={1402}
          />

          <Confirm
            label="I’ve completed my assignments."
            onClick={handleNextStep}
          />
        </>
      ),
    },
  ],
};
