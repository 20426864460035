import { format } from 'date-fns';
import { Profile } from '../../graphql/generated';

export const formatName = (profile?: Partial<Profile> | null): string =>
  profile?.firstName && profile?.lastName
    ? `${profile.firstName} ${profile.lastName}`
    : '-';

export const formatDate = (date: string): string =>
  format(new Date(date), 'Pp');
