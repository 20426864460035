import { Appearance } from '@workos-inc/component-library';
import { DirectoryUserState } from '../../../../graphql/generated';

const DirectoryUserStateBadges = {
  [DirectoryUserState.Active]: 'green',
  [DirectoryUserState.Inactive]: 'red',
  [DirectoryUserState.Suspended]: 'red',
} as const;

export const getDirectoryUserStateBadge = (
  state: DirectoryUserState,
): Appearance => DirectoryUserStateBadges[state];
