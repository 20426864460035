import { FC } from 'react';
import { StepData } from '../../../../shared/step-navigation';
import { useSsoStepRoutes } from '../../hooks/use-sso-step-routes';
import { MetadataXmlFormFields } from '../../interfaces/metadata-form-fields';
import { SsoStepNavigation } from '../../sso-step-navigation';
import { generateSsoStepData } from '../../utils/generate-sso-step-data';
import { createRipplingSamlApplication } from './1-create-rippling-saml-application';
import { uploadIdpMetadata } from './2-upload-idp-metadata';
import { uploadAcsUrlAndSpEntityId } from './3-upload-acs-url-and-sp-entity-id';
import { configureRipplingAttributeMapping } from './4-configure-attribute-mapping';

interface RipplingSamlStepsProps {}

const stepsData: StepData<MetadataXmlFormFields>[] = generateSsoStepData([
  createRipplingSamlApplication,
  uploadIdpMetadata,
  uploadAcsUrlAndSpEntityId,
  configureRipplingAttributeMapping,
]);

export const RipplingSamlSteps: FC<Readonly<RipplingSamlStepsProps>> = () => {
  const stepRoutes = useSsoStepRoutes(stepsData);

  return (
    <SsoStepNavigation stepsData={stepsData}>{stepRoutes}</SsoStepNavigation>
  );
};
