import { FC } from 'react';
import { EyeOff, IconProps, Terminal, User } from 'react-feather';
import { AuditLogEventActor } from '../../../../graphql/generated';

const ACTOR_ICONS = {
  user: User,
  system: Terminal,
  anonymous: EyeOff,
};

interface AuditLogsActorIconProps extends IconProps {
  actor?: AuditLogEventActor;
}

export const AuditLogsActorIcon: FC<AuditLogsActorIconProps> = ({
  actor,
  ...props
}) => {
  const userType = actor?.type ?? 'user';
  const UserIcon = ACTOR_ICONS[userType as keyof typeof ACTOR_ICONS];
  return <UserIcon {...props} />;
};
