import { useEffect } from 'react';
import { useMatch } from 'react-router-dom';
import { DirectoryState } from '../../../../graphql/generated';
import { usePortalSession } from '../../../components/portal-session-provider';
import { useDsyncStore } from '../dsync-store-provider';

export const DsyncExitWarningCheck = () => {
  const onConfigurePage = useMatch('/dsync/configure/*');
  const { setShowLoseProgressOnExitWarning } = usePortalSession();
  const { directory } = useDsyncStore();

  useEffect(() => {
    if (directory?.state !== DirectoryState.Linked && onConfigurePage) {
      setShowLoseProgressOnExitWarning(true);
    } else {
      setShowLoseProgressOnExitWarning(false);
    }
  }, [directory, setShowLoseProgressOnExitWarning, onConfigurePage]);

  return null;
};
