import { Button, Text } from '@workos-inc/component-library';
import { motion } from 'framer-motion';
import { FC, useEffect, useState } from 'react';
import { Check, X } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { DirectoryState } from '../../../../graphql/generated';
import { graphql } from '../../../../utils/graphql';
import { usePortalSession } from '../../../components/portal-session-provider';
import { Article, Title } from '../../../components/typography';
import { useDsyncStore } from '../dsync-store-provider';

export const ConfirmationStep: FC = () => {
  const navigate = useNavigate();

  const { appName, successUrl } = usePortalSession();
  const { directory, setDsyncStore } = useDsyncStore();

  const [isDirectoryReloaded, setIsDirectoryReloaded] = useState(false);

  useEffect(() => {
    const reloadDirectory = async (): Promise<void> => {
      if (!isDirectoryReloaded) {
        const response = await graphql().Directory({ id: directory.id });

        if (response?.data?.portal_directory) {
          setDsyncStore({ directory: response.data.portal_directory });

          setIsDirectoryReloaded(true);
        }
      }
    };

    void reloadDirectory();
  }, [
    directory.id,
    isDirectoryReloaded,
    setDsyncStore,
    setIsDirectoryReloaded,
  ]);

  if (!isDirectoryReloaded) {
    return null;
  }

  if (directory.state === DirectoryState.Linked) {
    return (
      <Article className="flex flex-col items-center text-center">
        <motion.i
          animate={{ opacity: 1, scale: 1 }}
          className="my-10 flex h-[100px] w-[100px] items-center justify-center rounded-full bg-green-darken text-white"
          initial={{ opacity: 0, scale: 0.5 }}
          transition={{ delay: 0.1 }}
        >
          <Check size={54} strokeWidth={2} />
        </motion.i>

        <motion.div
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          transition={{ delay: 0.15 }}
        >
          <Title>Directory Sync Configuration Complete</Title>
        </motion.div>

        <motion.div
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          transition={{ delay: 0.2 }}
        >
          <Text>Your directory is now connected to {appName}.</Text>
        </motion.div>

        {successUrl ? (
          <Button onClick={() => window.location.assign(successUrl)}>
            {`Go back to ${appName}`}
          </Button>
        ) : (
          <Button onClick={() => navigate(`/dsync/${directory.id}`)}>
            View Directory
          </Button>
        )}
      </Article>
    );
  }

  return (
    <Article className="flex flex-col items-center text-center">
      <motion.i
        animate={{ opacity: 1, scale: 1 }}
        className="my-10 flex h-[100px] w-[100px] items-center justify-center rounded-full bg-red-darken text-white"
        initial={{ opacity: 0, scale: 0.5 }}
        transition={{ delay: 0.1 }}
      >
        <X size={54} strokeWidth={2} />
      </motion.i>

      <motion.div
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
        transition={{ delay: 0.15 }}
      >
        <Title>Directory Sync Configuration Unsuccessful</Title>
      </motion.div>

      <motion.div
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
        transition={{ delay: 0.2 }}
      >
        <Text>
          Please go through the setup steps again to confirm that all steps were
          completed.
        </Text>
      </motion.div>

      <Button onClick={() => navigate('/dsync/configure/1')}>
        Retry Directory Setup
      </Button>
    </Article>
  );
};
