import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { ExternalLink } from '../../../../components/link';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';

const Component: FC = () => (
  <StepContent confirmLabel="I’ve configured the Channel Info, Source Settings, and Source Location.">
    <Text>On the Manage Channels page, select "Create".</Text>

    <Img
      className="my-8"
      height={1232}
      src="/images/8ac8e3fb-86d2-4af1-8706-6b692112d032.png"
      width={2490}
    />

    <Text>
      On the Channel Info page, add a descriptive name and click "Next".
    </Text>

    <Img
      className="my-8"
      height={1312}
      src="/images/02be3fda-0c70-459a-b5ef-c0f5a461a018.png"
      width={2490}
    />

    <Text>
      Select an "Active Data Store" from the dropdown menu. This example uses a
      PingDirectory LDAP instance, but this may be different depending on the
      type of datastore used in each case. Please refer to the{' '}
      <ExternalLink
        newTab
        appearance="highlight"
        href={
          'https://docs.pingidentity.com/bundle/pingfederate-103/page/vbe1564003005413.html'
        }
      >
        PingFederate documentation
      </ExternalLink>{' '}
      for specific settings on your type of datastore. Click "Next".
    </Text>

    <Img
      className="my-8"
      height={1172}
      src="/images/cb10de17-32f9-49a9-b2eb-9860f64ae9dd.png"
      width={2490}
    />

    <Text>
      On the Source Settings page, make any modifications needed for your
      datastore. In this example, the default values for the LDAP datastore did
      not need to be modified, so the default settings were used. After
      configuring the source settings specific to your use case, click "Next" to
      go to the Source Location page.
    </Text>

    <Img
      className="my-8"
      height={3120}
      src="/images/a1915cbe-eedb-4fbd-ad78-81f41d8eb37c.png"
      width={2490}
    />

    <Text>
      On the Source Location page, input a Base DN and either a Group DB or
      Filter for the Users. This tells your application where to look for the
      users to sync from your active data store. The setup used in each case may
      be different depending on the type of datastore being used and which users
      and groups are to be provisioned. Please reference the{' '}
      <ExternalLink
        newTab
        appearance="highlight"
        href={
          'https://docs.pingidentity.com/bundle/pingfederate-103/page/jqa1564003005539.html'
        }
      >
        PingFederate documentation
      </ExternalLink>{' '}
      for specific steps. When this is complete, click "Next".
    </Text>

    <Img
      className="my-8"
      height={1564}
      src="/images/78ce8b2a-6339-4e9e-bd2c-d1f4488fac92.png"
      width={2490}
    />
  </StepContent>
);

export const configureChannelInPingFederate: StepData = {
  title: 'Configure Channel in PingFederate',
  component: Component,
};
