import { Select, Text, TextInput } from '@workos-inc/component-library';
import { format, subDays } from 'date-fns';
import { AnimatePresence, motion } from 'framer-motion';
import {
  FC,
  forwardRef,
  InputHTMLAttributes,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Settings } from 'react-feather';

const CUSTOM_RANGE = 'custom';
const DATE_FORMAT = 'yyyy-MM-dd';
const MAX_PAST_DAYS = 90;
const DATES = [
  { label: 'Last 7 days', subtractDays: 7 },
  { label: 'Last 14 days', subtractDays: 14 },
  { label: 'Last 30 days', subtractDays: 30 },
  { label: 'Last 60 days', subtractDays: 60 },
  { label: 'Last 90 days', subtractDays: MAX_PAST_DAYS },
] as const;

function getDefaultDateRange() {
  const past = format(subDays(new Date(), MAX_PAST_DAYS), DATE_FORMAT);
  const today = format(new Date(), DATE_FORMAT);

  return `${past},${today}`;
}

interface DateRangePickerProps {
  date?: string;
  onDateRangeChange: (dateRange: string) => void;
}

export const DateRangePicker: FC<DateRangePickerProps> = ({
  date,
  onDateRangeChange,
}) => {
  const today = useMemo(() => format(new Date(), DATE_FORMAT), []);
  const dateRange = date || getDefaultDateRange();
  const [dateFrom = '', dateTo = ''] = dateRange.split(',');
  const dateFromLimit = useMemo(
    () => format(subDays(new Date(), MAX_PAST_DAYS), DATE_FORMAT),
    [],
  );
  const dateToLimit = useMemo(
    () => dateFrom || dateFromLimit,
    [dateFrom, dateFromLimit],
  );
  const dateList = useMemo(
    () =>
      DATES.map((date) => {
        const from = format(
          subDays(new Date(), date.subtractDays),
          DATE_FORMAT,
        );

        return {
          ...date,
          id: `${from},${today}`,
          from,
          to: today,
        };
      }),
    [today],
  );
  const dateKey = `${dateFrom},${dateTo}`;
  const [isCustomRange, setIsCustomRange] = useState(
    () => !dateList.find((date) => date.id === dateKey),
  );

  useEffect(() => {
    if (!date) {
      onDateRangeChange(dateRange);
    }
  }, [date, dateRange, onDateRangeChange]);

  return (
    <div>
      <Text as="p" className="mb-2 leading-none" multiline={false} size="small">
        Date Range
      </Text>

      <Select
        name="date-range-picker"
        onValueChange={(value) => {
          if (value && value !== CUSTOM_RANGE) {
            onDateRangeChange(value);
            setIsCustomRange(false);
          } else {
            onDateRangeChange(`${dateFrom},${dateTo}`);
            setIsCustomRange(true);
          }
        }}
        value={isCustomRange ? CUSTOM_RANGE : dateKey}
      >
        <Select.Trigger
          className="pl-5"
          iconLeft={<Settings className="opacity-50" size={14} />}
        />

        <Select.Content className="shadow-lg">
          {dateList.map((date) => (
            <Select.Item key={date.id} value={date.id}>
              <Select.ItemText>{date.label}</Select.ItemText>
            </Select.Item>
          ))}

          <Select.Separator />

          <Select.Item value={CUSTOM_RANGE}>
            <Select.ItemText>Custom range</Select.ItemText>
          </Select.Item>
        </Select.Content>
      </Select>

      <AnimatePresence>
        {isCustomRange && (
          <motion.div
            animate={{ opacity: 1, height: 'auto' }}
            className="mt-2 flex items-center gap-x-2"
            exit={{ opacity: 0, height: 0 }}
            initial={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.15, ease: 'easeOut' }}
          >
            <DateInput
              autoFocus
              data-testid="audit-log-date-range-picker-from"
              id="from-date"
              label="Start Date"
              max={today}
              min={dateFromLimit}
              onChange={(e) =>
                onDateRangeChange(`${e.currentTarget.value},${dateTo}`)
              }
              value={dateFrom}
            />

            <Text as="span" size="small">
              to
            </Text>

            <DateInput
              data-testid="audit-log-date-range-picker-to"
              disabled={dateFrom === ''}
              id="to-date"
              label="End Date"
              max={today}
              min={dateToLimit}
              onChange={(e) =>
                onDateRangeChange(`${dateFrom},${e.currentTarget.value}`)
              }
              readOnly={dateFrom === ''}
              value={dateTo}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

interface DateInputProps
  extends Pick<
    InputHTMLAttributes<HTMLInputElement>,
    'onChange' | 'max' | 'min' | 'readOnly' | 'disabled' | 'autoFocus'
  > {
  value: string;
  label: string;
  id: string;
}

const DateInput = forwardRef<HTMLInputElement, DateInputProps>(
  ({ id, label, ...props }, forwardedRef) => (
    <label className="weight-medium leading-0 m-0 w-36" htmlFor={id}>
      <TextInput
        ref={forwardedRef}
        required
        className="h-8 py-0 px-1"
        id={id}
        name={id}
        type="date"
        {...props}
      />
    </label>
  ),
);

DateInput.displayName = 'DateInput';

export const testables = {
  MAX_PAST_DAYS,
  DATE_FORMAT,
};
