import { FC } from 'react';
import {
  ConnectionFragment,
  ConnectionSessionError,
  ConnectionSessionErrorCode,
  InvalidAttributesError,
} from '../../../../../../graphql/generated';
import { InvalidAttributes } from './invalid-attributes';

export const isInvalidAttributesError = (
  error: ConnectionSessionError,
): error is InvalidAttributesError =>
  error.code === ConnectionSessionErrorCode.InvalidAttributes;

export interface ConnectionErrors {
  appName: string;
  connection: ConnectionFragment;
  errors: ConnectionSessionError[];
}

export const ConnectionErrors: FC<ConnectionErrors> = ({
  appName,
  connection,
  errors,
}) => (
  <>
    {errors.map((error) => {
      if (isInvalidAttributesError(error)) {
        return (
          <InvalidAttributes
            key={error.code}
            appName={appName}
            connection={connection}
            error={error}
          />
        );
      }
      return null;
    })}
  </>
);
