import { Appearance } from '@workos-inc/component-library';
import { unreachable } from '@workos-inc/standard';
import {
  OAuthSessionState,
  OidcSessionState,
  SamlSessionState,
} from '../../../../graphql/generated';

export const getSessionStateBadge = (
  state: SamlSessionState | OAuthSessionState | OidcSessionState,
): Appearance => {
  switch (state) {
    case SamlSessionState.Authorized:
    case OAuthSessionState.Authorized:
    case OidcSessionState.Authorized:
      return 'blue';

    case SamlSessionState.Failed:
    case OAuthSessionState.Failed:
    case OidcSessionState.Failed:
      return 'red';

    case SamlSessionState.Started:
    case OAuthSessionState.Started:
    case OidcSessionState.Started:
      return 'yellow';

    case SamlSessionState.Successful:
    case OAuthSessionState.Successful:
    case OidcSessionState.Successful:
      return 'green';

    default:
      unreachable(state);
  }
};
