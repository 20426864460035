import { Button, InputGroup, Text } from '@workos-inc/component-library';
import { motion } from 'framer-motion';
import { FC, ReactElement } from 'react';
import { Check, X } from 'react-feather';
import { Navigate, useNavigate } from 'react-router-dom';
import { ConnectionState } from '../../../../graphql/generated';
import { Card } from '../../../components/card';
import { FileField } from '../../../components/fields';
import { NextStep } from '../../../components/next-step';
import { Article, Title } from '../../../components/typography';
import { useFormContext } from '../../../shared/form-data-provider';
import { useStepsStore } from '../../../shared/step-navigation/steps-store-provider';
import { SsoFormMetadata } from '../interfaces/sso-form-metadata';
import { useSsoStore } from '../sso-store-provider';
import { getConnectionName } from '../utils/get-connection-name';
import { getSupportedConnectionType } from '../utils/get-supported-connection-type';
import { testConnection } from '../utils/test-connection';

const ErrorFieldComponent = ({
  field,
}: {
  field: keyof SsoFormMetadata;
}): ReactElement | null => {
  const navigate = useNavigate();
  const { handleInputChange, stepByFormKey } = useStepsStore<SsoFormMetadata>();
  const { formData, formErrors, setFormData } =
    useFormContext<SsoFormMetadata>();

  const setActiveStep = (step: number) => {
    void navigate(`/sso/configure/${step}`);
  };
  switch (field) {
    case 'oidc_client_id':
      return (
        <div key={field}>
          <Card>
            <InputGroup
              error={formErrors?.oidc_client_id?.message}
              id="oidc_client_id"
              label="Client ID"
              name="oidc_client_id"
              onChange={handleInputChange}
              value={formData?.oidc_client_id ?? ''}
            />
          </Card>

          <StepButton
            setActiveStep={setActiveStep}
            step={stepByFormKey?.oidc_client_id}
          />
        </div>
      );
    case 'oidc_client_secret':
      return (
        <div key={field}>
          <Card>
            <InputGroup
              error={formErrors?.oidc_client_secret?.message}
              id="oidc_client_secret"
              label="Client Secret"
              name="oidc_client_secret"
              onChange={handleInputChange}
              value={formData?.oidc_client_secret ?? ''}
            />
          </Card>
          <StepButton
            setActiveStep={setActiveStep}
            step={stepByFormKey?.oidc_client_secret}
          />
        </div>
      );
    case 'oidc_discovery_endpoint':
      return (
        <div key={field}>
          <Card>
            <InputGroup
              error={formErrors?.oidc_discovery_endpoint?.message}
              id="oidc_discovery_endpoint"
              label="Discovery Endpoint"
              name="oidc_discovery_endpoint"
              onChange={handleInputChange}
              value={formData?.oidc_discovery_endpoint ?? ''}
            />
          </Card>

          <StepButton
            setActiveStep={setActiveStep}
            step={stepByFormKey?.oidc_discovery_endpoint}
          />
        </div>
      );
    case 'oidc_redirect_uri':
      return (
        <div key={field}>
          <Card>
            <InputGroup
              error={formErrors?.oidc_redirect_uri?.message}
              id="oidc_redirect_uri"
              label="Login redirect URI"
              name="oidc_redirect_uri"
              value={formData?.oidc_redirect_uri ?? ''}
            />
          </Card>

          <StepButton
            setActiveStep={setActiveStep}
            step={stepByFormKey?.oidc_redirect_uri}
          />
        </div>
      );
    case 'saml_idp_url':
      return (
        <div key={field}>
          <Card>
            <InputGroup
              error={formErrors?.saml_idp_url?.message}
              id="saml_idp_url"
              label="IdP Metadata URL"
              name="saml_idp_url"
              onChange={handleInputChange}
              value={formData?.saml_idp_url ?? ''}
            />
          </Card>

          <StepButton
            setActiveStep={setActiveStep}
            step={stepByFormKey?.saml_idp_url}
          />
        </div>
      );
    case 'saml_idp_metadata_url':
      return (
        <div key={field}>
          <Card>
            <InputGroup
              error={formErrors?.saml_idp_metadata_url?.message}
              id="saml_idp_metadata_url"
              label="IdP Metadata URL"
              name="saml_idp_metadata_url"
              onChange={handleInputChange}
              value={formData?.saml_idp_metadata_url ?? ''}
            />
          </Card>

          <StepButton
            setActiveStep={setActiveStep}
            step={stepByFormKey?.saml_idp_metadata_url}
          />
        </div>
      );
    case 'saml_x509_certificates':
      return (
        <div key={field}>
          <Card>
            <FileField
              error={formErrors?.saml_x509_certificates}
              label="X.509 Certificate"
              name="saml_x509_certificates"
              onUpload={({ file }) => {
                setFormData({
                  saml_x509_certificates: [file.content],
                });
              }}
              value={formData?.saml_x509_certificates?.[0] ?? ''}
            />
          </Card>
          <StepButton
            setActiveStep={setActiveStep}
            step={stepByFormKey?.saml_x509_certificates}
          />
        </div>
      );
    case 'saml_idp_metadata_xml':
      return (
        <div key={field}>
          <Card>
            <FileField
              accept=".xml"
              error={formErrors?.saml_idp_metadata_xml}
              label="XML Metadata File"
              name="saml_idp_metadata_xml"
              onUpload={({ file }) => {
                setFormData({
                  saml_idp_metadata_xml: file.content,
                });
              }}
              value={formData?.saml_idp_metadata_xml ?? ''}
            />
          </Card>
          <StepButton
            setActiveStep={setActiveStep}
            step={stepByFormKey?.saml_idp_metadata_xml}
          />
        </div>
      );
    default:
      return null;
  }
};

export const ConfirmationStep: FC = () => {
  const { connection } = useSsoStore();
  const navigate = useNavigate();

  const { currentStepNumber, handleConfirmationStep, isUpdating } =
    useStepsStore<SsoFormMetadata>();
  const { formData, formErrors } = useFormContext<SsoFormMetadata>();

  const connectionType = getSupportedConnectionType(connection);
  const formErrorKeys = Object.keys(formErrors) as (keyof SsoFormMetadata)[];

  let errorCount = 0;
  formErrorKeys.forEach((key) => {
    const error = formErrors[key];
    if (error?.value === formData[key]) {
      errorCount++;
    }
  });

  if (!connectionType) {
    return <Navigate to="/unsupported-provider" />;
  }

  if (formErrorKeys.length) {
    return (
      <Article>
        <Title>Step {currentStepNumber}: Confirmation</Title>

        {errorCount > 0 && (
          <div className="text-red-darken">
            <Text inheritColor as="p" className="mt-8 mb-6" size="large">
              Please correct {errorCount} error
              {errorCount > 1 ? 's' : ''} and resubmit to complete your
              connection.
            </Text>
          </div>
        )}

        <div className="grid grid-cols-1 gap-4">
          {formErrorKeys.map((field) => (
            <ErrorFieldComponent key={field} field={field} />
          ))}
          <NextStep
            buttonText="Complete My Connection"
            disabled={errorCount > 0}
            isLoading={isUpdating}
            label="Click here to finalize your Connection."
            onClick={handleConfirmationStep}
          />
        </div>
      </Article>
    );
  }

  if (connection.state === ConnectionState.Active) {
    return (
      <Article className="flex flex-col items-center text-center">
        <motion.i
          animate={{ opacity: 1, scale: 1 }}
          className="my-10 flex h-[100px] w-[100px] items-center justify-center rounded-full bg-green-600 leading-[100px] text-white"
          initial={{ opacity: 0, scale: 0.5 }}
          transition={{ delay: 0.1 }}
        >
          <Check size={54} strokeWidth={2} />
        </motion.i>

        <motion.div
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          transition={{ delay: 0.15 }}
        >
          <Title>SSO Configuration Complete</Title>
        </motion.div>

        <motion.div
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          transition={{ delay: 0.2 }}
        >
          <Text>
            Your users can now sign-in with {getConnectionName(connectionType)}.
          </Text>
        </motion.div>

        <motion.div
          animate={{ opacity: 1, scale: 1 }}
          data-testid="configuration-complete"
          initial={{ opacity: 0, scale: 0.5 }}
          transition={{ delay: 0.25 }}
        >
          <Button onClick={() => testConnection(connection.id)}>
            Test Single Sign-On
          </Button>
        </motion.div>
      </Article>
    );
  }

  return (
    <Article className="flex flex-col items-center text-center">
      <motion.i
        animate={{ opacity: 1, scale: 1 }}
        className="my-10 flex h-[100px] w-[100px] items-center justify-center rounded-full bg-red-darken text-white"
        initial={{ opacity: 0, scale: 0.5 }}
        transition={{ delay: 0.1 }}
      >
        <X size={54} strokeWidth={2} />
      </motion.i>

      <motion.div
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
        transition={{ delay: 0.15 }}
      >
        <Title>Single Sign-On Configuration Unsuccessful</Title>
      </motion.div>

      <motion.div
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
        transition={{ delay: 0.2 }}
      >
        <Text>
          Please go through the setup steps again to confirm that all steps were
          completed.
        </Text>
      </motion.div>

      <Button onClick={() => navigate('/sso/configure/1')}>
        Retry Connection Setup
      </Button>
    </Article>
  );
};

const StepButton = ({
  setActiveStep,
  step,
}: {
  setActiveStep: (step: number) => void;
  step?: number;
}) => (
  <Button
    appearance="minimal"
    onClick={() => setActiveStep(step ?? 1)}
  >{`<- Show all of Step ${step}`}</Button>
);
