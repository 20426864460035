import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { CopyInput } from '../../../../components/inputs';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';
import { useDsyncStore } from '../../dsync-store-provider';

const Component: FC = () => {
  const { directory } = useDsyncStore();

  return (
    <StepContent confirmLabel="I have entered the SCIM URL and Bearer Token.">
      <Text>
        On the Outbound Provisioning page, select the "Configure Provisioning"
        button.
      </Text>

      <Img
        className="my-8"
        height={1226}
        src="/images/fa5d4aaf-4f10-41c4-abfb-ce38126a0e63.png"
        width={2490}
      />

      <Text>
        On the Target page, paste in the Endpoint in the <code>SCIM URL</code>{' '}
        field.Make sure <code>SCIM Version</code> is set as <code>2.0</code> and
        the <code>Authentication Method</code> is set as{' '}
        <code>OAuth 2 Bearer Token</code>. Paste the Bearer Token into the
        Access Token field.
      </Text>

      <CopyInput
        id="directory-endpoint"
        label="Copy and paste the endpoint into the SCIM URL field."
        value={directory.endpoint}
      />

      <CopyInput
        id="directory-bearer-token"
        label="Copy and paste the Bearer Token in to the Access Token field. "
        type="password"
        value={directory.bearerToken}
      />

      <Img
        className="my-8"
        height={3846}
        src="/images/b9438dae-cdb7-437e-850b-ecb68492938c.png"
        width={2490}
      />

      <Text>
        Once these fields have been entered in to PingFederate, click Next.
      </Text>
    </StepContent>
  );
};

export const configureOutboundProvisioningForThePingFederateApplication: StepData =
  {
    title: 'Configure Outbound Provisioning for the PingFederate Application',
    component: Component,
  };
