import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';
import { ConnectionAttributeMapping } from '../../attribute-mapping';
import { useSsoStore } from '../../sso-store-provider';

const Component: FC = () => {
  const { connection } = useSsoStore();
  return (
    <StepContent confirmLabel="I’ve configured the Attribute Map">
      <Text>Continue scrolling until "Custom Attribute Mapping".</Text>

      <Img
        className="my-8"
        height={1598}
        src="/images/aedeb103-65d9-4cae-af63-39cc4a6b0a33.png"
        width={2692}
      />

      <Text>Fill in the following attribute mappings and select "Next".</Text>

      <ConnectionAttributeMapping connectionType={connection?.type} />

      <Text>
        NOTE: Some VMware configurations use user.ExternalId instead of
        user.objectGUID. In this case, you would map the id attribute to
        user.ExternalId.
      </Text>

      <Img
        className="my-8"
        height={1598}
        src="/images/0c3bbf1b-6737-48b2-aced-5589a9afcac8.png"
        width={2692}
      />
    </StepContent>
  );
};

export const configureAttributeMap: StepData = {
  title: 'Configure Attribute Map',
  component: Component,
};
