import { PropsWithChildren, ReactElement, useState } from 'react';
import { AnyObject } from '../../../hooks/use-record-state';
import {
  StepData,
  StepDataWithPath,
  StepNavigation,
  StepNavigationProps,
} from '../../../shared/step-navigation';
import { useSsoStore } from '../sso-store-provider';
import { SwitchConnectionModal } from '../switch-connection-modal';
import { getSsoClientSideFormErrors } from '../utils/get-sso-client-side-form-errors';

interface SsoStepNavigationProps<TFormData> {
  stepsData: StepData<TFormData>[];
}

interface SsoStepNavigationEnhancerProps<TFormData extends AnyObject> {
  children: (props: StepNavigationProps<TFormData>) => ReactElement;
  onClickSwitchProvider: () => void;
  stepsData: StepData<TFormData>[];
}

export const SsoStepNavigationEnhancer = <TFormData extends AnyObject>({
  children,
  stepsData,
  onClickSwitchProvider: handleSwitchProvider,
}: SsoStepNavigationEnhancerProps<TFormData>) => {
  const { connection, updateConnection } = useSsoStore();

  const stepsWithPath: StepDataWithPath<TFormData>[] = stepsData.map(
    (step, index) => ({
      ...step,
      path: `/sso/configure/${index + 1}`,
    }),
  );

  const handleUpdateWithChanges = async (formData: AnyObject) => {
    await updateConnection(formData);
  };

  return children({
    onClickSwitchProvider: handleSwitchProvider,
    onUpdateWithChanges: handleUpdateWithChanges,
    provider: connection.type,
    stepsData: stepsWithPath,
    stepsUrlBase: '/sso/configure',
    switchProviderText: 'Switch Identity Provider',
  });
};

export const SsoStepNavigation = <TFormData extends AnyObject>({
  children,
  stepsData,
}: PropsWithChildren<SsoStepNavigationProps<TFormData>>) => {
  const [isSwitchProviderModalOpen, setIsSwitchProviderModalOpen] =
    useState(false);

  return (
    <SsoStepNavigationEnhancer<TFormData>
      onClickSwitchProvider={() => setIsSwitchProviderModalOpen(true)}
      stepsData={stepsData}
    >
      {(stepNavigationProps) => (
        <StepNavigation<TFormData>
          {...stepNavigationProps}
          clientSideFormValidator={getSsoClientSideFormErrors}
        >
          {children}

          <SwitchConnectionModal
            open={isSwitchProviderModalOpen}
            setOpen={setIsSwitchProviderModalOpen}
          />
        </StepNavigation>
      )}
    </SsoStepNavigationEnhancer>
  );
};
