import { unreachable } from '@workos-inc/standard';
import { connectionSessionDeserializer } from '../../../../graphql/deserializers/connection-session-deserializer';
import {
  ConnectionSessionContainerFragment,
  ConnectionSessionFragment,
} from '../../../../graphql/generated';
import { ConnectionProtocol } from './get-connection-protocol';

export const getEvents = (
  connection: ConnectionSessionContainerFragment,
  connectionType: ConnectionProtocol,
) => {
  let events: ConnectionSessionFragment[] = [];
  switch (connectionType) {
    case ConnectionProtocol.Saml:
      events = connection.samlSessions.data as ConnectionSessionFragment[];
      break;
    case ConnectionProtocol.OAuth:
      events = connection.oauthSessions.data as ConnectionSessionFragment[];
      break;
    case ConnectionProtocol.Oidc:
      events = connection.oidcSessions.data as ConnectionSessionFragment[];
      break;
    case ConnectionProtocol.MagicLink:
      // No connection sessions for Magic Link.
      break;
    default:
      return unreachable(connectionType);
  }

  return events.map((session) => connectionSessionDeserializer(session));
};
