import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';

const Component: FC = () => (
  <StepContent confirmLabel="I've mapped the user attributes.">
    <Text>Click the "Mappers" top menu option. Select "Create".</Text>

    <Img
      className="my-8"
      height={1352}
      src="/images/bb01dc4a-2d7e-494c-97a4-01eaebebad39.png"
      width={2252}
    />

    <Text>
      You'll need to create a "User Property" mapper for the following four
      attributes: "id", "email", "firstName", and "lastName".
    </Text>

    <Text>This is an example of how to fill out the fields for "id":</Text>

    <Img
      className="my-8"
      height={1286}
      src="/images/5de3447a-fbc2-48a6-8eca-0038fafff691.png"
      width={2014}
    />

    <Text>
      Also do this for the "email", "firstName", and "lastName" attributes:
    </Text>

    <Img
      className="my-8"
      height={1286}
      src="/images/b66f12f2-ecd0-44d3-90ce-418a6035d61b.png"
      width={2014}
    />

    <Img
      className="my-8"
      height={1286}
      src="/images/699a3b6a-aa51-4915-9914-884d55ca4d59.png"
      width={2014}
    />

    <Img
      className="my-8"
      height={1286}
      src="/images/b55c956d-3d0e-4385-9264-9e309eae3dea.png"
      width={2014}
    />
  </StepContent>
);

export const mapUserAttributes: StepData = {
  title: 'Map User Attributes',
  component: Component,
};
