import { FC } from 'react';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { DsyncStepNavigation } from '../../dsync-step-navigation';
import { useDsyncStepRoutes } from '../../hooks/use-dsync-step-routes';
import { useEnabledDsyncSteps } from '../../hooks/use-enabled-dsync-steps';
import { generateDsyncStepData } from '../../utils/generate-dsync-step-data';
import { createARipplingScimApplication } from './1-create-a-rippling-scim-application';
import { configureRipplingScimApp } from './2-configure-rippling-scim-app';
import { addSupportedScimAttributes } from './3-add-supported-scim-attributes';
import { configureBearerTokenUsersAndGroups } from './4-configure-bearer-token-users-and-groups';
import { setUpAttributeMapping } from './5-set-up-attribute-mapping';

interface RipplingScimStepsProps {}

const stepsData: StepData[] = generateDsyncStepData([
  createARipplingScimApplication,
  configureRipplingScimApp,
  addSupportedScimAttributes,
  configureBearerTokenUsersAndGroups,
  setUpAttributeMapping,
]);

export const RipplingScimSteps: FC<Readonly<RipplingScimStepsProps>> = () => {
  const enabledSteps = useEnabledDsyncSteps(stepsData);
  const stepRoutes = useDsyncStepRoutes(enabledSteps);

  return (
    <DsyncStepNavigation stepsData={enabledSteps}>
      {stepRoutes}
    </DsyncStepNavigation>
  );
};
