import { InputGroup } from '@workos-inc/component-library';
import { FC } from 'react';
import { Card } from '../../card';

type CopyInputProps = {
  id: string;
  label: string;
  value?: string | null;
  type?: 'text' | 'password';
};

export const CopyInput: FC<Readonly<CopyInputProps>> = ({
  id,
  label,
  value,
  type,
}) => (
  <Card>
    <InputGroup
      isCopyable
      readOnly
      id={id}
      isObfuscated={type === 'password'}
      label={label}
      name="copy-input"
      value={value ?? ''}
    />
  </Card>
);
