import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { CopyInput } from '../../../../components/inputs';
import { usePortalSession } from '../../../../components/portal-session-provider';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';
import { useSsoStore } from '../../sso-store-provider';

const Component: FC = () => {
  const { appName } = usePortalSession();
  const { connection } = useSsoStore();

  return (
    <StepContent confirmLabel="I've created and configured a SAML application.">
      <Text>
        Create a SAML application in your NetIQ instance. You'll need the
        following Assertion Consumer Service (ACS) URL. The ACS URL is the
        location NetIQ will redirect its authentication response to.
      </Text>

      <CopyInput
        id="saml-acs-url"
        label="Copy this ACS URL"
        value={connection.saml_acs_url}
      />

      <Text>
        The Service Provider Entity ID is a unique value which represents{' '}
        {appName} within NetIQ. Provide the value below to your NetIQ SAML
        application settings.
      </Text>

      <CopyInput
        id="saml-entity-id"
        label="Copy this SP Entity ID"
        value={connection.saml_entity_id}
      />

      <Text>
        For some setups, you can use the metadata found at the following SP
        Metadata link to configure the SAML connection.
      </Text>

      <CopyInput
        id="saml-sp-metadata-url"
        label="Copy this URL/XML"
        value={connection.saml_sp_metadata_url}
      />
    </StepContent>
  );
};

export const createAndConfigureANetIqSamlApplication: StepData = {
  title: 'Create and configure a NetIQ SAML Application',
  component: Component,
};
