import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { AuditLogsRouter } from '../../features/audit-logs/audit-logs-router';
import { DsyncRouter } from '../../features/dsync/dsync-router';
import { SsoRouter } from '../../features/sso/sso-router';
import { getIntentFromCookie, Intent } from '../../utils/token';
import { Head } from '../head';
import { ThemeLoader } from '../theme-provider/theme-loader';
import { IntentRouteGuard } from './intent-route-guard';

interface IntentRouterProps {}

export const intentMapping: Record<Intent, string> = {
  sso: '/sso',
  dsync: '/dsync',
  audit_logs: '/audit-logs',
};

export const IntentRouter: FC<Readonly<IntentRouterProps>> = () => {
  let intent: Intent | undefined;

  try {
    intent = getIntentFromCookie();
  } catch {
    return <Navigate to="/not-found" />;
  }

  return (
    <ThemeLoader>
      <Head />
      <Routes>
        <Route
          element={<Navigate replace to={intentMapping[intent]} />}
          path="/"
        />

        <Route
          element={
            <IntentRouteGuard guard="sso" intent={intent}>
              <SsoRouter />
            </IntentRouteGuard>
          }
          path={`${intentMapping.sso}/*`}
        />

        <Route
          element={
            <IntentRouteGuard guard="dsync" intent={intent}>
              <DsyncRouter />
            </IntentRouteGuard>
          }
          path={`${intentMapping.dsync}/*`}
        />

        <Route
          element={
            <IntentRouteGuard guard="audit_logs" intent={intent}>
              <AuditLogsRouter />
            </IntentRouteGuard>
          }
          path={`${intentMapping.audit_logs}/*`}
        />

        <Route element={<Navigate to="/not-found" />} path="*" />
      </Routes>
    </ThemeLoader>
  );
};
