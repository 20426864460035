import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Topnav } from '../../../components/topnav';
import { FormDataProvider } from '../../../shared/form-data-provider/form-data-provider';
import { ConfiguredSsoConnectionPage } from '../pages/configured-sso-connection-page/configured-sso-connection-page';
import { ConnectionEventPage } from '../pages/connection-event-page/connection-event-page';
import { SsoProviderSelectionPage } from '../pages/sso-provider-selection-page';
import { SsoExitWarningCheck } from '../sso-exit-warning-check/sso-exit-warning-check';
import { SsoStoreProvider } from '../sso-store-provider';
import { SsoSteps } from '../steps/sso-steps';
import { SsoRedirector } from './sso-redirector';

interface SsoRouterProps {}

export const SsoRouter: FC<Readonly<SsoRouterProps>> = () => (
  <FormDataProvider>
    <SsoStoreProvider>
      <Topnav />
      <SsoExitWarningCheck />

      <Routes>
        <Route element={<SsoRedirector />} path="/">
          <Route element={<SsoProviderSelectionPage />} path="/new" />
          <Route element={<SsoSteps />} path="/configure/*" />

          <Route element={<ConnectionEventPage />} path="/session/:sessionId" />

          <Route
            element={<ConfiguredSsoConnectionPage />}
            path="/:connectionId"
          />

          <Route
            element={<ConnectionEventPage />}
            path="/connection/event/:sessionId"
          />
        </Route>
      </Routes>
    </SsoStoreProvider>
  </FormDataProvider>
);
