import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';

const Component: FC = () => (
  <StepContent confirmLabel="I've added claims to the user ID token.">
    <Text>
      Add the "sub", "email", "given_name", and "family_name" claims to the user
      ID token in your OIDC provider settings. If the "given_name" claim is not
      available, the "name" claim will be used instead. For many providers,
      these claims are included by default, but for other providers you will
      need to add these claims.
    </Text>
  </StepContent>
);

export const addClaimsToUserIdToken: StepData = {
  title: 'Add Claims to User ID Token',
  component: Component,
};
