import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { ConnectionStepProps } from '../../../interfaces/step-props';
import { ConnectionAttributeMapping } from '../../attribute-mapping';
import { Confirm } from '../../confirm';
import { Img } from '../../image';
import { CopyInput } from '../../input';
import { MetadataXmlStep } from './saml/metadata-xml-step';

export const GoogleSamlAddApplication: FC<Readonly<ConnectionStepProps>> = ({
  onNextStep: handleNextStep,
}) => (
  <>
    <Text>
      On your Google Admin dashboard, select "Apps" from the sidebar menu, and
      then select "Web and Mobile Apps" from the following list. On this page,
      select "Add App" and then "Add custom SAML app".
    </Text>

    <Img
      className="my-8"
      height={1009}
      src="/images/2586671f-9aec-4889-affd-76821224584f.png"
      width={1474}
    />

    <Confirm label="I've added a custom SAML app." onClick={handleNextStep} />
  </>
);

export const GoogleSamlEnterDetails: FC<Readonly<ConnectionStepProps>> = ({
  appName,
  onNextStep: handleNextStep,
}) => (
  <>
    <Text>
      Enter an App name and icon (if applicable) for {appName}, then select
      "Continue".
    </Text>

    <Img
      className="my-8"
      height={1009}
      src="/images/3ab3a0af-d6b0-4709-ba0d-46db60d883b9.png"
      width={1474}
    />

    <Confirm label="I've entered the App Details." onClick={handleNextStep} />
  </>
);

export const GoogleSamlUploadMetadata: FC<Readonly<ConnectionStepProps>> = (
  connectionStepProps,
) => (
  <MetadataXmlStep
    {...connectionStepProps}
    idpEntityIdPlaceholder="https://accounts.google.com/o/saml2?idpid=C00l3qzl9"
    idpSsoUrlPlaceholder="https://accounts.google.com/o/saml2/idp?idpid=C00l3qzl9"
  >
    <Text>
      Select the "Download Metadata" button to download the metadata file, and
      upload it below. Click "Continue".
    </Text>

    <Img
      className="my-8"
      height={1001}
      src="/images/edef0a99-4ff2-4453-99e3-2787d5dc30b8.png"
      width={1239}
    />
  </MetadataXmlStep>
);

export const GoogleSamlServiceProviderDetails: FC<
  Readonly<ConnectionStepProps>
> = ({ connection, onNextStep: handleNextStep }) => (
  <>
    <CopyInput label="Copy this ACS URL" value={connection?.saml_acs_url} />

    <CopyInput label="Copy this Entity ID" value={connection?.saml_entity_id} />

    <Text>Submit the "ACS URL" and the "Entity ID". Click "Continue"</Text>

    <Img
      className="my-8"
      height={1009}
      src="/images/d70e5055-34de-4d31-a7d3-a0ac8cfa4395.png"
      width={1474}
    />
    <Confirm
      label="I've configured the ACS URL and Entity ID in my custom SAML app."
      onClick={handleNextStep}
    />
  </>
);

export const GoogleSamlAttributeMapping: FC<Readonly<ConnectionStepProps>> = ({
  connection,
  onNextStep: handleNextStep,
}) => (
  <>
    <Text>Provide the following Attribute Mappings and select "Finish":</Text>

    <ConnectionAttributeMapping connectionType={connection?.type} />

    <Img
      className="my-8"
      height={1034}
      src="/images/f5bdf97e-407d-4d13-a3ff-68d2c56998b0.png"
      width={1271}
    />

    <Confirm
      label="I've finished configuring the Attribute Mapping."
      onClick={handleNextStep}
    />
  </>
);

export const GoogleSamlUserAccess: FC<Readonly<ConnectionStepProps>> = ({
  onNextStep: handleNextStep,
}) => (
  <>
    <Text>
      In the created SAML app's landing page, select the "User Access Section".
    </Text>

    <Img
      className="my-8"
      height={1034}
      src="/images/8f314d73-d51b-4935-accb-dc019f42967f.png"
      width={1271}
    />

    <Text>
      Turn this service ON for the correct organizational units in your Google
      Directory setup. Save any changes. Note that Google may take up to 24
      hours to propagate these changes, and the connection may be inactive until
      the changes have propagated.
    </Text>

    <Img
      className="my-8"
      height={1034}
      src="/images/bbfbe286-485d-471c-a799-fd816c34d0ac.png"
      width={1271}
    />

    <Confirm
      label="I've finished configuring the User Access."
      onClick={handleNextStep}
    />
  </>
);
