import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { metadataXmlFormKeys } from '../../constants/metadata-form-keys';
import { MetadataXmlFormFields } from '../../interfaces/metadata-form-fields';
import { MetadataXmlStep } from '../../metadata-step/metadata-xml-step';

const Component: FC = () => (
  <MetadataXmlStep
    idpEntityIdPlaceholder="https://www.rippling.com/..."
    idpSsoUrlPlaceholder="https://www.rippling.com/api/platform/sso/sp-initiated/..."
  >
    <Text>
      Rippling will now provide the SSO set up instructions. Step 1 will include
      the IdP Metadata XML file. Click to download the file from Rippling and
      upload it below.
    </Text>

    <Img
      className="my-8"
      height={1277}
      src="/images/f654756d-1985-4411-9f71-238a9772362f.png"
      width={1920}
    />
  </MetadataXmlStep>
);

export const uploadIdpMetadata: StepData<MetadataXmlFormFields> = {
  title: 'Upload IdP Metadata',
  component: Component,
  formKeys: metadataXmlFormKeys,
};
