import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { usePortalSession } from '../../../../components/portal-session-provider';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';

const Component: FC = () => {
  const { appName } = usePortalSession();

  return (
    <StepContent confirmLabel="I’ve completed my assignments and turned on provisioning.">
      <Text>
        In order for your users and groups to be synced to {appName} you will
        need to assign them to your Azure AD SCIM Application. Select "Users and
        groups" from the "Manage" section of the navigation menu.
      </Text>

      <Img
        className="my-8"
        height={1334}
        src="/images/0c730d3b-baa7-4e9b-9a52-558096b5682c.png"
        width={2878}
      />

      <Text>Select "Add user/group" from the top menu.</Text>

      <Img
        className="my-8"
        height={1314}
        src="/images/ee49e2ce-a4dd-414d-9737-cad387369dfa.png"
        width={2878}
      />

      <Text>
        Select "None selected" under the "Users and Groups". In the menu, select
        the users and groups that you want to add to the SCIM application, and
        click "Select".
      </Text>

      <Img
        className="my-8"
        height={1314}
        src="/images/818e42eb-fc17-4722-98bd-fed201ed055b.png"
        width={2878}
      />

      <Text>
        Select "Assign" to add the selected users and groups to your SCIM
        application.
      </Text>

      <Img
        className="my-8"
        height={1094}
        src="/images/4d34de41-9842-46cb-bde0-5d990a5c97f4.png"
        width={2114}
      />

      <Text>
        Confirm the "Provisioning Status" is set to "On" and that the "Scope" is
        set to "Sync only assigned users and groups".
      </Text>

      <Img
        className="my-8"
        height={1656}
        src="/images/3f085d2a-66fb-4f40-bb6d-a7b1862ac79e.png"
        width={2426}
      />
    </StepContent>
  );
};

export const assignPeopleGroupsToAzureAdScimApplication: StepData = {
  title: 'Assign People & Groups to Azure AD SCIM Application',
  component: Component,
};
