import { JsonSchemaExplorer } from '@workos-inc/component-library';
import { useEffect, useMemo, useState } from 'react';
import { Activity, Calendar, Crosshair, Globe } from 'react-feather';
import { useParams } from 'react-router-dom';
import { AuditLogEventQuery } from '../../../../graphql/generated';
import { graphql } from '../../../../utils/graphql';
import { AuditLogsActorIcon, AuditLogsBreadcrumb } from '../audit-logs-table';
import { formatAuditLogEventDate } from '../format-audit-log-event-date';
import { PageWrapper } from '../page-wrapper/page-wrapper';

export const AuditLogEvent = () => {
  const { eventId } = useParams();
  const [data, setData] = useState<AuditLogEventQuery>();

  useEffect(() => {
    async function fetchEventData() {
      if (!eventId) {
        throw new Error("'eventId' is required");
      }

      const { data: eventData } = await graphql().auditLogEvent({
        id: eventId,
      });
      setData(eventData);
    }

    void fetchEventData();
  }, [eventId]);

  const auditLog = data?.portal_auditLogEvent;
  const time =
    formatAuditLogEventDate(auditLog?.occurredAt ?? '', 'H:mm:ss z') ?? '';
  const date =
    formatAuditLogEventDate(auditLog?.occurredAt ?? '', 'MMMM dd, yyyy') ?? '';
  const rawData = useMemo(
    () => (auditLog?.data ? JSON.parse(auditLog.data) : undefined),
    [auditLog?.data],
  );

  const properties = useMemo(
    () => [
      {
        id: 'actor',
        subtitle: auditLog?.actor.type ?? '',
        title: auditLog?.actor.name ?? '',
        icon: <AuditLogsActorIcon actor={auditLog?.actor} />,
      },
      {
        id: 'action',
        subtitle: auditLog?.id ?? '',
        title: auditLog?.action ?? '',
        icon: <Activity />,
      },
      {
        id: 'occurred_at',
        subtitle: time,
        title: date,
        icon: <Calendar />,
      },
      {
        id: 'targets',
        subtitle:
          auditLog?.targets.map((target) => target.type).join(', ') ?? '',
        title: getTargetCountTitle(auditLog?.targets || []),
        icon: <Crosshair />,
      },
      {
        id: 'context',
        subtitle: rawData?.context?.user_agent ?? '',
        title: rawData?.context?.location ?? '',
        icon: <Globe />,
      },
    ],
    [auditLog, date, time, rawData?.context],
  );

  return (
    <PageWrapper>
      <div className="mb-4 flex w-full flex-col gap-2">
        <AuditLogsBreadcrumb auditLogId={eventId} />
      </div>

      <JsonSchemaExplorer
        className="overflow-hidden rounded-md ring-1 ring-gray-lightmode-200 dark:ring-gray-darkmode-200"
        isLoading={!auditLog}
        json={rawData}
        properties={properties}
        subtitle={auditLog?.id ?? '-'}
        title="Audit log event"
      />
    </PageWrapper>
  );
};

/**
 * Get the count of targets and returns it as a nicely formatted string
 */
const getTargetCountTitle = (targets: { type: string }[]): string => {
  const targetCache: Record<string, number> = {};

  targets.forEach((target) => {
    if (!targetCache[target.type]) {
      targetCache[target.type] = 0;
    }

    targetCache[target.type] += 1;
  });

  return Object.entries(targetCache)
    .map(([type, count]) => `${count} ${type}${count > 1 ? 's' : ''}`)
    .join(', ');
};
