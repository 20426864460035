import { FC } from 'react';
import { CenteredPageContent } from '../../../../components/centered-page-content';
import { LoadingScreen } from '../../../../components/loading-screen';
import { useDsyncStore } from '../../dsync-store-provider';
import { DeleteDirectory } from './delete-directory';
import { DirectoryAttributeMappings } from './directory-attribute-mappings';
import { DirectoryOverview } from './directory-overview';
import { DirectoryUsers } from './directory-users';

interface ConfiguredDirectoryPageProps {}

export const ConfiguredDirectoryPage: FC<
  Readonly<ConfiguredDirectoryPageProps>
> = () => {
  const { directory } = useDsyncStore();

  if (!directory) {
    return <LoadingScreen />;
  }

  return (
    <CenteredPageContent className="flex flex-col gap-8">
      <DirectoryOverview />
      <DirectoryAttributeMappings />
      <DirectoryUsers />
      <DeleteDirectory />
    </CenteredPageContent>
  );
};
