import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';
import { ConnectionAttributeMapping } from '../../attribute-mapping';
import { useSsoStore } from '../../sso-store-provider';

const Component: FC = () => {
  const { connection } = useSsoStore();

  return (
    <StepContent confirmLabel="I’ve finished and closed the claims issuance policy.">
      <Text>Click “Add Rule” in the “Edit Claims Issuance Policy” window.</Text>

      <Img
        className="my-8"
        height={853}
        src="/images/b0ce3aa1-5a5c-498a-8b40-f9297ed03a29.png"
        width={1186}
      />

      <Text>Select “Send LDAP Attributes as Claims” and then “Next”.</Text>

      <Img
        className="my-8"
        height={853}
        src="/images/753196aa-ebd0-4456-a961-4faacbfddbd2.png"
        width={1186}
      />

      <Text>Submit “Attributes” as “Claim rule name:”</Text>

      <Text>Select “Active Directory” as “Attribute Store”</Text>

      <Text>Fill in the following Attribute mappings:</Text>

      <ConnectionAttributeMapping connectionType={connection.type} />

      <Text>Click “Finish”.</Text>

      <Img
        className="my-8"
        height={853}
        src="/images/e835b332-47de-43e5-a34d-0031395dee9c.png"
        width={1186}
      />
    </StepContent>
  );
};

export const configureClaimsIssuancePolicy: StepData = {
  title: 'Configure Claims Issuance Policy',
  component: Component,
};
