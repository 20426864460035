import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';

const Component: FC = () => (
  <StepContent confirmLabel="I’ve selected “Assertion” as the signature element.">
    <Text>
      Continue scrolling down the Application Details to “SAML Initiator”.
    </Text>

    <Text>Select “Service Provider” under “SAML Initiator”.</Text>

    <Img
      className="my-8"
      height={1222}
      src="/images/820d289b-d982-45ad-8788-5992b33bffe9.png"
      width={2816}
    />

    <Text>Select “Assertion” under “SAML signature element”.</Text>

    <Img
      className="my-8"
      height={1222}
      src="/images/5802afac-d1ce-43e1-b7fa-e76c8f171f21.png"
      width={2816}
    />
  </StepContent>
);

export const advancedSamlConfiguration: StepData = {
  title: 'Advanced SAML Configuration',
  component: Component,
};
