import {
  ConnectionFragment,
  UpdateConnectionFromMetadataUrlMutation,
} from '../../../../../../graphql/generated';
import { graphql } from '../../../../../utils/graphql';
import { SsoFormMetadata } from '../../../interfaces/sso-form-metadata';

export const completeConnectionFromMetadataUrl = async (
  formData: SsoFormMetadata,
  connection: ConnectionFragment,
): Promise<
  UpdateConnectionFromMetadataUrlMutation['portal_updateConnectionFromMetadataUrl']
> => {
  if (!formData.saml_idp_metadata_url) {
    throw new Error('saml_idp_metadata_url needs to be defined');
  }

  const response = await graphql().UpdateConnectionFromMetadataUrl({
    input: {
      connectionId: connection.id,
      metadataUrl: formData.saml_idp_metadata_url,
    },
  });

  return response.data?.portal_updateConnectionFromMetadataUrl;
};
