import { unreachable } from '@workos-inc/standard';
import { PropsWithChildren } from 'react';
import { Card } from '../../../components/card';
import { FileField } from '../../../components/fields';
import { Form } from '../../../components/form';
import { useFormContext } from '../../../shared/form-data-provider';
import { useStepsStore } from '../../../shared/step-navigation/steps-store-provider';
import { SelectedConfiguration } from '../interfaces/selected-configuration';
import { SsoFormMetadata } from '../interfaces/sso-form-metadata';
import { useSsoStore } from '../sso-store-provider';
import { ManualMetadataStep } from './manual-metadata-step';

export interface MetadataXmlStepProps {
  idpEntityIdPlaceholder: string;
  idpSsoUrlPlaceholder: string;
}
export const MetadataXmlStep = <TFormData extends SsoFormMetadata>({
  children,
  idpEntityIdPlaceholder,
  idpSsoUrlPlaceholder,
}: PropsWithChildren<MetadataXmlStepProps>) => {
  const { selectedConfiguration, setSsoStore } = useSsoStore();

  const { handleNextStep, isUpdating } = useStepsStore<TFormData>();
  const { formData, formErrors, setFormData } = useFormContext<TFormData>();

  const setSelectedConfiguration = (
    selectedConfiguration: SelectedConfiguration,
  ) => setSsoStore({ selectedConfiguration });

  switch (selectedConfiguration) {
    case 'dynamic':
      return (
        <>
          {children}

          <Card>
            <Form
              disabled={!formData?.saml_idp_metadata_xml}
              isLoading={isUpdating}
              isUpdate={!!formErrors?.saml_idp_metadata_xml}
              onSubmit={handleNextStep}
              secondaryAction={() => setSelectedConfiguration('manual')}
              secondaryText="Configure Manually"
            >
              <FileField
                accept=".xml"
                error={formErrors?.saml_idp_metadata_xml}
                id="saml_idp_metadata_xml"
                label="XML Metadata File"
                name="saml_idp_metadata_xml"
                onUpload={({ file }) => {
                  setFormData({
                    ...formData,
                    saml_idp_metadata_xml: file.content,
                  });
                }}
                value={formData?.saml_idp_metadata_xml}
              />
            </Form>
          </Card>
        </>
      );
    case 'manual':
      return (
        <ManualMetadataStep
          idpEntityIdPlaceholder={idpEntityIdPlaceholder}
          idpSsoUrlPlaceholder={idpSsoUrlPlaceholder}
          onConfigurationTypeChange={(selectedConfiguration) =>
            setSelectedConfiguration(selectedConfiguration)
          }
        />
      );
    default:
      return unreachable(selectedConfiguration);
  }
};
