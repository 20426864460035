import { FC } from 'react';
import { StepData } from '../../../../shared/step-navigation';
import { useSsoStepRoutes } from '../../hooks/use-sso-step-routes';
import { SsoStepNavigation } from '../../sso-step-navigation';
import { generateSsoStepData } from '../../utils/generate-sso-step-data';
import { setupOktaApplication } from './1-setup-okta-application';

interface OktaSamlOinStepsProps {}

const stepsData: StepData[] = generateSsoStepData([setupOktaApplication]);

export const OktaSamlOinSteps: FC<Readonly<OktaSamlOinStepsProps>> = () => {
  const stepRoutes = useSsoStepRoutes(stepsData);

  return (
    <SsoStepNavigation stepsData={stepsData}>{stepRoutes}</SsoStepNavigation>
  );
};
