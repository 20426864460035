import { FC } from 'react';
import { StepData } from '../../../../shared/step-navigation';
import { useSsoStepRoutes } from '../../hooks/use-sso-step-routes';
import { MetadataUrlFormFields } from '../../interfaces/metadata-form-fields';
import { SsoStepNavigation } from '../../sso-step-navigation';
import { generateSsoStepData } from '../../utils/generate-sso-step-data';
import { createSamlIntegration } from './1-create-saml-integration';
import { configureAttributeStatements } from './2-configure-attribute-statements';
import { uploadIdentityProviderMetadata } from './3-upload-identity-provider-metadata';

interface MiniOrangeSamlStepsProps {}

const stepsData: StepData<MetadataUrlFormFields>[] = generateSsoStepData([
  createSamlIntegration,
  configureAttributeStatements,
  uploadIdentityProviderMetadata,
]);

export const MiniOrangeSamlSteps: FC<
  Readonly<MiniOrangeSamlStepsProps>
> = () => {
  const stepRoutes = useSsoStepRoutes(stepsData);

  return (
    <SsoStepNavigation stepsData={stepsData}>{stepRoutes}</SsoStepNavigation>
  );
};
